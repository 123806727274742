import {CabinetOption, ViewOptionType, ViewOption} from './cabinet-option'
import {IProduct} from '../common/interface/product-types'
import {OptionValueNameItem} from '../services/override.service'
import {ProdboardCabinetOption} from '../services/prodboard-types'

export class CombinedUnit extends CabinetOption {

  public readonly optionSelectName = 'CombinedUnit'

  /**
   * The option keys that can come from Prodboard
   */
  private opts = ['left', 'right', 'top', 'bot',]

  constructor(option: ProdboardCabinetOption, product: IProduct, cabinetId: number) {
    super(option, product, cabinetId)

    this.setNameAndOptions()

    this.priority = 30
    this.viewOptions = [
      {
        type: ViewOptionType.select,
        title: 'Kombinerat med skåp till vänster',
        name: 'left',
        values: ['Ja', 'Nej'],
        selection: 'Nej',
        disabled: false
      },
      {
        type: ViewOptionType.select,
        title: 'Kombinerat med skåp till höger',
        name: 'right',
        values: ['Ja', 'Nej'],
        selection: 'Nej',
        disabled: false
      },
      {
        type: ViewOptionType.select,
        title: 'Kombinerat med skåp över',
        name: 'top',
        values: ['Ja', 'Nej',],
        selection: 'Nej',
        disabled: false
      },
      {
        type: ViewOptionType.select,
        title: 'Kombinerat med skåp under ',
        name: 'bot',
        values: ['Ja', 'Nej'],
        selection: 'Ingen',
        disabled: false
      }
    ]
    this.active = false
    this.description = 'Some cabinets are combined into a larger unit'
    this.title = 'optCombinedUnit'
    this.sanitize(option)
    this.setValuesFromProdboardData(option)
  }

  public update(data: any) {
    super.update(data)
    this.setFromProperties(data)

  }

  protected getCustomCustomerListing(lc: string): string[] {
    return this.viewOptions.filter((opt: ViewOption) => opt.selection === 'Ja')
      .map((opt: ViewOption) => this.optionValueNames.find((on: OptionValueNameItem) => on.key === opt.name)[lc])
  }

  private sanitize(option: ProdboardCabinetOption): void {
    option.value = option.value || {} as any
    option.value.options = option.value.options || {} as any
    option.value.options.combi_new = option.value.options.combi_new || {} as any
    this.opts.forEach((key: string) => {
      option.value.options.combi_new[key] = option.value.options.combi_new[key] || false
    })
  }

  private setValuesFromProdboardData(option: ProdboardCabinetOption): void {
    this.viewOptions
      .forEach((opt: ViewOption) => opt.selection = option.value.options.combi_new[opt.name] ? 'Ja' : 'Nej')
    this.setActive()
  }

  private setActive(): void {
    this.active = false
    this.viewOptions.forEach((opt: ViewOption) => this.active = opt.selection === 'Ja' || this.active)
  }
}

/**
 * To avoid circular dependencies we put some definitions
 * in a separate file
 */
export const BACK_PANEL_MIRROR = 'Täcksida med spegel'
export const NO_BACK_COVER = 'Ingen täcksida'
export const BACK_PANEL_PLAIN = 'Slät täcksida'


/**
 * Side panels
 */
export const COVER_TYPE_POS = 0
export const COVER_EXTEND_POS = 1
export const COVER_CONSOLE_POS = 2

export const COVER_SIDE_PLAIN = 'Täcksida, slät'
export const COVER_SIDE_MIRROR = 'Täcksida med spegel'
export const COVER_SIDE_NONE = 'Ingen täcksida'

export const COVER_SIDE_CONSOLE_CONVEX = 'Med konsol (konvex)'
export const COVER_SIDE_CONSOLE_CONCAVE = 'Med konsol (konkav)'
export const COVER_SIDE_CONSOLE_NONE = 'Utan konsoll'

/**
 * Fillers
 */
export const FILLER_TOP_POS = 0
export const FILLER_LEFT_POS = 1
export const FILLER_RIGHT_POS = 2
export const FILLER_BOTTOM_POS = 3

/**
 * Paint Process
 */
export const LINSEED_COMPLETE = '1. Linoljefärg, komplett'
export const LINSEED_BASE = '2. Linoljefärg, grundmålning'
// export const SPRAY = '3. Spraymålning (ingen målning efter montering)'
export const NO_PAINT_WAXED_INSIDE = '4. Omålat på utsidorna, vaxat på insidorna'
export const NO_PAINT = '5. Helt omålat och obehandlat'

export const ORDINARY_PAINT_COMPLETE = '3. Vanlig färg, komplett'
export const ORDINARY_PAINT_BASE = '6. Vanlig färg, grundmålning'
/**
 * Door Attachment
 */
export const DOOR_ON_DOOR = 'Door on door'
export const SLIDES = 'Slides'
export const TBD = 'TBD'

/**
 * Hinges
 */
export const CLASSIC_HINGES = '1. Klassiskt lyftgångjärn'
export const MODERN_HINGES = '2. Moderna mjukstängande gångjärn'
export const MODERN_HINGES_TAP = '3. Modernt gångjärn med synlig tapp'

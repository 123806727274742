export class ReceiptItem {

  public price = 0

  public material = 0

  public labor = 0

  /**
   * The cabinet index
   */
  public cabinetIndex: number | null = null

  /**
   * If this item belongs to an option this must be set.
   */
  public optionName: string | null = null

  /**
   * This is the line item text e.g. "Snickarglädje båda hörn"
   */
  public itemText: string

  /**
   * When the price is fetched from a property in the product
   * e.g. prBaPa it should be set here.
   */
  public priceProperty = ''

  /**
   * In general all price items created are active
   * but at times we just want an item that we do not
   * display
   */
  public active = true

  /**
   * Let us set a source... Good for some reason I guess.
   * We use 'option' as default since that is the most common
   * one. Do not forget to set this on Cabinets and Comments etc.
   */
  public source: 'option' | 'cabinet' | 'comment' | 'appliance' | 'discount' | 'dimension' = 'option'

  /**
   * Create a new item for the receipt
   *
   * @param itemText - The text to show on the receipt
   * @param cabinetIndex - The cabinet index if any. 1,2,3... etc.
   * @param optionName - The option name as in Doors_0 etc.
   */
  constructor(
    itemText: string,
    cabinetIndex: number | null = null,
    optionName: string | null = null) {
    this.itemText = itemText
    this.cabinetIndex = cabinetIndex
    this.optionName = optionName
  }
}

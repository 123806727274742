import {OptionValueNameItem, OptionValues} from './override.service'
import {OUTSIDE_SKIRTING, STANDARD_SKIRTING} from '../common/interface/product-types'
import {
  BACK_PANEL_MIRROR,
  BACK_PANEL_PLAIN,
  COVER_SIDE_CONSOLE_CONCAVE,
  COVER_SIDE_CONSOLE_CONVEX,
  COVER_SIDE_CONSOLE_NONE,
  COVER_SIDE_MIRROR,
  COVER_SIDE_PLAIN,
  DOOR_ON_DOOR,
  LINSEED_BASE,
  LINSEED_COMPLETE,
  NO_BACK_COVER,
  NO_PAINT,
  NO_PAINT_WAXED_INSIDE,
  ORDINARY_PAINT_BASE,
  ORDINARY_PAINT_COMPLETE,
  SLIDES,
  TBD
} from '../model/model-types'

export const getDefaultOptionNameValue = (optionName: string): OptionValueNameItem[] => {
  const exist = overrideDefaults.find((optionValue: OptionValues) => optionValue.name === optionName)
  if (exist) {
    return exist.options
  }
  return [{
    key: '',

    sv: `!!Värdet är inte satt i modellen!! ${optionName}`,
    en: `!!NOT SET IN MODEL!! + ${optionName}`
  }]
}

export const overrideDefaults: OptionValues[] = [
  {
    name: 'BackPanel',
    options: [
      {key: NO_BACK_COVER, sv: 'Ingen täcksida bak', en: 'No back panel'},
      {key: BACK_PANEL_PLAIN, sv: 'Slät täcksida bak', en: 'BACK_PANEL: YUES!'},
      {key: BACK_PANEL_MIRROR, sv: 'Täcksida med spegel bak', en: 'BACK_PANEL: YUES!'}
    ]
  },
  {
    name: 'BrassPlate',
    options: [
      {key: 'Vänster', sv: 'Bakre täcksida: Ja', en: 'Brass tag: Left'},
      {key: 'Höger', sv: 'Bakre täcksida: Ja', en: 'Brass tag: Right'},
      {key: 'Båda', sv: 'Bakre täcksida: Ja', en: 'Brass tag: Both'}
    ]
  },
  {
    name: 'CarpenterJoy',
    options: [
      {
        key: 'Ja',

        sv: 'Snickarglädje"-dekoration i nedre vänstra hörnet',
        en: 'Curve decoration in bottom left corner'
      },
      {
        key: 'Ja',

        sv: 'Snickarglädje"-dekoration i nedre högra hörnet',
        en: 'Curve decoration in bottom right corner'
      },
      {
        key: 'Båda',

        sv: 'Snickarglädje"-dekorationer i frontramens båda nedre hörn',
        en: 'Curve decoration in bottom corners'
      }
    ]
  },
  {
    name: 'CenterPost',
    options: [
      {
        key: 'Fast mittstolpe mellan luckorna',

        sv: 'Fast mittstolpe mellan luckorna',
        en: 'Fixed mid-post between the doors'
      },
      {
        key: 'Mittstolpen är fixerad vid en av luckorna',

        sv: 'Mittstolpen är fixerad vid en av luckorna',
        en: 'Fixed mid-post at one of the doors'
      },
      {
        key: 'Ingen mittstolpe mellan luckorna',

        sv: 'Ingen mittstolpe mellan luckorna',
        en: 'No mid-post'
      }
    ]
  },
  {
    name: 'CombinedUnit',
    options: [
      {
        key: 'left',

        sv: 'Skåpet byggs som en enhet tillsammans med skåpet till vänster',
        en: 'Combined unit with the cabinet on the left'
      },
      {
        key: 'right',

        sv: 'Skåpet byggs som en enhet tillsammans med skåpet till höger',
        en: 'Combined unit with the cabinet on the right'
      },
      {
        key: 'top',

        sv: 'Skåpet byggs som en enhet tillsammans med skåpet ovanför',
        en: 'Combined unit with the cabinet on top'
      },
      {
        key: 'bot',

        sv: 'Skåpet byggs som en enhet tillsammans med skåpet nedan',
        en: 'Combined unit with the cabinet below'
      }
    ]
  },
  {
    name: 'Cornice',
    options: [
      {key: 'Ja', sv: 'Krönlist: Ja', en: 'Cornice: Yes'}
    ]
  },
  {
    name: 'CoverSide',
    options: [
      {key: COVER_SIDE_PLAIN, sv: 'Slät täcksida', en: 'Plain endpanels'},
      {key: COVER_SIDE_MIRROR, sv: 'Täcksida med spegel', en: 'Cover side with spegel'},
      {key: 'Förlängd', sv: 'förlängd med', en: 'Extended cover sides'},
      {key: COVER_SIDE_CONSOLE_CONCAVE, sv: 'Med konkav konsol', en: 'With concave console'},
      {key: COVER_SIDE_CONSOLE_CONVEX, sv: 'Med konvex konsol', en: 'With convex console'},
      {key: COVER_SIDE_CONSOLE_NONE, sv: 'Utan konsol', en: 'Without console'}
    ]
  },
  {
    name: 'CuttingBoard',
    options:
      [
        {key: 'Ja', sv: 'Utdragbar skärbräda', en: 'Chopping board'}
      ]
  },
  {
    name: 'Door',
    options: [
      {key: 'Vitrinlucka', sv: 'Vitrinlucka', en: 'Glass door'},
      {key: 'Ingen lucka', sv: 'Ingen lucka', en: 'No door'},
      {key: 'Ja', sv: 'Målad insida', en: 'Painted inside'},
      {key: 'Nej', sv: 'Ingen målad insida', en: 'No painted inside'}
    ]
  },
  {
    name: 'DoorAttachment',
    options: [
      {
        key: DOOR_ON_DOOR,

        sv: 'Luckan monteras "door-on-door" och har därför inga egna gångjärn',
        en: 'Door-on-door - no hinges!'
      },
      {
        key: SLIDES,

        sv: 'Anpassas för kyl/frys som har glidskenor, OBS! Inte "door-on-door")',
        en: 'Slides - include hinges!'
      },
      {
        key: TBD,

        sv: 'OBS! Kulladal behöver veta om kylen är "door-on-door" eller om den har glidskenor',
        en: 'TBD? - door-on-door - or slides?'
      }
    ]
  },
  {
    name: 'DoorType',
    options: [
      {
        key: '0. Ej specificerat',

        sv: '',
        en: ''
      },
      {
        key: '1. Överfalsad profilerad lucka',

        sv: 'Överfalsad profilerad lucka',
        en: 'Type 1 (lipped door with rounded profile on all sides)'
      },
      {
        key: '2. Innanförliggande profilerad lucka',

        sv: 'Innanförliggande profilerad lucka',
        en: 'Innanförliggande profilerad lucka'
      },
      {
        key: '3. Innanförliggande shakerlucka',

        sv: 'Innanförliggande shakerlucka',
        en: 'Innanförliggande shakerlucka'
      },
      {key: 'Door style 4', sv: 'Type 4', en: 'Type 4'}
    ]
  },
  {
    name: 'DrawerDoor',
    options: [
      {key: 'Låda', sv: 'Låda under ugn', en: 'Drawer beneath oven'},
      {key: 'Lucka', sv: 'Lucka under ugn', en: 'Door beneath oven'}
    ]
  },
  {
    name: 'DrawerFront',
    options: [
      {key: 'Standard', sv: 'Standardlådfront', en: 'Standard drawer fronts'},
      {key: 'Släta lådfronter', sv: 'Släta lådfronter', en: 'Plain'}
    ]
  },
  {
    name: 'DrawerInsert',
    options: [
      {key: 'Bestickinsats', sv: 'Bestickinsats', en: 'cutlery dividers'},
      {key: 'Knivblock', sv: 'Knivblock', en: 'knife block'},
      {key: 'Kryddinsatser', sv: 'Kryddinsatser', en: 'wavy'}
    ]
  },
  {
    name: 'FanAdoption',
    options: [
      {key: 'Ja', sv: 'Anpassad för fläkt', en: 'Fan adaption'}
    ]
  },
  {
    name: 'FrameWidth',
    options: [
      {key: 'left', sv: 'Ramens bredd, vänster sida: ', en: 'Frame width left: '},
      {key: 'right', sv: 'Ramens bredd, höger sida: ', en: 'Frame width right: '}
    ]
  },
  {
    name: 'Filler',
    options: [
      {key: 'fillerLeft', sv: 'Filler på vänster sida:', en: 'Filler on left side:'},
      {key: 'fillerRight', sv: 'Filler på höger sida:', en: 'Filler on right side:'},
      {key: 'fillerTop', sv: 'Indrag övre:', en: 'Top Recess:'},
      {key: 'fillerBottom', sv: 'Indrag undre:', en: 'Bottom Recess:'}
    ]
  },
  {
    name: 'HandleDoor',
    options: [
      {
        key: 'Ja',

        sv: 'Vred',
        en: 'Turning knobs'
      },
      {
        key: 'Nej',

        sv: 'Inte vred',
        en: 'No turning knobs'
      }
    ]
  },
  {
    name: 'HandleDrawer',
    options: [
      {
        key: 'handtag_fornicklad_massing',

        sv: 'Handtag Förnicklad Mässing',
        en: 'Handle Nickle Platted Brass'
      },
      {key: '1843_gold', sv: 'Knoppar i guld', en: 'Gold knobs'},
      {key: '1843_silver', sv: 'Knoppar i silver', en: 'Silver knobs'},
      {key: 'ldhandtag_silver', sv: 'Handtag i silver', en: 'Silver handles'},
      {key: 'handtag_massing', sv: 'Handtag i mässing', en: 'Brass handles'},
      {key: 'skalhandtag_massing', sv: 'Skalhandtag i mässing', en: 'Skalhandtag i mässing'}
    ]
  },
  {
    name: 'Hanging',
    options: [
      {key: 'v_nster', sv: 'En vänsterhängd lucka', en: 'Left'},
      {key: 'h_ger', sv: 'En högerhängd lucka', en: 'Right'},
      {key: 'v_nster-v_nster', sv: 'Två vänsterhängda luckor', en: 'Left-Left'},
      {key: 'h_ger-h_ger', sv: 'Två högerhängda luckor', en: 'Right-Right'},
      {key: 'v_nster-h_ger', sv: 'En vänsterhängd och en högerhängd lucka', en: 'Left-Right'},
      {
        key: 'v_nster-v_nster-h_ger',

        sv: 'Två vänsterhängda och en högerhängd lucka',
        en: 'Left-Left-Right'
      },
      {
        key: 'v_nster-h_ger-h_ger',

        sv: 'En vänsterhängd och två högerhängda luckor',
        en: 'Left-Right-Right'
      },
      {key: 'upptill', sv: 'Luckan hängd i ovankant', en: 'Top'},
      {key: 'nertill', sv: 'Luckan hängd i underkant', en: 'Bottom'},
      {
        key: 'v_nster-h_ger_x2',

        sv: 'Två vänsterhängda och två högerhängda luckor',
        en: 'Left-Right-x2'
      },
      {
        key: 'v_nster-v_nster-h_ger_x2',

        sv: 'Fyra vänsterhängda och två högerhängda luckor',
        en: 'Left-Left-Right-x2'
      },
      {
        key: 'v_nster-h_ger-h_ger_x2',

        sv: 'Fyra högerhängda och två vänsterhängda luckor',
        en: 'Left-Right-Right-x2'
      }
    ]
  },
  {
    name: 'HiddenDrawer',
    options: [
      {key: 'Utdragslåda, hög', sv: 'Utdragslåda, hög: ', en: 'Hidden drawer, high: '},
      {key: 'Utdragslåda, låg', sv: 'Utdragslåda, låg: ', en: 'Hidden drawer, low: '},
      {key: 'Hyllplan', sv: 'Hyllplan: ', en: 'Shelves: '}
    ]
  },
  {
    name: 'HiddenDrawerSink',
    options: [
      {
        key: 'En extra utdragslåda',

        sv: 'En extra utdragslåda, att montera ovanför lådan med metallkärlen. ' +
          'Denna låda levereras separat och monteras på plats (för att underlätta för VVS-montage)',
        en: 'Note! 1 hidden drawer - don\'t fix it in the cabinet, it should be separate'
      },
      {
        key: 'Två extra utdragslådor',

        sv: 'Två extra utdragslådor, att montera ovanför lådorna med metallkärlen. ' +
          'Dessa lådor levereras separat och monteras på plats (för att underlätta för VVS-montage)',
        en: 'Note! 2 hidden drawers - don\'t fix them in the cabinet, they should be separate'
      }
    ]
  },
  {
    name: 'Hinges',
    options: [
      {
        key: '1. Klassiskt lyftgångjärn',

        sv: 'Klassiskt lyftgångjärn',
        en: 'Standard old fashioned hinges'
      },
      {key: '2. Moderna mjukstängande gångjärn', sv: 'Moderna mjukstängande gångjärn', en: 'Modern'},
      {
        key: '3. Modernt gångjärn med synlig tapp',

        sv: 'Modernt gångjärn med synlig tapp',
        en: 'Modern with visible pin'
      }
    ]
  },
  {
    name: 'Legs',
    options: [
      {key: 'Vänster', sv: 'Ben på vänster sida', en: 'Legs on left side'},
      {key: 'Höger', sv: 'Ben på höger sida', en: 'Legs on right side'},
      {key: 'Båda', sv: 'Ben på båda sidor', en: 'Legs on both sides'}
    ]
  },
  {
    name: 'Lighting',
    options: [
      {key: 'Spotlights undertill', sv: 'Spotlights undertill', en: 'Spotlights below'},
      {key: 'LED-strips undertill', sv: 'LED-strips undertill', en: 'LED strips below'},
      {
        key: 'Spotlights undertill, och LED-strips inuti skåpet',

        sv: 'Spotlights undertill, och LED-strips inuti skåpet',
        en: 'Spotlights below and LED strips inside'
      },
      {
        key: 'LED-strips undertill och inuti skåpet',

        sv: 'LED-strips undertill och inuti skåpet',
        en: 'LED strips below and inside'
      },
      {key: 'LED-strips inuti skåpet', sv: 'LED-strips inuti skåpet', en: 'LED strips inside'}
    ]
  },
  {
    name: 'PaintProcess',
    options: [
      {
        key: LINSEED_COMPLETE,

        sv: 'Linoljefärg, komplett (två strykningar i snickeriet, en strykning efter montering)',
        en: 'Standard linseed paint process (waxed insides as usual)'
      },
      {
        key: LINSEED_BASE,
        sv: 'Linoljefärg, grundmålning (kunden slutmålar själv)',
        en: 'Standard linseed paint process (waxed insides as usual)'
      },
      {
        key: ORDINARY_PAINT_COMPLETE,

        sv: 'Vanlig färg (ej linoljefärg). Grundmålning i snickeriet och slutstrykning efter montering.',
        en: 'Regular paint - NOT LINSEED PAINT (waxed insides as usual)'
      },
      {
        key: NO_PAINT_WAXED_INSIDE,
        sv: 'Omålat på utsidorna, vaxat på insidorna',
        en: 'No paint on inside, vaxed outsides'
      },
      {
        key: NO_PAINT,
        sv: 'Helt omålat och obehandlat', en: 'Unpainted and NO wax on insides'
      },
      {
        key: ORDINARY_PAINT_BASE,
        sv: 'Vanlig färg (ej linoljefärg). Grundmålning i snickeriet, kunden slutmålar själv.',
        en: 'Regular paint - NOT LINSEED PAINT (waxed insides as usual)'
      }
    ]
  },
  {
    name: 'PaintSide',
    options: [
      {
        key: 'Vänster',
        sv: 'Målad sida vänster', en: 'Paint side because of DW: Left'
      },
      {
        key: 'Höger',
        sv: 'Målad sida höger', en: 'Paint side because of DW: Right'
      },
      {
        key: 'Båda',
        sv: 'Målad sida båda', en: 'Paint side because of DW: Left and right'
      },
      {
        key: 'Ingen',
        sv: 'Inget kladd på sidorna', en: 'Nones'
      }
    ]
  },
  {
    name: 'Plinth',
    options: [
      {key: 'Sockelhöjd', sv: 'Sockelhöjd: ', en: 'Plinth height: '}
    ]
  },
  {
    name: 'Scribings',
    options: [
      {
        key: 'Ingen överdimensionering',

        sv: 'Ingen överdimensionering',
        en: 'No scribing'
      }, {
        key: 'Höger',

        sv: '40 mm extra på höger sida (överdimensionerat, bör sågas av vid montering)',
        en: 'Scribing 40 mm right'
      },
      {
        key: 'Vänster',

        sv: '40 mm extra på vänster sida (överdimensionerat, bör sågas av vid montering)',
        en: 'Scribing 40 mm left'
      },
      {
        key: 'Både höger och vänster',

        sv: '40 mm extra på höger och vänster sida (överdimensionerat, bör sågas av vid montering)',
        en: 'Scribing 40 mm both left and right'
      }
    ]
  },
  {
    name: 'Shelves',
    options: [
      {key: 'Antal hyllplan', sv: 'Antal hyllplan: ', en: 'Number of shelves: '}
    ]
  },
  {
    name: 'ShelvesAdjustable',
    options: [
      {
        key: 'Ja',

        sv: 'Ställbara hyllplan som vilar på flyttbara bärlister',
        en: 'Adjustable shelves: '
      }
    ]
  },
  {
    name: 'Skirting',
    options: [
      {
        key: STANDARD_SKIRTING,

        sv: 'Standardsockel (inskjuten) - ca 90 mm hög (Skåpen står på en justerbar sockelbox, som är 70 mm hög, ' +
          'och denna har ställfötter som går att justera. Sockelns totalhöjd är därför variabel från 70 mm till ca 95 mm)',
        en: 'Outside skirt'
      },
      {
        key: OUTSIDE_SKIRTING,

        sv: 'Utanpåliggande sockellist - 95 mm hög. (Skåpen står på en justerbar sockelbox, som är 70 mm hög, ' +
          'och denna har ställfötter som går att justera. Sockelboxens totalhöjd är därför variabel från 70 mm till ca 95 mm)',
        en: 'Outside skirting board (plywood boxes behind it)'
      }
    ]
  },
  {
    name: 'SpiceRack',
    options: [
      {key: 'Kryddhylla', sv: 'Kryddhylla', en: 'Spice Rack'},
      {key: 'Burkhylla', sv: 'Burkhylla', en: 'Jar rack'},
      {key: 'Combo', sv: 'Kombinerad krydd- och burkhylla', en: 'Combined spice and jar rack'}
    ]
  },
  {
    /**
     * This is only used set to avoid strange messages if spotted.
     */
    name: 'HiddenVisibleWidth',
    options: [
      {key: 'not_in_use', sv: 'not_in_use', en: 'not_in_use'}
    ]
  }
]

<form *ngIf="projectHelperService.project$ | async; let project" [formGroup]="form">
  <div [ngClass]="{ 'hide-form-control' : formControlToHide['projectName'] }" class="input-holder">
    <mat-form-field>
      <mat-label kdlI18n label="projectName"></mat-label>
      <input formControlName="customerName"
             matInput
             type="text">
    </mat-form-field>

    <p kdlI18n label="projectNameHint"></p>
  </div>


  <div [ngClass]="{ 'hide-form-control' : formControlToHide['customerPrice'] }"
       class="input-holder">
    <mat-form-field class="narrow">
      <mat-label kdlI18n label="priceToCustomer"></mat-label>
      <input class="kdl-no-flippers"
             formControlName="customerPrice"
             matInput
             type="number">
      <span matTextSuffix="">kr</span>
    </mat-form-field>
    <p>
      <span kdlI18n label="priceToCustomer"></span>
    </p>
  </div>

  <!-- Discount, calculated in percent -->
  <div [ngClass]="{ 'hide-form-control' : formControlToHide['discount'] }" class="input-holder">
    <mat-form-field class="narrow">
      <mat-label kdlI18n label="discountPercent"></mat-label>
      <input
        class="kdl-no-flippers"
        formControlName="discountPercent"
        matInput
        type="number">
    </mat-form-field>
    <p>{{discountString}}</p>
  </div>

  <!-- Customer Version - customerVersion -->
  <div [ngClass]="{ 'hide-form-control' : formControlToHide['customerVersion'] }"
       class="input-holder">
    <mat-form-field class="narrow">
      <mat-label kdlI18n label="customerVersion"></mat-label>
      <input class="kdl-no-flippers"
             formControlName="customerVersion"
             matInput
             type="number">
    </mat-form-field>
    <p kdlI18n label="customerVersionHint"></p>
  </div>
  <!-- Paint process -->
  <mat-form-field [ngClass]="{ 'hide-form-control' : formControlToHide['paintProcess'] }">
    <mat-label kdlI18n label="paintProcess"></mat-label>
    <mat-select [placeholder]="'paintProcessPlaceholder' | i18n | async" formControlName="paintProcess"
                ngDefaultControl>
      <mat-option [value]="1"><span kdlI18n label="lineSeed"></span></mat-option>
      <mat-option [value]="2"><span kdlI18n label="primered"></span></mat-option>
      <mat-option [value]="3"><span kdlI18n label="normalPaint"></span></mat-option>
      <mat-option [value]="4"><span kdlI18n label="waxed"></span></mat-option>
      <mat-option [value]="5"><span kdlI18n label="noPaint"></span></mat-option>
      <mat-option [value]="6"><span kdlI18n label="normalPaintBase"></span></mat-option>
    </mat-select>
  </mat-form-field>
  <!-- Color -->
  <!--   <mat-form-field>-->
  <!--    <mat-label kdlI18n label="color"></mat-label>-->
  <!--    <input [matAutocomplete]="auto"-->
  <!--           aria-label="Number"-->
  <!--           formControlName="color"-->
  <!--           matInput-->
  <!--           placeholder="Pick one"-->
  <!--           type="text">-->
  <!--    <mat-autocomplete #auto="matAutocomplete" autoActiveFirstOption>-->
  <!--      <mat-option *ngFor="let option of colorFilteredOptions | async" [value]="option">-->
  <!--        {{option}}-->
  <!--      </mat-option>-->
  <!--    </mat-autocomplete>-->
  <!--  </mat-form-field>-->

  <!--  <div *ngIf="fixComplete">-->
  <!--    <div [ngClass]="{ 'hide-form-control' : formControlToHide['color'] || hideColor }" class="color-holder">-->
  <!--      <mat-form-field class="narrow">-->
  <!--        <mat-label kdlI18n label="color"></mat-label>-->
  <!--        <mat-select (valueChange)="clearCustomColor($event)" [placeholder]="'colorNumber' | i18n | async"-->
  <!--                    formControlName="color" ngDefaultControl>-->
  <!--          <mat-option *ngFor="let color of colors" [value]="color.val">{{color.text}}</mat-option>-->
  <!--        </mat-select>-->
  <!--      </mat-form-field>-->
  <!--      <mat-form-field *ngIf="form.controls.color.value === 'Special: NCS'" class="narrow color">-->
  <!--        <mat-label kdlI18n label="customColor"></mat-label>-->
  <!--        <input [placeholder]="'colorNumber' | i18n | async" formControlName="customColor" matInput>-->
  <!--      </mat-form-field>-->
  <!--    </div>-->
  <!--  </div>-->
    <mat-form-field
      [ngClass]="{ 'hide-form-control' : formControlToHide['color']}" class="narrow">
      <mat-label kdlI18n label="color"></mat-label>
      <input [placeholder]="'colorNumber' | i18n | async" formControlName="color" matInput>
    </mat-form-field>
  <!--  <div [ngClass]="{ 'hide-form-control' : formControlToHide['carpentry']}" class="color-holder">-->
  <!--    <mat-form-field class="narrow">-->
  <!--      <mat-label kdlI18n label="carpentryLabel"></mat-label>-->
  <!--      <mat-select (valueChange)="clearCustomCarpentry($event)" [placeholder]="'carpentryLabel' | i18n | async"-->
  <!--                  formControlName="carpentry" ngDefaultControl>-->
  <!--        <mat-option *ngFor="let carpenter of carpenters" [value]="carpenter.val">{{carpenter.text}}</mat-option>-->
  <!--      </mat-select>-->
  <!--    </mat-form-field>-->
  <!--    <mat-form-field *ngIf="form.controls.carpentry.value === 'Custom carpentry'" class="narrow color">-->
  <!--      <mat-label kdlI18n label="customCarpentryLabel"></mat-label>-->
  <!--      <input [placeholder]="'customCarpentryLabel' | i18n | async" formControlName="customCarpentry" matInput>-->
  <!--    </mat-form-field>-->
  <!--  </div>-->
  <!--  <div [ngClass]="{ 'hide-form-control' : formControlToHide['cad']}" class="color-holder">-->
  <!--    <mat-form-field class="narrow">-->
  <!--      <mat-label kdlI18n label="cadLabel"></mat-label>-->
  <!--      <mat-select (valueChange)="clearCustomCad($event)" [placeholder]="'cadLabel' | i18n | async"-->
  <!--                  formControlName="cad" ngDefaultControl>-->
  <!--        <mat-option *ngFor="let cad of caders" [value]="cad.val">{{cad.text}}</mat-option>-->
  <!--      </mat-select>-->
  <!--    </mat-form-field>-->
  <!--    <mat-form-field *ngIf="form.controls.cad.value === 'Custom CAD'" class="narrow color">-->
  <!--      <mat-label kdlI18n label="customCadLabel"></mat-label>-->
  <!--      <input [placeholder]="'customCadLabel' | i18n | async" formControlName="customCad" matInput>-->
  <!--    </mat-form-field>-->
  <!--  </div>-->
  <!--  <div [ngClass]="{ 'hide-form-control' : formControlToHide['painter']}" class="color-holder">-->
  <!--    <mat-form-field class="narrow">-->
  <!--      <mat-label kdlI18n label="painterLabel"></mat-label>-->
  <!--      <mat-select (valueChange)="clearCustomPainter($event)" [placeholder]="'painterLabel' | i18n | async"-->
  <!--                  formControlName="painter" ngDefaultControl>-->
  <!--        <mat-option *ngFor="let painter of painters" [value]="painter.val">{{painter.text}}</mat-option>-->
  <!--      </mat-select>-->
  <!--    </mat-form-field>-->
  <!--    <mat-form-field *ngIf="form.controls.painter.value === 'Custom painter'" class="narrow color">-->
  <!--      <mat-label kdlI18n label="customPainterLabel"></mat-label>-->
  <!--      <input [placeholder]="'customPainterLabel' | i18n | async" formControlName="customPainter" matInput>-->
  <!--    </mat-form-field>-->
  <!--  </div>-->

  <!-- Country, should be checked how this relates to Customer -->
  <mat-form-field [ngClass]="{ 'hide-form-control' : formControlToHide['lc'] }">
    <mat-label kdlI18n label="country">Land</mat-label>
    <mat-select [placeholder]="'countryPlaceholder' | i18n | async"
                formControlName="lc" id="country" ngDefaultControl>
      <mat-option [value]="'sv'"><span kdlI18n label="sweden"></span></mat-option>
      <mat-option [value]="'fi'"><span kdlI18n label="finland"></span></mat-option>
      <mat-option [value]="'da'"><span kdlI18n label="denmark"></span></mat-option>
      <mat-option [value]="'nb'"><span kdlI18n label="norway"></span></mat-option>
      <mat-option [value]=""></mat-option>
    </mat-select>
  </mat-form-field>

  <div [ngClass]="{ 'hide-form-control' : formControlToHide['socelHeight'] }" class="input-holder">
    <mat-form-field class="narrow">
      <mat-label kdlI18n label="socelHeight"></mat-label>
      <input class="kdl-no-flippers" formControlName="socelHeight" matInput type="number">
      <span matTextSuffix="">mm</span>
    </mat-form-field>
    <p kdlI18n label="socelHeightHint"></p>
  </div>

  <div [ngClass]="{ 'hide-form-control' : formControlToHide['factoryInvoice'] }" class="checkbox-holder">
    <mat-checkbox formControlName="hideFactoryInvoice" ngDefaultControl>
      <span kdlI18n label="priceToCarpentry"></span>
    </mat-checkbox>
  </div>
</form>

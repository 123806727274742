import {Injectable} from '@angular/core'
import {HttpErrorResponse, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http'
import {catchError, map, switchMap} from 'rxjs/operators'
import {throwError} from 'rxjs'
import {AuthService, IAuthInfo} from '../services/auth.service'


/**
 * Intended to catch e.g. 401 responses and if so re-route to home.
 */
@Injectable()
export class ResponseInterceptor implements HttpInterceptor {

  constructor(
    private authService: AuthService) {
  }

  intercept(req: HttpRequest<any>, next: HttpHandler) {

    return next.handle(req)
      .pipe(
        catchError((error: HttpErrorResponse) => {
          if (error.status === 401) {
            return this.authService.checkLogin().pipe(
              switchMap((res: boolean) => {
                if (res === false) {
                  return throwError(error)
                }
                return this.authService.authState$
              }),
              map((auth: IAuthInfo) => req.clone({
                headers: req.headers.set('Authorization', `Bearer ${auth.accessToken}`)
              })),
              switchMap((authReq: HttpRequest<any>) => next.handle(authReq))
            )
          }
          return throwError(error)
        }),
      )
  }
}

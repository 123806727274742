import {IProduct} from '../common/interface/product-types'
import {CabinetOption, ViewOptionType} from './cabinet-option'
import {ProdboardCabinetOption} from '../services/prodboard-types'

export class ShelvesAdjustable extends CabinetOption {
  /**
   * Must be statically set _before_ constructor?
   */
  public readonly optionSelectName = 'ShelvesAdjustable'

  constructor(option: ProdboardCabinetOption, product: IProduct, cabinetId: number) {
    super(option, product, cabinetId)
    this.setNameAndOptions()

    this.priority = 10

    this.viewOptions = [
      {
        type: ViewOptionType.select,
        title: 'Flyttbara hyllplan',
        name: 'shelvesAdjustable',
        values: ['Ja', 'Nej'],
        selection: '',
        disabled: false
      }
    ]

    this.description = 'Special kind of shelf style'
    this.title = 'optShelvesAdjustable'

    const sOptions = ShelvesAdjustable.sanitize(option)
    this.setValuesFromProdboardData(sOptions)
    this.setPrice()
  }

  private static sanitize(option: ProdboardCabinetOption): ProdboardCabinetOption {
    option.value = option.value || {} as any
    option.value.name = option.value.name || 'Nej'

    return option
  }

  public update(data: any): void {
    super.update(data)
    this.setFromProperties(data)
    this.active = this.viewOptions[0].selection === 'Ja'
    this.setPrice()
  }

  protected getCustomCustomerListing(lc: string): string[] {
    return [this.optionValueNames[0][lc]]
  }

  private setValuesFromProdboardData(options: ProdboardCabinetOption) {
    this.viewOptions[0].selection = options.value.name
    this.active = this.viewOptions[0].selection === 'Ja'
  }

  private setPrice(): void {
    this.resetPrices()
    if (this.active) {
      this.price = this.product.adShPr.price
      this.labor = this.product.adShPr.labor
      const item = this.addReceiptItem()
      item.priceProperty = 'adShPr'
      this.shouldHavePrice = true
    }
    this.addPriceFromComments()
  }
}

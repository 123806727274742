import {Component} from '@angular/core'
import {ProjectService} from '../../../services/project.service'

@Component({
  selector: 'kdl-project-prodboard-summary',
  templateUrl: './project-prodboard-summary.component.html',
  styleUrls: ['./project-prodboard-summary.component.scss']
})
export class ProjectProdboardSummaryComponent {

  constructor(
    public projectService: ProjectService
  ) {
  }
}

import {CabinetOption, ViewOption, ViewOptionType} from './cabinet-option'
import {IProduct} from '../common/interface/product-types'
import {FactoryData} from './factory/factory-data'
import {Skirting} from './skirting'
import {FrameWidth} from './frame-width/frame-width'
import {ProdboardCabinetOption} from '../services/prodboard-types'
import {FILLER_BOTTOM_POS, FILLER_LEFT_POS, FILLER_RIGHT_POS, FILLER_TOP_POS} from './model-types'

export class Filler extends CabinetOption {

  public readonly optionSelectName = 'Filler'

  constructor(option: ProdboardCabinetOption, product: IProduct, cabinetId: number) {
    super(option, product, cabinetId)
    this.setNameAndOptions()

    this.priority = 6 // Right after frames
    this.viewOptions = [
      {
        type: ViewOptionType.text,
        title: 'Övre indrag (mm)',
        name: 'fillerTop',
        values: [],
        selection: '0',
        disabled: false
      },
      {
        type: ViewOptionType.text,
        title: 'Vänster indrag (mm)',
        name: 'fillerLeft',
        values: [],
        selection: '0',
        disabled: false
      },
      {
        type: ViewOptionType.text,
        title: 'Höger indrag (mm)',
        name: 'fillerRight',
        values: [],
        selection: '0',
        disabled: false
      },
      {
        type: ViewOptionType.text,
        title: 'Undre indrag (mm)',
        name: 'fillerBottom',
        values: [],
        selection: '0',
        disabled: false
      }
    ]
    this.active = false
    this.description = 'This one is hard to explain, but it is about how the facade frame is joined to the carcass'
    this.title = 'optFiller'
    Filler.sanitize(option)
    this.setValuesFromProdboardData(option)
  }

  get bottom(): number {
    return +this.viewOptions[FILLER_BOTTOM_POS].selection
  }

  get left(): number {
    return +this.viewOptions[FILLER_LEFT_POS].selection
  }

  get right(): number {
    return +this.viewOptions[FILLER_RIGHT_POS].selection
  }

  get top(): number {
    return +this.viewOptions[FILLER_TOP_POS].selection
  }

  private static sanitize(option: ProdboardCabinetOption): ProdboardCabinetOption {
    option.value = option.value || {left: 0, right: 0} as any
    return option
  }

  public getFactoryData(): FactoryData {
    return {
      fillers: {
        left: this.left,
        right: this.right,
        top: this.top,
        bottom: this.bottom,
      }
    }
  }

  public update(data: any): void {
    super.update(data)
    this.viewOptions[FILLER_BOTTOM_POS].selection = data.fillerBottom || 0
    this.viewOptions[FILLER_LEFT_POS].selection = data.fillerLeft || 0
    this.viewOptions[FILLER_RIGHT_POS].selection = data.fillerRight || 0
    this.viewOptions[FILLER_TOP_POS].selection = data.fillerTop || 0
    this.setActive()

  }

  public setTopAndBottom(skirting: Skirting, frame: FrameWidth): void {
    const defaults = this.getDefaultRecess(this.product, skirting, frame)
    const data = {fillerTop: defaults.tr, fillerBottom: defaults.br, fillerLeft: this.left, fillerRight: this.right}
    this.update(data)
    this.valueChanges$.next(true)
  }

  // We don't want fillers listed in the customer- or factory print view, so we return nothing
  protected getCustomCustomerListing(): string[] {
    return []
  }

  private setValuesFromProdboardData(option: ProdboardCabinetOption): void {
    this.viewOptions[FILLER_LEFT_POS].selection = option.value.left
    this.viewOptions[FILLER_RIGHT_POS].selection = option.value.right
    this.setActive()
  }

  private setActive(): void {
    this.active = this.viewOptions.reduce((sum: number, opt: ViewOption) => sum + +opt.selection, 0) > 0
  }
}

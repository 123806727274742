import {ProdboardCabinet} from '../cabinet/prodboard-cabinet'
import {CabinetOption} from '../cabinet-option'
import {Lighting} from '../lighting'
import {Inject, Injectable} from '@angular/core'
import {StoppersAndCatchers} from './stoppers-and-catchers'
import {HandleDoor} from '../handle-door'
import {NO_DOOR_SELECTED} from '../door'

interface CountMap {
  [key: string]: number[]
}

export interface SummaryProperties {
  description: string
  cabinetNumbers: number[]
}

@Injectable({
  providedIn: 'root',
})
export class ProjectOption {

  public stringAddition: { sv: string; en: string } = {sv: 'på skåp', en: 'on cabinets'}

  /**
   * Tells if any cabinet have lightning
   */
  public lightning = false

  private readonly lc: 'sv' | 'en' = 'sv'

  private skirtCount: CountMap = {}

  private doorCount: CountMap = {}

  private coverSideCount: CountMap = {}

  private drawerFrontCount: CountMap = {}

  private hingeCount: CountMap = {}

  private paintCount: CountMap = {}

  private shelvesCount: CountMap = {}

  private centerPostCount: CountMap = {}

  private stoppersAndCatchersCount: CountMap = {}

  private scribingsCount: CountMap = {}

  private stoppersAndCatchers = {
    a: {sv: 'Magnet i luckan, metallstopp i stommen', en: 'Our magnets and stoppers'},
    b: {sv: 'Metallvinkel som stopp, inga magneter', en: 'No magnets - just the stoppers'},
    c: {sv: 'Inga magneter eller luckstopp', en: 'No magnets no stoppers'},
  }

  constructor(
    @Inject(ProdboardCabinet) private cabinets: ProdboardCabinet[],
    @Inject(String) lc: 'sv' | 'en' = 'sv',
  ) {
    this.lc = lc
    cabinets
      .map((cabinet: ProdboardCabinet) => {
        const door = cabinet.options.find((option: CabinetOption) => option.optionSelectName === 'Door')
        let noDoor = false
        if (door) {
          noDoor = door.viewOptions[0].selection === NO_DOOR_SELECTED
        }
        cabinet.options.forEach((option: CabinetOption) => {
          const countMap = this.getMap(option)
            if (countMap && !(option.optionSelectName === 'DoorType' && noDoor)) {
              this.createSummary(countMap, option, cabinet.index)
            }
          /**
           * This is special since we only need to know IF any lightning is active
           */
          if (option instanceof Lighting) {
            this.lightning = this.lightning || option.active
          }
        })
        return cabinet
      })
      .filter((cabinet: ProdboardCabinet) => cabinet.hasStoppers)
      .forEach((cabinet: ProdboardCabinet) => {
        this.setStoppersAndCatchers(cabinet)
      })
  }
  public getSkirting(): SummaryProperties[] {
    return this.getFromSummary(this.skirtCount)
  }

  public getDoors(): SummaryProperties[] {
    return this.getFromSummary(this.doorCount)
  }

  public getCoverSides(): SummaryProperties[] {
    return this.getFromSummary(this.coverSideCount)
  }

  public getDrawerFront(): SummaryProperties[] {
    return this.getFromSummary(this.drawerFrontCount)
  }

  public getHinges(): SummaryProperties[] {
    return this.getFromSummary(this.hingeCount)
  }

  public getPaints(): SummaryProperties[] {
    return this.getFromSummary(this.paintCount)
  }

  public getShelves(): SummaryProperties[] {
    return this.getFromSummary(this.shelvesCount)
  }

  public getCenterPost(): SummaryProperties[] {
    return this.getFromSummary(this.centerPostCount)
  }

  public getScribings(): SummaryProperties[] {
    return this.getFromSummary(this.scribingsCount)
  }

  public getCatchersAndStoppers(): SummaryProperties[] {
    return this.getFromSummary(this.stoppersAndCatchersCount)
  }

  private createSummary(prop: { [key: string]: number[] }, option: CabinetOption, cabinetNumber: number): void {
    const value = option.getOption(this.lc)
    prop[value] = prop[value] || []
    prop[value].push(cabinetNumber)
  }

  private getFromSummary(prop: { [key: string]: number[] }): SummaryProperties[] {
    const res: any = []
    const keys = Object.keys(prop)
    keys
      .filter((s: string) => s)
      .forEach((key: string) => {
        const cabinetNumbers: number[] = prop[key]
        res.push(
          {
            description: key,
            cabinetNumbers,
          },
        )
      })
    return res
  }

  /**
   * Simply select the proper map based on class.
   */
  private getMap(option: CabinetOption): CountMap | undefined {
    switch (option.optionSelectName) {
      case 'Skirting':
        return this.skirtCount
      case 'DoorType':
        return this.doorCount
      case 'CoverSide':
        return this.coverSideCount
      case 'DrawerFront':
        return this.drawerFrontCount
      case 'Hinges':
        return this.hingeCount
      case 'PaintProcess':
        return this.paintCount
      case 'Shelves':
        return this.shelvesCount
      case 'CenterPost':
        return this.centerPostCount
      case 'Scribings':
        return this.scribingsCount
    }
  }

  private setStoppersAndCatchers(cabinet: ProdboardCabinet): void {
    const doorType = cabinet.options.find((option: CabinetOption) => option.optionSelectName === 'DoorType')
    const hinges = cabinet.options.find((option: CabinetOption) => option.optionSelectName === 'Hinges')
    const handles: HandleDoor =
      cabinet.options.find((option: CabinetOption) => option.optionSelectName === 'HandleDoor') as HandleDoor
    const door = cabinet.options.find((option: CabinetOption) => option.optionSelectName === 'Door')
    const type = StoppersAndCatchers.select(doorType, door, hinges, handles)
    if (type) {
      const key = this.stoppersAndCatchers[type][this.lc]
      this.stoppersAndCatchersCount[key] = this.stoppersAndCatchersCount[key] || []
      this.stoppersAndCatchersCount[key].push(cabinet.index)
    }
  }
}

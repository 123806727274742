import {ProductCategory} from '../../services/product-static.service'
import {CabinetOption} from '../cabinet-option'
import {Shelves} from '../shelves'
import {Lighting} from '../lighting'
import {UnknownOption} from '../unknown-option'
import {CenterPost} from '../center-post'
import {CoverSide} from '../cover-side'
import {BackPanel} from '../back-panel'
import {Door, NO_DOOR_SELECTED} from '../door'
import {FanAdoption} from '../fan-adoption'
import {CarpenterJoy} from '../carpenter-joy'
import {DrawerInsert} from '../drawer-insert'
import {CuttingBoard} from '../cutting-board'
import {Skirting} from '../skirting'
import {Hinges} from '../hinges'
import {PaintProcess} from '../paint-process'
import {HiddenDrawer} from '../hidden-drawer'
import {DoorType} from '../door-type'
import {Hanging} from '../hanging'
import {FrameWidth} from '../frame-width/frame-width'
import {Comment, CommentHomeEntity, Comments} from '../../comments/model/comment'
import {HandleDrawer} from '../handle-drawer'
import {HandleDoor} from '../handle-door'
import {ShelvesAdjustable} from '../shelves-adjustable'
import {Cornice} from '../cornice'
import {DrawerFront} from '../drawer-front'
import {ReceiptItem} from '../receipt/receipt-item'
import {IProdboardComment} from '../../services/prodboard.service'
import {Plinth} from '../plinth'
import {Scribings} from '../scribings'
import {OverrideService} from '../../services/override.service'
import {Filler} from '../filler'
import {CabinetSettings} from '../cabinet-settings/cabinet-setting'
import {HiddenDrawerSink} from '../hidden-drawer-sink'
import {HiddenVisibleWidth} from '../hidden-visible-width'
import {FrameWidthHelper} from '../frame-width/frame-width-helper'
import {DoorAttachment} from '../door-attachment'
import {BrassPlate} from '../brass-plate'
import {CombinedUnit} from '../combined-unit'
import {SpiceRack} from '../spice-rack'
import {DrawerDoor} from '../drawer-door'
import {PaintSide} from '../paint-side'
import {
  IProdboardPosition,
  ProdboardCabinetOption,
  ProdboardItem,
  ProdboardSelectionItem
} from '../../services/prodboard-types'
import {IProject, TPaintProcessValues} from '../../services/project-types'
import {Legs} from '../legs'
import {DOOR_ON_DOOR} from '../model-types'

export class ProdboardCabinet implements Comments {

  /**
   * The UID received from Prodboard
   */
  public uid = ''

  public index: number

  public code: string

  public readonly cat: string

  public description: string

  public dimensions: {
    x: number
    y: number
    z: number
  } = {x: 0, y: 0, z: 0}

  /**
   * Price infos
   */
  public price = 0

  public labor = 0

  public material = 0

  /**
   * Base price of cabinet, can be set in settings
   * defaults to product prices
   */
  public basePrice: number

  public baseLabor: number

  public baseMaterial: number

  /**
   * Description of what? In Swedish?
   */
  public deSwLaSw = ''

  public options: CabinetOption[] = []

  public comments: Comment[] = []

  public readonly receipt: ReceiptItem[] = []

  public prodboardComment: IProdboardComment | undefined

  public higherThanStandard = false

  public deeperThanStandard = false

  public hasSocel = false

  public isDishwasherCabinet = false

  public isBaseCornerCabinet = false

  public productComments = {
    sv: '',
    en: ''
  }

  public position: IProdboardPosition = {
    direction: {
      x: 0,
      y: 0,
      z: 0
    },
    center: {
      x: 0,
      y: 0,
      z: 0
    }
  }

  public drawers: number[] = []

  /**
   * These two variables a used when we override the prodboard data
   * in forms
   */
  public hWidth: number
  public vWidth: number

  /**
   * Let us expose our paint process so that the project
   * can select it. We convert the 'text' value to a number
   * that is suitable for the project
   */
  public paintProcess: TPaintProcessValues = 0

  /**
   * Visible and Hidden width are applicable only for "Corner" cabinets.
   * Hidden is used as marker, if > 0 we have both. Visible is normally
   * set to the width (x) of the cabinet.
   */
  public hiddenVisibleWidth: HiddenVisibleWidth | undefined

  public commentHome: CommentHomeEntity = {type: 'CABINET', id: this.uid}

  /**
   * Given the condition that the cabinet has no Door or Glass door
   * AND painted inside === NO then this has a value.
   */
  private paintedInsideNote = ''

  private pActualHeight = 0

  private readonly defaultProduct: any = {
    cat: 'EMPTY',
    pc: 'EMPTY',
    pr: {
      price: 0,
      labor: 0
    },
    exPrHiSt: {
      price: 0,
      labor: 0
    },
    exPrDeSt: {
      labor: 0,
      price: 0
    },
    shIdPr: {
      labor: 0,
      price: 0
    },
    frontFrame: {
      faLeFr: 0,
      faRiFr: 0
    },
    shDe: 0,
    scSe: 'default'
  }

  private readonly ignoredTypes: Set<any> = new Set(['UnknownOption', 'HandleDrawer'])

  private project: IProject

  private input: ProdboardItem

  /**
   * The override service that sets option names.
   * It has only one method `setOption() => void
   */
  private overrideService: OverrideService

  private readonly product: ProductCategory

  private settings: CabinetSettings = new CabinetSettings()

  /**
   * Three sensible defaults for handling the skirtings dependency
   * to fillers and frame
   */
  private skirting: Skirting = new Skirting({} as any, {} as any, 0)

  private frame: FrameWidth = new FrameWidth({} as any, {} as any, {
    index: 1,
    dimensions: {x: 450}
  } as any, new FrameWidthHelper())

  private fillers: Filler = new Filler({} as any, {} as any, 0)

  private legs: Legs = new Legs({} as any, {} as any, 0)

  private currentSkirting = ''

  private baseSettings: CabinetSettings = new CabinetSettings()

  /**
   * A very complex recalculation of a Prodboard cabinet combined with
   * the project data
   *
   * @param overrideService - Used to add new labels to options via the setOption method.
   * @param product - The product from the product database.
   * @param input - The Prodboard Cabinet item, containing the options and items.
   * @param project - The project data for the current project
   */
  constructor(
    overrideService: OverrideService = {} as any,
    product: ProductCategory = {} as any,
    input: ProdboardItem = {} as any,
    project: IProject = {form: {}} as any
  ) {

    this.product = product
    this.overrideService = overrideService
    this.input = input
    this.project = project
    // We do not want to fail if no prices. Or if the frames are not in place.
    this.product = Object.assign(this.defaultProduct, this.product)

    this.cat = product.cat
    this.code = product.pc
    this.productComments.sv = product.coSe
    this.productComments.en = product.co

    this.position = input.position || this.position

    this.basePrice = ProdboardCabinet.fixPrice(this.product.pr.price)
    this.baseLabor = ProdboardCabinet.fixPrice(this.product.pr.labor)
    this.baseMaterial = ProdboardCabinet.fixPrice(this.product.pr.material)

    this.product.nuDo = this.product.nuDo || 0
    this.product.nuDr = this.product.nuDr || 0

    /**
     * Guess if we have socel so that we can display that?
     * If no default socel, then no socel. And if 0 as default?
     */
    this.hasSocel = !!this.product.shDe

    this.description = product.tiLaSw
    this.deSwLaSw = product.deEnLaSw
    this.isDishwasherCabinet = this.product.idc

    this.isBaseCornerCabinet = this.product.ibcc

    // Not sure why I am copying this array, but I do.
    this.product.dhs = this.product.dhs || []
    this.product.dhs.forEach((height: number) => this.drawers.push(height))
    // From the prodboard file.
    this.index = input.index
    this.uid = input.uid

    this.commentHome.id = this.uid
    this.prodboardComment = this.input.prodboardComment || {} as any

    this.dimensions = Object.assign(this.dimensions, this.input.dimensions || {})


    this.baseSettings.fromCabinet(this)

    // When we come here we have a "product" from the product database
    // And an "item" from prodboard, we can now set interesting properties
    // based on the input.
    this.analyzeItems(this.input.items)
    this.analyzeOptions(this.input.options)
    this.options = CabinetOption.sortOptions(this.options)
    this.calculateCabinetPrice()
    // Needed to make sure all options have a proper id.
    this.options.forEach(o => o.setCommentHomeId(this.uid))
  }

  get hasStoppers(): boolean {
    return this.product.scSe === 'default'
  }

  get hiddenWidth(): number {
    if (this.hiddenVisibleWidth) {
      return this.hWidth || this.hiddenVisibleWidth.hiddenWidth
    }
    return 0
  }

  /**
   * If not a corner cabinet the visible width is same as width (x)
   */
  get visibleWidth(): number {
    if (this.hiddenVisibleWidth) {
      return this.vWidth || this.hiddenVisibleWidth.visibleWidth
    }
    return this.dimensions.x
  }

  /**
   * Returns true if this is considered an Oven cabinet.
   */
  get isOven(): boolean {
    return this.product.ioc
  }

  /**
   * Tells if this cabinet has only drawers and more
   * than one drawer. This is used to generate warnings
   * if frame - recess !== 20, but not if it is a sink-out
   */
  get drawersOnly(): boolean {
    return this.product.nuDr > 1 && this.product.nuDo < 1 && this.product.rct.indexOf('sink') === -1
  }

  get actualHeight(): number {
    return this.figureOutActualHeight()
  }

  /**
   * Return cabinet FRONT area in m2
   */
  get area(): number {
    return this.dimensions.x * this.dimensions.y / 1000 / 1000
  }

  /**
   * Return cabinet volume in m3
   */
  get volume(): number {
    return this.dimensions.x * this.dimensions.y * this.dimensions.z / 1000 / 1000 / 1000
  }

  get numberOfShelves(): number {
    return this.product.nuShSt
  }

  get numberOfDoors(): number {
    return this.product.nuDo
  }

  get numberOfDrawers(): number {
    return this.product.nuDr
  }

  get counterTopId(): string {
    return this.settings.counterTop
  }

  set counterTopId(id: string) {
    this.settings.counterTop = id
  }

  // eslint-disable-next-line @typescript-eslint/member-ordering
  get cabinetType(): string {
    return this.product.rct
  }

  set cabinetType(type: string) {
    this.product.rct = type
  }

  private static fixPrice(value: any): number {
    if (isNaN(+value)) {
      return 0
    }
    return +value
  }

  public getScribings(): string {
    let scribings = ''
    this.options
      .filter((option: CabinetOption) => option.optionSelectName === 'Scribings' && option.active)
      .forEach((option: Scribings) => scribings = option.viewOptions[0].selection)
    return scribings
  }

  /**
   * Check if this should have an inside paint note
   */
  public checkIfPaintedInsideNote(): string {
    this.paintedInsideNote = ''
    this.options
      .filter((option: CabinetOption) => option.optionSelectName === 'Door')
      .forEach((door: Door) => this.paintedInsideNote = door.paintedInsideNote)
    return this.paintedInsideNote
  }

  public checkSettingsDiff(): string[] {
    /**
     * A list of translation for the warning if values differ from Prodboard and Mill
     */
    const translation = {
      title: 'beskrivning',
      titleEn: 'beskrivning till snickeri',
      height: 'höjd',
      width: 'bredd',
      depth: 'djup',
      price: 'pris',
      labor: 'snickerikostnad',
      material: 'materialkostnad',
      productCommentEn: 'produktkommentar på engelska',
      productCommentSv: 'produktkommentar på svenska',
      drawers: 'lådor',
      visibleWidth: 'synlig bredd',
      hiddenWidth: 'dold bredd'
    }
    // Some options are not relevant for some cabinets
    // If not a base corner cabinet, remove the hidden/visible
    if (!this.isBaseCornerCabinet) {
      delete translation.hiddenWidth
      delete translation.visibleWidth
    }

    // If no drawers we should not warn for that.
    if (this.numberOfDrawers === 0) {
      delete translation.drawers
    }
    const diffs = []
    Object.keys(translation).forEach((key: string) => {
      const setting = this.getSettingsValue(this.settings[key])
      const base = this.getSettingsValue(this.baseSettings[key])
      if (setting && setting !== base) {
        diffs.push(`${translation[key]} (${setting} <> ${base})`)
      }
    })
    return diffs
  }

  public getSettingsValue(value: any): string {
    if (!value) {
      return value
    }
    if (Array.isArray(value)) {
      return `[${value.join(',')}]`
    }
    return value + ''
  }

  /**
   * Generic update instead of publishing price
   */
  public update(cabinetOptions?: { [key: string]: CabinetOption | Comment[] }): void {
    if (cabinetOptions) {
      this.comments = cabinetOptions.comments as Comment[] || []
      Object.keys(cabinetOptions).forEach(key => {
        const option = this.options.find((opt: CabinetOption) => opt.name === key)
        if (option) {
          option.update(cabinetOptions[key])
          if (cabinetOptions[key].hasOwnProperty('active')) {
            option.active = (cabinetOptions[key] as CabinetOption).active
          }
        }
      })
    }
    this.setRecess() // Currently special case where we set recess baaed on Skirting. More of this?
    this.calculateCabinetPrice()
  }

  public getSettings(): CabinetSettings {
    return this.settings
  }

  /**
   * Override settings that take precedence over prodboard values (except index)
   *
   * @param settings
   */
  public setSettings(settings: CabinetSettings = new CabinetSettings()): void {
    Object.assign(this.settings, settings)
    this.dimensions.x = settings.width || this.dimensions.x
    /**
     * Woot! Note that we try to override the "actual height", that
     * So if we have a height here the dimensions has to be
     * set as the difference between _current_ actual and y
     */
    if (settings.height) {
      this.dimensions.y = settings.height + (this.dimensions.y - this.pActualHeight)
    }
    this.dimensions.z = settings.depth || this.dimensions.z
    this.description = settings.titleEn || this.description
    this.basePrice = settings.price || this.basePrice
    this.baseLabor = settings.labor || this.baseLabor
    this.baseMaterial = settings.material || this.baseMaterial
    this.hWidth = settings.hiddenWidth || this.hiddenWidth
    this.vWidth = settings.visibleWidth || this.visibleWidth

    this.deSwLaSw = settings.title || this.deSwLaSw
    this.productComments.en = settings.productCommentEn || this.product.co
    this.productComments.sv = settings.productCommentSv || this.product.coSe

    /**
     *
     */
    this.drawers = settings.drawers || this.drawers
    if (this.hiddenVisibleWidth) {
      this.hiddenVisibleWidth.update({
        hiddenWidth: this.hiddenWidth,
        visibleWidth: this.visibleWidth
      })
      this.dimensions.x = this.hiddenWidth + this.visibleWidth
    }
    this.calculateCabinetPrice()
  }

  public resetSettings(): void {
    this.settings = new CabinetSettings()
    this.setSettings(this.baseSettings)
  }

  private calculateCabinetPrice(): void {
    this.receipt.length = 0
    this.price = this.basePrice
    this.labor = this.baseLabor
    this.material = this.baseMaterial
    const marker = this.checkPriceChange() ? '*' : ''
    const item = new ReceiptItem(`Skåp ${this.index}, ${this.cat}${marker}`, this.index)
    item.price = this.basePrice
    item.labor = this.baseLabor
    item.material = this.baseMaterial
    item.source = 'cabinet'
    this.receipt.push(item)

    const actualHeight = this.figureOutActualHeight()

    if (actualHeight > this.product.hiSt) {
      this.higherThanStandard = true
      this.price += ProdboardCabinet.fixPrice(this.product.exPrHiSt.price)
      this.labor += ProdboardCabinet.fixPrice(this.product.exPrHiSt.labor)
      const highItem = new ReceiptItem(`Högre än standard ${actualHeight} > ${this.product.hiSt}`, this.index)
      highItem.price = ProdboardCabinet.fixPrice(this.product.exPrHiSt.price)
      highItem.labor = ProdboardCabinet.fixPrice(this.product.exPrHiSt.labor)
      highItem.source = 'dimension'
      this.receipt.push(highItem)
    }

    if (this.dimensions.z > this.product.deSt) {
      this.deeperThanStandard = true
      this.price += ProdboardCabinet.fixPrice(this.product.exPrDeSt.price)
      this.labor += ProdboardCabinet.fixPrice(this.product.exPrDeSt.labor)
      const deepItem = new ReceiptItem(`Djupare än standard ${this.dimensions.z} > ${this.product.deSt}`, this.index)
      deepItem.price = ProdboardCabinet.fixPrice(this.product.exPrDeSt.price)
      deepItem.labor = ProdboardCabinet.fixPrice(this.product.exPrDeSt.labor)
      deepItem.source = 'dimension'
      this.receipt.push(deepItem)
    }

    this.options
      .filter(o => o.active)
      .forEach((option: CabinetOption) => {
        this.price += ProdboardCabinet.fixPrice(option.price)
        this.labor += ProdboardCabinet.fixPrice(option.labor)
        this.material += ProdboardCabinet.fixPrice(option.material)
        // Note that we do not set receipt items for options
      })

    this.comments.forEach((c: Comment) => {
      this.price += ProdboardCabinet.fixPrice(c.price)
      this.labor += ProdboardCabinet.fixPrice(c.labor)
      this.material += ProdboardCabinet.fixPrice(c.labor)
      const commentItem = new ReceiptItem(`Kommentar: ${c.comment}`, this.index)
      commentItem.price = ProdboardCabinet.fixPrice(c.price)
      commentItem.labor = ProdboardCabinet.fixPrice(c.labor)
      commentItem.material = ProdboardCabinet.fixPrice(c.material)
      commentItem.source = 'comment'
      this.receipt.push(commentItem)
    })


  }

  private analyzeItems(items: ProdboardSelectionItem[] = []): void {
    let ite: CabinetOption
    const hiddenVisibleWidth: ProdboardSelectionItem[] = []
    items.forEach((item: ProdboardSelectionItem) => {
      switch (item.code) {
        case 'frames/json':
          ite = new FrameWidth(item as any, this.product, this, new FrameWidthHelper())
          this.frame = ite as FrameWidth
          this.options.push(ite)
          break
        case 'fillers/json':
          ite = new Filler(item as any, this.product, this.index)
          this.fillers = ite as Filler
          this.options.push(ite)
          break
        case 'legs/json':
          ite = new Legs(item as any, this.product, this.index)
          this.legs = ite as Legs
          this.options.push(ite)
          break
        case 'hidden width':
        case 'visible width':
          hiddenVisibleWidth.push(item)
          break
        case 'handle_door':
          ite = new HandleDoor(item, this.product, this.index)
          this.options.push(ite)
          break
        default:
        // Do nothing on purpose
      }
    })

    if (hiddenVisibleWidth.length > 0) {
      this.hiddenVisibleWidth = new HiddenVisibleWidth({} as any, this.product, this.index, hiddenVisibleWidth)
      this.options.push(this.hiddenVisibleWidth)
      this.dimensions.x = this.hiddenVisibleWidth.hiddenWidth + this.hiddenVisibleWidth.visibleWidth
      this.dimensions.z = this.dimensions.z - 50
    }
    if (hiddenVisibleWidth.length === 0) {
      this.correctDepth()
    }
  }

  /**
   * All prodboard options get have a corresponding class to handle
   * them individually.
   *
   * @param options - Options as found on the prodboard file
   * @private
   */
  private analyzeOptions(options: ProdboardCabinetOption[] = []) {
    // The same option can occur several times. We need to number them.
    const counters = {drawerInsert: 0, coverSide: 0} as any
    options.forEach((o: ProdboardCabinetOption) => (counters[o.name] = 0))
    const drawerInsertsList: ProdboardCabinetOption[] = []
    const hiddenDrawersList: ProdboardCabinetOption[] = []
    options.forEach((option: ProdboardCabinetOption) => {
      let opt: CabinetOption
      option.count = counters[option.name]++
      switch (option.name.toLowerCase()) {
        case 'anpassat för fläkt':
          opt = new FanAdoption(option, this.product, this.index)
          break
        case 'brass tag':
          opt = new BrassPlate(option, this.product, this.index)
          break
        case 'combined unit':
          opt = new CombinedUnit(option, this.product, this.index)
          break
        case 'doors':
          opt = new DoorType(option, this.product, this.index)
          break
        case 'gångjärn':
          opt = new Hinges(option, this.product, this.index)
          break
        case 'handtag, låda':
          opt = new HandleDrawer(option, this.product, this.index)
          break
        case 'hyllor':
          opt = new Shelves(option, this.product, this.index)
          break
        case 'hyllor på luckans insida':
          opt = new SpiceRack(option, this.product, this.index)
          break
        case 'hängning':
          opt = new Hanging(option, this.product, this.index)
          break
        case 'inbyggd belysning':
          opt = new Lighting(option, this.product, this.index)
          break
        case 'krönlist':
          opt = new Cornice(option, this.product, this.index)
          break
        case 'mittstolpe':
          opt = new CenterPost(option, this.product, this.index)
          break
        case 'målning':
          const pp = new PaintProcess(option, this.product, this.index)
          opt = pp
          /**
           * We set the paint process 'index', any cabinet that has this > 0
           * will indicate the paint process. Last cabinet will win. We should
           * in the future generate a warning if different or something.
           */
          this.paintProcess = pp.paintProcess()
          break
        case 'släta lådfronter':
          opt = new DrawerFront(option, this.product, this.index)
          break
        case 'snickarglädje':
          opt = new CarpenterJoy(option, this.product, this.index)
          break
        case 'sockel':
          opt = new Skirting(option, this.product, this.index)
          this.skirting = opt as Skirting
          break
        case 'ställbara hyllplan':
          opt = new ShelvesAdjustable(option, this.product, this.index)
          break
        case 'typ av lucka':
          opt = new Door(option, this.product, this.index)
          break
        case 'luckinfästning':
          opt = new DoorAttachment(option, this.product, this.index)
          break
        case 'ramens bredd (admin only)':
          opt = new Scribings(option, this.product, this.index)
          break
        case 'täcksida, höger':
          option.count = counters.coverSide++
          opt = new CoverSide(option, this.product, this.index, 'right')
          break
        case 'täcksida, vänster':
          option.count = counters.coverSide++
          opt = new CoverSide(option, this.product, this.index, 'left')
          break
        case 'täcksidor':
        case 'täcksida, baksida':
          opt = new BackPanel(option, this.product, this.index)
          break
        case 'utdragslådor':
          // BD1o and BD1o+PC has the hidden drawer set but is not relevant
          if (this.product.ioc === true) {
            break
          }
          opt = new HiddenDrawerSink(option, this.product, this.index)
          break
        case 'utdragbar skärbräda':
          opt = new CuttingBoard(option, this.product, this.index)
          break
        case 'drawer/door':
          opt = new DrawerDoor(option, this.product, this.index)
          break
        case 'paint side because of dw':
          opt = new PaintSide(option, this.product, this.index)
          break
        case 'template':
          opt = new UnknownOption(option, this.product, this.index, 'template')
          break
        default:
          if (option.name.match(/LÅDINSATS/i) !== null) {
            drawerInsertsList.push(option)
          }

          this.setHiddenDrawer(hiddenDrawersList, option)
          opt = new UnknownOption(option, this.product, this.index, option.name)
      }

      if (this.isDishwasherCabinet) {
        this.ignoredTypes.add('Hinges')
        this.ignoredTypes.add('Hanging')
        this.ignoredTypes.add('HandleDoor')
        this.ignoredTypes.add('HandleDrawer')
      }
      if (opt && !this.ignoredTypes.has(opt.optionSelectName)) {
        this.options.push(opt)
      }
    })

    if (drawerInsertsList.length > 0) {
      this.options.push(new DrawerInsert({} as any, this.product, this.index, drawerInsertsList))
    }

    if (hiddenDrawersList.length > 0) {
      this.options.push(new HiddenDrawer({count: 0} as any, this.product, this.index, hiddenDrawersList))
    }

    /**
     * Update all options with cabinet uid and
     * proper labels
     */
    this.options.forEach((option: CabinetOption) => {
      this.overrideService.setOption(option)
      option.cabinetId = this.uid
    })

    // Adjust depth for dishwashers, i.e. they are actually on 25 mm deep,
    // Also height is fixed to 777
    if (this.isDishwasherCabinet) {
      this.dimensions.z = 25
      this.dimensions.y = 777
    }
    this.updateOptions()
  }

  /**
   * Do some updates based on our options. This is called
   * also when we get updates from outside, not just when rendering
   */
  private updateOptions(): void {
    /**
     * If this cabinet has the shDe we add this
     * as an additional option.
     */
    if (this.product.shDe) {
      this.options.push(new Plinth({value: {name: 'Plinth'}} as any, this.product, this.index))
    }

    /**
     * Adjust frame and fillers based on skirting.
     */
    this.setRecess()

    /**
     * If no door, then no hanging
     */
    this.removeHanging()

    /**
     * Remove Hinges, HandleDoor and HandleDrawer if DoorAttachment is equal to "Door on door".
     * This is because that means it's a refrigerator or freezer and shouldn't have these options
     */
    this.removeIfDoorOnDoor()
  }

  private setHiddenDrawer(hiddenDrawersList: ProdboardCabinetOption[], option: ProdboardCabinetOption): void {
    if (option.name === 'Översta delen'
      || option.name === 'Andra delen'
      || option.name === 'Tredje delen'
      || option.name === 'Fjärde delen'
      || option.name === 'Femte delen'
      || option.name === 'Sjätte delen'
      || option.name === 'Sjunde delen'
      || option.name === 'Åttonde delen') {
      hiddenDrawersList.push(option)
    }
  }

  /**
   * Removes the hanging option by setting it i
   *
   * @private
   */
  private removeHanging(): void {
    const door = this.options.find((option: CabinetOption) => option.optionSelectName === 'Door')
    if (door && door.viewOptions[0].selection === NO_DOOR_SELECTED) {
      const hanging = this.options.find((option: CabinetOption) => option.optionSelectName === 'Hanging')
      if (hanging) {
        hanging.active = false
      }
    }
  }

  private removeIfDoorOnDoor(): void {
    this.options
      // Only doorAttachment can have "Door on Door"
      .filter((option: CabinetOption) => option.viewOptions[0] && option.viewOptions[0].selection === DOOR_ON_DOOR)
      .forEach(() => {
        // There is normally just one so ...
        [this.options.find((option: CabinetOption) => option.optionSelectName === 'Hinges'),
          this.options.find((option: CabinetOption) => option.optionSelectName === 'HandleDoor'),
          this.options.find((option: CabinetOption) => option.optionSelectName === 'HandleDrawer')]
          .filter(o => o)
          .forEach((option: CabinetOption) => option.active = false)
      })
  }

  /**
   * A very special case where recess (fillers) and frame needs
   * updating based on skirting.
   */
  private setRecess(): void {
    // We should only update the values if the skirting has _changed_ ?
    if (this.currentSkirting !== this.skirting.viewOptions[0].selection) {
      this.currentSkirting = this.skirting.viewOptions[0].selection
      this.fillers.setTopAndBottom(this.skirting, this.frame)
      this.frame.updateBasedOnSkirting(this.skirting)
    }
  }

  /**
   * The height reduction can come from three places, in order of
   * priority
   *
   * a) From a specific Plinth option. It is used if not 0
   * b) From the project setting, it is used if set to anything but 0
   * c) From the product property shDe
   *
   * In effect the only way to override is to set a NON-negative
   * value in either the option or on the project
   */
  private figureOutActualHeight(): number {
    const heightOption = this.options.find((option: CabinetOption) => option.optionSelectName === 'Plinth')
    if (heightOption && heightOption.active) {
      this.pActualHeight = this.dimensions.y - Number(heightOption.createOptionsSummary())
      return this.pActualHeight
    }

    if (this.project.form.socelHeight === 0) {
      this.pActualHeight = this.dimensions.y
      return this.pActualHeight
    }

    this.pActualHeight = this.dimensions.y - (this.product.shDe || 0)
    return this.pActualHeight
  }

  /**
   * Corner cabinets BCP, BC, BCD2, should have a depth correction
   * of 50 mm.
   */
  private correctDepth(): void {
    if (this.product.ibcc === true) {
      this.dimensions.z = this.dimensions.z - 50
    }
  }

  private checkPriceChange(): boolean {
    const cabinet =
      Math.ceil(ProdboardCabinet.fixPrice(this.product.pr.price) +
        ProdboardCabinet.fixPrice(this.product.pr.labor) +
        ProdboardCabinet.fixPrice(this.product.pr.material))
    const settings = Math.ceil(this.basePrice + this.baseLabor + this.baseMaterial)
    return cabinet - settings !== 0
  }

}

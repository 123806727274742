<div class="holder">
  <div class="line header">
    <div class="text">Text</div>
    <div class="filler"></div>
    <div class="number">Pris</div>
    <div class="number">%</div>
    <div class="number">Rab.</div>
    <div class="number">Kund</div>
  </div>
  <div *ngFor="let item of items$ | async">
    <div class="line">
      <div class="text">{{item.text}}</div>
      <div class="filler"></div>
      <div class="number">{{item.price | number : '1.0-0': 'fr'}}</div>
      <div class="number">{{item.discountPercent | number : '1.0-0': 'fr'}}%</div>
      <div class="number">{{item.discountAmount| number : '1.0-0': 'fr'}}</div>
      <div class="number">{{item.customerPrice| number : '1.0-0': 'fr'}}</div>
    </div>
    <div *ngFor="let comment of item.comments" class="line comment">
      <div class="text">{{comment.text}}</div>
      <div class="filler"></div>
      <div class="number">{{comment.price | number : '1.0-0': 'fr'}}</div>
    </div>
  </div>

  <div class="line footer">
    <div class="text">Total:</div>
    <div class="filler"></div>
    <div class="number">{{rTotal | number: '1.0-0': 'fr'}}</div>
    <div class="number"></div>
    <div class="number">{{rDiscount | number: '1.0-0': 'fr'}}</div>
    <div class="number">{{rCustomer | number: '1.0-0': 'fr'}}</div>
  </div>
</div>

import {Component, OnInit, OnDestroy} from '@angular/core'
import {WarningService} from '../service/warning.service'
import {Warning} from '../model/warning'
import {filter} from 'rxjs/operators'
import {Subscription} from 'rxjs'

@Component({
  selector: 'kdl-warning',
  templateUrl: './warning.component.html',
  styleUrls: ['./warning.component.scss']
})
export class WarningComponent implements OnInit, OnDestroy {

  public warningsList: Warning[] = []

  private sub$ = new Subscription()

  constructor(
    public warningService: WarningService
  ) {
  }

  public ngOnInit(): void {
    this.sub$ = this.warningService.warnings$.pipe(
      filter(Boolean)
    ).subscribe({
      next: (warnings: Warning[]) => {
        this.warningsList.length = 0
        warnings
          .filter(w => !w.factoryOnly)
          .forEach((warning: Warning) => {
            this.warningsList.push(warning)
          })
      }
    })
  }

  public ngOnDestroy() {
    this.sub$.unsubscribe()
  }

  /**
   * So that the list does not flicker on save
   */
  public tracker(index: any, warning: Warning): string {
    return warning.name
  }
}

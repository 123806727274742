import {CabinetOption, ViewOptionType} from './cabinet-option'
import {IProduct} from '../common/interface/product-types'
import {ProdboardCabinetOption, ProdboardSelectionItem} from '../services/prodboard-types'

export class HiddenVisibleWidth extends CabinetOption {

  public readonly optionSelectName = 'HiddenVisibleWidth'

  constructor(option: ProdboardCabinetOption, product: IProduct, cabinetId: number, itemList: ProdboardSelectionItem[]) {
    super(option, product, cabinetId)
    this.setNameAndOptions()

    this.priority = 100
    this.viewOptions = [
      {
        type: ViewOptionType.text,
        title: 'Synlig bredd',
        name: 'visibleWidth',
        values: [],
        selection: '',
        disabled: false
      },
      {
        type: ViewOptionType.text,
        title: 'Dold bredd',
        name: 'hiddenWidth',
        values: [],
        selection: '',
        disabled: false
      }
    ]
    this.active = true

    this.description = 'Special case for corner cabinets - one part of the cabinet is "hidden", the other part is "visible"'
    this.title = 'optHiddenVisibleWidth'

    HiddenVisibleWidth.sanitize(itemList)
    this.setValuesFromProdboardData(itemList)
  }

  get visibleWidth(): number {
    return +this.viewOptions[0].selection
  }

  get hiddenWidth(): number {
    return +this.viewOptions[1].selection
  }

  private static sanitize(itemList: ProdboardSelectionItem[]): void {
    itemList.forEach((item: ProdboardSelectionItem) => {
      item.value = item.value || 0 + ''
    })
  }

  public update(data: any): void {
    super.update(data)
    this.setFromProperties(data)
  }

  protected getCustomCustomerListing(): string[] {
    return []
  }

  private setValuesFromProdboardData(itemList: ProdboardSelectionItem[]): void {
    itemList.forEach((item: ProdboardSelectionItem) => {
      if (item.code === 'visible width') {
        this.viewOptions[0].selection = item.value
      }
      if (item.code === 'hidden width') {
        this.viewOptions[1].selection = (+item.value - 50) + ''
      }
    })
  }
}

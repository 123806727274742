import {CustomerStateLabel, ICustomerState, STATE_VERSION} from './customer-types'

export type TCustomerStateMap = { [key in Lowercase<CustomerStateLabel>]: ICustomerState }

export class CustomerStateMap {

  public stateMap: TCustomerStateMap = {
    a: {
      state: {
        version: STATE_VERSION,
        name: 'Initial contact',
        image: './assets/fas1-kontakt.png',
        color: '#D0E1E4',
        position: 0,
        state: 'A'
      },
      completed: false,
      conditions: [
        {
          type: 'UNARY',
          id: 'c',
          label: 'Broshure requested?',
          options: ['Yes', 'No', 'Ignore'],
          completed: true,
          optional: false,
          selection: 'Ignore'
        },
        {
          type: 'UNARY',
          id: 'd',
          label: 'Broshure sent?',
          options: ['Yes', 'No', 'Ignore'],
          completed: true,
          optional: false,
          selection: 'Ignore'
        },
        {
          type: 'UNARY',
          id: 'e',
          label: 'Quote request via Prodboard – did they get respons from us?',
          options: ['Yes', 'No', 'Ignore'],
          completed: true,
          optional: false,
          selection: 'Ignore'
        }
      ],
      descriptionStart: 'Kund kontaktar oss (i nuläget envägskontaktväg Kund->Kulladal',
      descriptionEnd: 'This phase ends when Kulladal and/or the customer starts using the drawing program.  '
    },
    b: {
      state: {
        version: STATE_VERSION,
        name: 'Drafting',
        // image: 'pause-circle-outline',
        image: './assets/fas2-skisser.png',
        color: '#E6B8AF',
        position: 1,
        state: 'B'
      },
      completed: false,
      conditions: [
        {
          type: 'BINARY',
          id: 'b-01',
          label: 'Prodboard: Control frame widths',
          completed: false
        },
        {
          type: 'BINARY',
          id: 'b-02',
          label: 'Prodboard: Make 10 mm gaps between cabinets and the ceiling',
          completed: false
        },
        {
          type: 'BINARY',
          id: 'b-03',
          label: 'Prodboard: Add legs if needed at corners/ends and stoves',
          completed: false,
          stateId: 'SKIRTING'
        },
        {
          type: 'BINARY',
          id: 'b-10',
          label: 'Prodboard: Add info about appliance models (as comments on the cabients)',
          completed: false
        },
        {
          type: 'BINARY',
          id: 'b-11',
          label: 'Prodboard: Set "Sliding" or "Door-on-door" on appliances',
          completed: false,
          stateId: 'DOOR_ATTACHMENTS'
        },
        {
          type: 'BINARY',
          id: 'b-12',
          label: 'Prodboard: Set turning knobs, if there should be any',
          completed: false,
          stateId: 'HANDLES'
        },
        {
          type: 'BINARY',
          id: 'b-16',
          label: 'Are cutlery dividers decided?',
          completed: false
        }
      ],
      descriptionStart: 'Kulladal och/eller kunden skissar i ritverktyget',
      descriptionEnd: 'This phase ends when the design and details are set and the customer is ready for the final quotation'
    },
    c: {
      state: {
        version: STATE_VERSION,
        name: 'Final quote',
        image: './assets/fas3-slutspec.png',
        color: '#F9CB9C',
        position: 2,
        state: 'C'
      },
      completed: false,
      conditions: [
        {
          type: 'UNARY',
          id: 'c-01',
          label: 'Fittings to order? Add in "Settings / Appliances"',
          completed: false,
          options: ['Yes', 'No'],
          selection: null
        },
        {
          type: 'UNARY',
          id: 'c-02',
          label: 'Oven to order? Add in "Settings / Appliances"',
          completed: false,
          options: ['Yes', 'No'],
          selection: null
        },
        {
          type: 'UNARY',
          id: 'c-03',
          label: 'Hob to order? Add in "Settings / Appliances"',
          completed: false,
          options: ['Yes', 'No'],
          selection: null
        },
        {
          type: 'UNARY',
          id: 'c-04',
          label: 'Diswasher to order? Add in "Settings / Appliances"',
          completed: false,
          options: ['Yes', 'No'],
          selection: null
        },
        {
          type: 'UNARY',
          id: 'c-05',
          label: 'Microwaveoven to order? Add in "Settings / Appliances"',
          completed: false,
          options: ['Yes', 'No'],
          selection: null
        },
        {
          type: 'UNARY',
          id: 'c-06',
          label: 'Stove to order? Add in "Settings / Appliances"',
          completed: false,
          options: ['Yes', 'No'],
          selection: null
        },
        {
          type: 'UNARY',
          id: 'c-07',
          label: 'Extractor to order? Add in "Settings / Appliances"',
          completed: false,
          options: ['Yes', 'No'],
          selection: null
        },
        {
          type: 'UNARY',
          id: 'c-08',
          label: 'Fridge to order? Add in "Settings / Appliances"',
          completed: false,
          options: ['Yes', 'No'],
          selection: null
        },
        {
          type: 'UNARY',
          id: 'c-09',
          label: 'Freezer to order? Add in "Settings / Appliances"',
          completed: false,
          options: ['Yes', 'No'],
          selection: null
        },
        {
          type: 'UNARY',
          id: 'c-10',
          label: 'Sink/faucet to order? Add in "Settings / Appliances"',
          completed: false,
          options: ['Yes', 'No'],
          selection: null
        },
        {
          type: 'UNARY',
          id: 'c-11',
          label: 'Countertop, stainless steel, to order? Add in "Settings / Countertops"',
          completed: false,
          options: ['Yes', 'No'],
          selection: null
        },
        {
          type: 'UNARY',
          id: 'c-12',
          label: 'Countertop, stone, to order? Add in "Settings / Countertops"',
          completed: false,
          options: ['Yes', 'No'],
          selection: null
        },
        {
          type: 'UNARY',
          id: 'c-13',
          label: 'Countertop, wood, to order? Add in "Settings / Countertops"',
          completed: false,
          options: ['Yes', 'No'],
          selection: null
        },
        {
          type: 'UNARY',
          id: 'c-14',
          label: 'Anything else to order? ',
          completed: false,
          options: ['Yes', 'No'],
          selection: null
        },
        {
          type: 'UNARY',
          id: 'c-15',
          label: 'Lights: Info about drivers etc in each cabinet?',
          completed: false,
          options: ['Yes', 'No'],
          selection: null
        },
        {
          type: 'BINARY',
          id: 'c-16',
          label: 'Address / contact info to the customer',
          completed: false
        },
        {
          type: 'BINARY',
          id: 'c-17',
          label: 'The spec has been sent to the customer',
          completed: false
        },
        {
          type: 'BINARY',
          id: 'c-18',
          label: 'The customer has approved the spec',
          completed: false
        }
      ],
      descriptionStart: '... kunden har bestämt sig för att gå vidare - det är dags för slutspec',
      descriptionEnd: 'This phase ends when the customer accepts the final quotation'
    },
    d: {
      state: {
        version: STATE_VERSION,
        name: 'Order',
        image: './assets/fas4-order.png',
        color: '#FCE5CD',
        position: 3,
        state: 'D'
      },
      completed: false,
      conditions: [
        {
          type: 'BINARY',
          id: 'd-aa',
          label: 'Export the ordersheet pdf',
          completed: false
        },
        {
          type: 'BINARY',
          id: 'd-ab',
          label: 'Add a number to the project name',
          completed: false
        },
        {
          type: 'BINARY',
          id: 'd-ac',
          label: 'Slack: Create channel, share with the carpentries and CAD workers',
          completed: false
        },
        {
          type: 'BINARY',
          id: 'd-ag',
          label: 'Drive: Create folders',
          completed: false
        },
        {
          type: 'BINARY',
          id: 'd-ah',
          label: 'Insert in Kulladal\'s delivery schedule',
          completed: false
        },
        {
          type: 'BINARY',
          id: 'd-ai',
          label: 'Insert in the carpentries delivery schedule',
          completed: false
        },
        {
          type: 'BINARY',
          id: 'd-aj',
          label: 'Insert in the Agacco economy-sheet',
          completed: false
        },
        {
          type: 'BINARY',
          id: 'd-ak',
          label: 'Insert the numbers into "Nya ekonomin"',
          completed: false
        },
        {
          type: 'BINARY',
          id: 'd-al',
          label: 'Wint: Send invoice 1 of 3 to the customer',
          completed: false
        },
        {
          type: 'BINARY',
          id: 'd-am',
          label: 'Ordered from Decosteel?',
          completed: false
        },
        {
          type: 'BINARY',
          id: 'd-ao',
          label: 'Ordered from Smeg?',
          completed: false
        },
        {
          type: 'BINARY',
          id: 'd-ap',
          label: 'Ordered from Siemens?',
          completed: false
        },
        {
          type: 'BINARY',
          id: 'd-aq',
          label: 'Ordered from Steny?',
          completed: false
        },
        {
          type: 'BINARY',
          id: 'd-ar',
          label: 'Ordered from Fjäråskuåpan?',
          completed: false
        },
        {
          type: 'BINARY',
          id: 'd-as',
          label: 'Ordered from Sekelskifte?',
          completed: false
        },
        {
          type: 'BINARY',
          id: 'd-at',
          label: 'Ordered from Byggfabriken?',
          completed: false
        },
        {
          type: 'BINARY',
          id: 'd-au',
          label: 'Ordered from Badex?',
          completed: false
        },
        {
          type: 'BINARY',
          id: 'd-av',
          label: 'Ordered from BeslagDesign?',
          completed: false
        },
        {
          type: 'BINARY',
          id: 'd-aw',
          label: 'Ordered wooden countertops (Bara Bänkskivor)?',
          completed: false
        },
        {
          type: 'BINARY',
          id: 'd-ax',
          label: 'Ordered wooden countertops from other carpentry?',
          completed: false
        },
        {
          type: 'BINARY',
          id: 'd-ay',
          label: 'Anything else to order?',
          completed: false
        },
        {
          type: 'BINARY',
          id: 'd-az',
          label: 'Order special paint',
          completed: false,
          notApplicable: true
        }
      ],
      descriptionStart: '... när kunden godkänt slutspecen',
      descriptionEnd: 'This phase ends after processing the order and preparing for CAD-drawings'
    },
    e: {
      state: {
        version: STATE_VERSION,
        name: 'CAD drawings',
        image: './assets/fas5-produktion.png',
        color: '#FCE5CD',
        position: 4,
        state: 'E'
      },
      completed: false,
      conditions: [
        {
          type: 'BINARY',
          id: 'e-a',
          label: 'CAD-drawings: First version is finished',
          completed: false
        },
        {
          type: 'BINARY',
          id: 'e-b',
          label: 'CAD-drawings: Sent to the proofreader',
          completed: false
        },
        {
          type: 'BINARY',
          id: 'e-c',
          label: 'CAD-drawings: Proofreading finished',
          completed: false
        },
        {
          type: 'BINARY',
          id: 'e-d',
          label: 'CAD-drawings: Updated after proofreading',
          completed: false
        },
        {
          type: 'BINARY',
          id: 'e-e',
          label: 'CAD-drawings: Sent to customer',
          completed: false
        },
        {
          type: 'BINARY',
          id: 'e-f',
          label: 'CAD-drawings: Checked by the customer',
          completed: false
        },
        {
          type: 'BINARY',
          id: 'e-g',
          label: 'CAD-drawings: Updated after customer\'s input',
          completed: false
        },
        {
          type: 'BINARY',
          id: 'e-h',
          label: 'CAD-drawings: Proofread by Kulladal',
          completed: false
        },
        {
          type: 'BINARY',
          id: 'e-i',
          label: 'Sent "CONFIRMED" message to the carpentry, including ordersheet and CAD drawings',
          completed: false
        }
      ],
      descriptionStart: '... när det är dags att göra CAD-ritningar',
      descriptionEnd: 'This phase ends when the CAD-drawings have been accepted by both the customer and the carpentry'
    },
    // eslint-disable-next-line @typescript-eslint/naming-convention
    f: {
      state: {
        version: STATE_VERSION,
        name: 'In production',
        image: './assets/fas6-CAD.png',
        color: '#FFF2CC',
        position: 5,
        state: 'F'
      },
      completed: false,
      conditions: [
        {
          type: 'BINARY',
          id: 'e1',
          label: 'Info to the customer about Steny/Nerostein measuring/delivery',
          completed: false
        },
        {
          type: 'BINARY',
          id: 'e2',
          label: 'Lights: Equipment packed in the Kulladal storage',
          completed: false
        },
        {
          type: 'BINARY',
          id: 'e3',
          label: 'Info to the customer: Date for delivery',
          completed: false
        },
        {
          type: 'BINARY',
          id: 'e4',
          label: 'Delivery info email to the customer',
          completed: false
        },
        {
          type: 'BINARY',
          id: 'e5',
          label: 'Date of pickup confirmed by the carpentry',
          completed: false
        },
        {
          type: 'BINARY',
          id: 'e6',
          label: 'Date of pickup confirmed by the transporter',
          completed: false
        },
        {
          type: 'BINARY',
          id: 'e7',
          label: 'Painting info email to the customer',
          completed: false
        },
        {
          type: 'BINARY',
          id: 'e8',
          label: 'Fitting instrucktion PDF to the customer',
          completed: false
        },
        {
          type: 'BINARY',
          id: 'e9',
          label: 'Send invoice 2 of 3 to the customer',
          completed: false
        }
      ],
      descriptionStart: '... alla ritningar är klara, snickeriet sätter igång produktionen',
      descriptionEnd: 'This phase ends when the kitchen has been delivered to the customer'
    },
    g: {
      state: {
        version: STATE_VERSION,
        name: 'Delivery',
        //image: 'globe-2-outline',
        image: './assets/fas7-leverans.png',
        color: '#D9EAD3',
        position: 6,
        state: 'G'
      },
      completed: false,
      conditions: [
        {
          type: 'BINARY',
          id: 'f-1',
          label: 'Send invoice 3 of 3 to the customer',
          completed: false
        },
        {
          type: 'BINARY',
          id: 'f-2',
          label: 'Send cups',
          completed: false
        }
      ],
      descriptionStart: '... köket är levererat till kund',
      descriptionEnd: 'This phase ends after the kitchen, appliances and fixtures has been deliver, ' +
        'the kitchen is painted, the customer is happy and the invoices paid. At this point we close the project.'
    },
    h: {
      state: {
        version: STATE_VERSION,
        name: 'Customer archive',
        //image: 'play-circle-outline',
        image: './assets/archive-outline-kundarkiv.png',
        color: '#D0E0E3',
        position: 7,
        state: 'H'
      },
      completed: false,
      conditions: [
        {
          /**
           * For some reason I do not remember the archive need a condition
           */
          type: 'BINARY',
          id: '1',
          label: '',
          completed: false
        }
      ],
      descriptionStart: '',
      descriptionEnd: 'After everything is delivered, mounted and paid  the customer file is moved to the archive'
    },
    i: {
      state: {
        version: STATE_VERSION,
        name: 'Drafts archive',
        //image: 'shopping-cart-outline',
        image: './assets/archive-outline-skissarkivet.png',
        color: '#CADAF8',
        position: 8,
        state: 'I'
      },
      completed: false,
      conditions: [
        {
          /**
           * For some reason I do not remember the archive need a condition
           */
          type: 'BINARY',
          id: '1',
          label: '',
          completed: false,
          expired: false,
          comments: []
        }
      ],
      descriptionStart: '',
      descriptionEnd: 'Achived projects (those from phase 1-2 who don\'t chose to continue)'
    }
  }
}

import {IProduct} from '../common/interface/product-types'
import {CabinetOption, ViewOption, ViewOptionType} from './cabinet-option'
import {CARDINALITY} from '../common/interface/helpers'
import {ProdboardCabinetOption} from '../services/prodboard-types'

export class DrawerInsert extends CabinetOption {

  /**
   * Must be statically set _before_ constructor
   */
  public readonly optionSelectName = 'DrawerInsert'

  constructor(option: ProdboardCabinetOption, product: IProduct, cabinetId: number, optionsList: ProdboardCabinetOption[]) {
    super(option, product, cabinetId)
    this.setNameAndOptions()

    this.priority = 10
    this.viewOptions = [
      {
        type: ViewOptionType.select,
        title: 'Bestickinsats',
        name: 'cutleryDiv',
        values: ['0', '1', '2', '3', '4'],
        selection: '0',
        disabled: false,
      },
      {
        type: ViewOptionType.select,
        title: 'Knivblock',
        name: 'knifeBlock',
        values: ['0', '1', '2', '3', '4'],
        selection: '0',
        disabled: false,
      },
      {
        type: ViewOptionType.select,
        title: 'Kryddinsats',
        name: 'wavy',
        values: ['0', '1', '2', '3', '4'],
        selection: '0',
        disabled: false
      }
    ]
    this.description = 'Dividers for drawers, knife blocks etc'
    this.title = 'optDrawerInsert'
    const sOption = DrawerInsert.sanitize(optionsList)
    this.setValuesFromProdboardData(sOption)
    this.setPrice()
  }

  private static sanitize(options: ProdboardCabinetOption[]): { [key: string]: number } {
    const drawerCount = {
      // eslint-disable-next-line @typescript-eslint/naming-convention
      div_wall: 0,
      knife: 0,
      wavy: 0,
    }
    options.forEach((option: ProdboardCabinetOption) => {
      option.value = option.value || {} as any
      // eslint-disable-next-line @typescript-eslint/naming-convention
      option.value.options = option.value.options || {div_wall: {}, knife: {}, wavy: {}} as any
      /**
       * Since the key is not consistent we have to extract the key name to be able to check it's value
       * Daniel will probably have a better solution :-)
       *
       * The keys here are knife, div_wall, wavy
       *
       * each of which can have { ch_1: true} set ?? (Wrong! It can be {ch_1: true} or {ch_2: true}. We don't know
       * the "ch number")
       * If so we need to increment
       * this drawerInsert on the same prop. Let us assume that the property name
       * matches what we have in drawerInsert object.
       *
       * All of this is overly smart and should be simplified!
       *
       */

      const optionsKeys: string[] = Object.keys(option.value.options)
      optionsKeys.forEach((localKey: string) => {
        /**
         * The problem here is we don't know the name of the object. It could be ch1, ch2 and so on.
         * So we have to check if there is an object and then extract the object regardless of the name.
         * We assume that if there is an object it is set to true
         */
        const optKeys = Object.keys(option.value.options[localKey])
        if (optKeys.length > 0) {
          drawerCount[localKey]++
        }
      })
    })
    return drawerCount
  }

  public update(data: any): void {
    super.update(data)
    this.setFromProperties(data)
    this.active =
      +this.viewOptions[0].selection > 0 || +this.viewOptions[1].selection > 0 || +this.viewOptions[2].selection > 0
    this.setPrice()
  }

  /**
   * For CAD, we just want the legs
   */
  public valueMap(): { [p: string]: string | number | boolean } {
    return {
      cutlery: Number.parseInt(this.viewOptions[0].selection, 10),
      knife: Number.parseInt(this.viewOptions[1].selection, 10),
      wavy: Number.parseInt(this.viewOptions[2].selection, 10)
    }
  }

  protected getCustomCustomerListing(lc: string): string[] {
    const answer: string[] = []
    this.viewOptions.forEach((option: ViewOption, i: number) => {
      if (+option.selection > 0) {
        answer.push(this.optionValueNames[i][lc] + ': ' + option.selection + ' ' + CARDINALITY[lc])
      }
    })
    return answer
  }

  private setValuesFromProdboardData(option: { [key: string]: number }): void {

    this.viewOptions[0].selection = option.div_wall + ''
    this.viewOptions[1].selection = option.knife + ''
    this.viewOptions[2].selection = option.wavy + ''

    this.active =
      +this.viewOptions[0].selection > 0 || +this.viewOptions[1].selection > 0 || +this.viewOptions[2].selection > 0
  }

  private setPrice(): void {
    this.resetPrices()
    const prices = {
      cuDiPr: Object.assign({price: 0, labor: 0, material: 0}, this.product.cuDiPr),
      knBlPr: Object.assign({price: 0, labor: 0, material: 0}, this.product.knBlPr),
      waBlPr: Object.assign({price: 0, labor: 0, material: 0}, this.product.waBlPr),
    }

    const numberOfCutleryDividers = +this.viewOptions[0].selection
    const numberOfKnifeBlocks = +this.viewOptions[1].selection
    const numberOfKryddinsats = +this.viewOptions[2].selection

    this.price =
      numberOfCutleryDividers * prices.cuDiPr.price +
      numberOfKnifeBlocks * prices.knBlPr.price +
      numberOfKryddinsats * prices.waBlPr.price

    this.labor =
      numberOfCutleryDividers * prices.cuDiPr.labor +
      numberOfKnifeBlocks * prices.knBlPr.labor +
      numberOfKryddinsats * prices.waBlPr.labor

    this.material =
      numberOfCutleryDividers * prices.cuDiPr.material +
      numberOfKnifeBlocks * prices.knBlPr.material +
      numberOfKryddinsats * prices.waBlPr.material

    if (numberOfCutleryDividers > 0) {
      const item = this.addReceiptItem()
      item.itemText = `Pris för ${numberOfCutleryDividers} bestikinsats(er)`
      item.price = numberOfCutleryDividers * prices.cuDiPr.price
      item.labor = numberOfCutleryDividers * prices.cuDiPr.labor
      item.material = numberOfCutleryDividers * prices.cuDiPr.material
      item.priceProperty = 'cuDiPr'
      this.shouldHavePrice = true
    }

    if (numberOfKnifeBlocks > 0) {
      const item = this.addReceiptItem()
      item.itemText = `Pris för ${numberOfKnifeBlocks} knivblock`
      item.price = numberOfKnifeBlocks * prices.knBlPr.price
      item.labor = numberOfKnifeBlocks * prices.knBlPr.labor
      item.material = numberOfKnifeBlocks * prices.knBlPr.material
      item.priceProperty = 'knBlPr'
      this.shouldHavePrice = true
    }

    if (numberOfKryddinsats > 0) {
      const item = this.addReceiptItem()
      item.itemText = `Pris för ${numberOfKryddinsats} kryddinsats(er)`
      item.price = numberOfKryddinsats * prices.waBlPr.price
      item.labor = numberOfKryddinsats * prices.waBlPr.labor
      item.material = numberOfKryddinsats * prices.waBlPr.material
      item.priceProperty = 'waBlPr'
      this.shouldHavePrice = true
    }
    this.addPriceFromComments()
  }
}

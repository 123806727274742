<kdl-floating-menu></kdl-floating-menu>
<mat-toolbar class="header shadow">
  <div class="logo">
    <div [routerLink]="[adminHomePath]" class="logo-holder">
      <img alt="logo" class="logo-image" src="../../../assets/logo2.png">
    </div>
  </div>
  <div class="filler"></div>
  <kdl-shortcuts *ngIf="userName || userEmail"></kdl-shortcuts>
  <div class="filler"></div>
  <kdl-problem-list></kdl-problem-list>

  <div *ngIf="userName || userEmail" class="menu">
    <div>{{userName || userEmail}}</div>
    <button [matMenuTriggerFor]="menu" mat-button>
      <mat-icon class="material-icons-outlined">menu</mat-icon>
    </button>
    <mat-menu #menu="matMenu">
      <button [routerLink]="[adminHomePath]" mat-menu-item>
        <span kdlI18n label="homeMnu"></span>
      </button>

      <button mat-menu-item [matMenuTriggerFor]="customer">
        <span kdlI18n label="customersMnu"></span>
      </button>

      <button [routerLink]="[productPath]" mat-menu-item>
        <span kdlI18n label="productsMnu"></span>
      </button>
      <button [routerLink]="[userPath]" mat-menu-item>
        <span kdlI18n label="usersMnu"></span>
      </button>
      <button [routerLink]="[settingsHomePath]" mat-menu-item>
        <span kdlI18n label="settingsMnu"></span>
      </button>
      <button [routerLink]="['login', 'logout']" mat-menu-item>
        <span kdlI18n label="logoutMnu"></span>
      </button>
    </mat-menu>
    <mat-menu #customer>
      <button [routerLink]="[customerHomePath]" mat-menu-item>
        <span kdlI18n label="customersMnu"></span>
      </button>
      <button [routerLink]="[customerHomePath, 'list']" mat-menu-item>
        <span kdlI18n label="listMnu"></span>
      </button>
    </mat-menu>

  </div>
</mat-toolbar>


import {IProduct} from '../common/interface/product-types'
import {CabinetOption, ViewOptionType} from './cabinet-option'
import {ProdboardCabinetOption} from '../services/prodboard-types'

export class HiddenDrawerSink extends CabinetOption {

  /**
   * Must be statically set _before_ constructor
   */
  public readonly optionSelectName = 'HiddenDrawerSink'

  private prices = [
    {
      name: 'Ingen utdragslåda',
      amount: 0
    },
    {
      name: 'En extra utdragslåda',
      amount: 1
    },
    {
      name: 'Två extra utdragslådor',
      amount: 2
    }
  ]

  constructor(option: ProdboardCabinetOption, product: IProduct, cabinetId: number) {
    super(option, product, cabinetId)
    this.setNameAndOptions()

    this.priority = 100
    this.viewOptions = [
      {
        type: ViewOptionType.select,
        title: 'Utdragslådor vaskskåp',
        name: 'hiddenDrawerSink',
        values: ['En extra utdragslåda', 'Två extra utdragslådor', 'Ingen utdragslåda'],
        selection: 'Ingen utdragslåda',
        disabled: false
      }
    ]
    this.active = true

    this.description = 'MISSING'
    this.title = 'optHiddenDrawerSink'

    const sOption = HiddenDrawerSink.sanitize(option)
    this.setValuesFromProdboardData(sOption)
    this.setPrice()
  }

  private static sanitize(option: ProdboardCabinetOption): ProdboardCabinetOption {
    option.value = option.value || {name: 'Ingen utdragslåda'} as any
    // This is because the stupidness of hidden drawers have different names depending on cabinet
    if (option.value.name === 'En utdragslådor') {
      option.value.name = 'En extra utdragslåda'
    }
    if (option.value.name === 'Två utdragslådor') {
      option.value.name = 'Två extra utdragslådor'
    }

    return option
  }

  public update(data: any) {
    super.update(data)
    this.setFromProperties(data)
    this.active = this.viewOptions[0].selection !== this.prices[0].name
    this.setPrice()
  }

  protected getCustomCustomerListing(lc: string): string[] {
    return this.getCustomerListingByKey(lc, this.viewOptions[0].selection)
  }

  private setValuesFromProdboardData(option: ProdboardCabinetOption): void {
    this.viewOptions[0].selection = option.value.name
    this.active = this.viewOptions[0].selection !== 'Ingen utdragslåda'
  }

  private setPrice(): void {
    this.resetPrices()

    const findPrice = this.prices.find((price: any) => price.name === this.viewOptions[0].selection)
    if (!findPrice) {
      this.problems.push({description: 'No price for ' + this.viewOptions[0].selection, handled: false})
    } else {
      this.price = this.product.hiDrPr.price * findPrice.amount
      this.labor = this.product.hiDrPr.labor * findPrice.amount
      if (this.active) {
        this.shouldHavePrice = true
        const item = this.addReceiptItem()
        item.itemText = `Pris för ${findPrice.amount} utdragslådor`
        item.price = this.product.hiDrPr.price * findPrice.amount
        item.labor = this.product.hiDrPr.labor * findPrice.amount
        this.addPriceFromComments()
      }
    }
  }
}

import {CabinetOption, ViewOptionType} from './cabinet-option'
import {IProduct} from '../common/interface/product-types'
import {ProdboardCabinetOption} from '../services/prodboard-types'

export class FanAdoption extends CabinetOption {
  /**
   * Must be statically set _before_ constructor
   */
  public readonly optionSelectName = 'FanAdoption'

  constructor(option: ProdboardCabinetOption, product: IProduct, cabinetId: number) {
    super(option, product, cabinetId)
    this.setNameAndOptions()

    this.priority = 5 // This feels important
    this.viewOptions = [
      {
        type: ViewOptionType.binary,
        title: 'Inbyggd fläkt',
        name: 'fan',
        values: [
          'Ja',
          'Nej'
        ],
        selection: '',
        disabled: false
      }
    ]
    this.description = 'A cabinet can have a built-in extractor (hood)'
    this.title = 'optFanAdoption'
    const sOption = FanAdoption.sanitize(option)
    this.setValuesFromProdboardData(sOption)
    this.setPrice()
  }

  private static sanitize(option: ProdboardCabinetOption): ProdboardCabinetOption {
    option.value = option.value || {} as any

    return option
  }

  public update(data: any): void {
    super.update(data)
    this.setFromProperties(data)
    this.active = this.viewOptions[0].selection === 'Ja'
    this.setPrice()
  }

  private setValuesFromProdboardData(option: ProdboardCabinetOption): void {
    this.active = option.value.name === 'Ja'
    this.viewOptions[0].selection = option.value.name
  }

  private setPrice(): void {
    this.resetPrices()
    if (this.active) {
      this.price += this.product.adBuInHoPr.price
      this.labor += this.product.adBuInHoPr.labor
      const item = this.addReceiptItem()
      item.priceProperty = 'adBuInHoPr'
      this.shouldHavePrice = true
    }
    this.addPriceFromComments()
  }
}

import {Injectable} from '@angular/core'
import {ProdboardCabinet} from './cabinet/prodboard-cabinet'
import {ProductCategory} from '../services/product-static.service'
import {OverrideService} from '../services/override.service'
import {ProdboardItem} from '../services/prodboard-types'
import {IProject} from '../services/project-types'

@Injectable({
  providedIn: 'root'
})
export class ProdboardCabinetFactory {

  public createCabinet(
    overrideService: OverrideService,
    product: ProductCategory,
    input: ProdboardItem,
    project: IProject): ProdboardCabinet {
    return new ProdboardCabinet(overrideService, product, input, project)
  }
}

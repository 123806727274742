import {Injectable, InjectionToken} from '@angular/core'
import {Observable, ReplaySubject} from 'rxjs'
import {HttpClient} from '@angular/common/http'
import {environment} from '../../../environments/environment'
import {map} from 'rxjs/operators'
import {IAppliance, Appliance} from '../model/appliance'

export interface ApplianceType {
  name: 'stove' | 'stoveTop' | 'oven' | 'fan' | 'dishwasher' | 'refrigerator' | 'freezer' | 'micro'
    | 'handle' | 'knobs' | 'knob' | 'faucet' | 'sink' | 'sinkBench' | 'other'
  displayName: string
}

export const applianceTypes: ApplianceType[] = [
  {
    name: 'stove',
    displayName: 'Spis'
  },
  {
    name: 'stoveTop',
    displayName: 'Häll'
  },
  {
    name: 'oven',
    displayName: 'Ugn'
  },
  {
    name: 'fan',
    displayName: 'Fläkt'
  },
  {
    name: 'dishwasher',
    displayName: 'Diskmaskin'
  },
  {
    name: 'refrigerator',
    displayName: 'Kylskåp'
  },
  {
    name: 'freezer',
    displayName: 'Frys'
  },
  {
    name: 'micro',
    displayName: 'Micro'
  },
  {name: 'handle', displayName: 'Handtag'},
  {name: 'knobs', displayName: 'Knoppar'},
  {name: 'knob', displayName: 'Vred'},
  {name: 'faucet', displayName: 'Blandare'},
  {name: 'sink', displayName: 'Diskho'},
  {name: 'sinkBench', displayName: 'Diskbänk'},
  {name: 'other', displayName: 'Övrigt'}
]

export interface IApplianceService {
  /**
   * A function that saves the appliance
   *
   * @param appliance - The appliance to save
   * @returns - The same appliance potentially updated
   */
  saveAppliance(appliance: Appliance): Observable<Appliance>

  /**
   * A function that deletes the appliance
   *
   * @param appliance - The appliance to deletes
   */
  deleteAppliance(appliance: Appliance): Observable<void>
}

export const APPLIANCE_SERVICE = new InjectionToken<IApplianceService>('applianceService')

@Injectable({
  providedIn: 'root'
})
export class ApplianceService implements IApplianceService {

  public appliances$ = new ReplaySubject<Appliance[]>()

  public applianceTypeMap: { [key: string]: string} = {}

  private appliances: Appliance[] = []


  constructor(
    private httpClient: HttpClient
  ) {
    applianceTypes.forEach((type: ApplianceType) => this.applianceTypeMap[type.name] = type.displayName)

    const url = `${environment.productUrl}/appliances`
    this.httpClient.get<IAppliance[]>(url).subscribe({
      next: (appliances: Appliance[]) => {
        this.appliances = appliances.map((a: IAppliance) => new Appliance(a))
        this.appliances$.next(this.appliances)
      }
    })
  }

  /**
   * Create and delete are server operations
   */
  public saveAppliance(appliance: Appliance): Observable<Appliance> {
    let url = `${environment.productUrl}/appliances`
    if (appliance.id) {
      url += `/${appliance.id}`
    }
    return this.httpClient.put<Appliance>(url, appliance).pipe(
      map((updatedAppliance: Appliance) => {
        if (appliance.id) {
          const a1 = this.appliances.find((a: Appliance) => a.id === appliance.id)
          Object.assign(a1, updatedAppliance)
        } else {
          this.appliances.push(updatedAppliance)
        }
        this.appliances$.next(this.appliances)
        return updatedAppliance
      })
    )
  }

  public deleteAppliance(appliance: Appliance): Observable<any> {
    const url = `${environment.productUrl}/appliances/${appliance.id}`
    return this.httpClient.delete(url).pipe(
      map(() => {
        this.appliances = this.appliances.filter((a: Appliance) => a.id !== appliance.id)
        this.appliances$.next(this.appliances)
      })
    )
  }
}

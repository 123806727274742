/**
 * Suddenly decided to collect some global
 * helpers here.
 *
 */

import {formatNumber} from '@angular/common'

/**
 * Calculate the reverse percent and present it as a customer string,
 * if no price the empty string if no discount just the price
 *
 * @param price - The price given to customer
 * @param discount - The discount used, 10 is 10%
 * @return - A string of type "2000 - 10% rabatt = 1800
 */
export const calculateDiscount = (price: number, discount: number): string => {
  let result = ''
  if (!price) {
    return ``
  }

  if (!discount) {
    result = `${formatNumber(price, 'fr', '1.0-0')} kr`
  } else {
    // x - (x * 10%) = 1800 => x - (x * 10%) - 1800 = 0 =>
    // x -0.1x-1800 = 0 => 0.9x - 1800 = 0 => 0.9x = 1800 =>
    // x = 1800/0.9 => 200 => original price 1800 + 200 = 200
    const originalPrice = price / (1 - discount / 100)
    result = `${formatNumber(originalPrice, 'fr', '1.0-0')} kr ` +
      `- ${discount}% rabatt = ${formatNumber(price, 'fr', '1.0-0')} kr`
  }
  // Make bigger spaces
  return result.replace(/ /g, ' ')
}

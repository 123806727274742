import {CabinetOption, ViewOptionType} from './cabinet-option'
import {IProduct} from '../common/interface/product-types'
import {ProdboardCabinetOption} from '../services/prodboard-types'

/**
 * Class/Model introduced for "Utdragslåda" in Oven cabinet BD1o, BD1o+PC
 */
export class DrawerDoor extends CabinetOption {
  /**
   * Must be statically set _before_ constructor
   */
  public readonly optionSelectName = 'DrawerDoor'

  constructor(option: ProdboardCabinetOption, product: IProduct, cabinetId: number) {
    super(option, product, cabinetId)
    this.setNameAndOptions()

    this.priority = 100

    option.value = option.value || {name: 'Lucka'} as any

    this.viewOptions = [
      {
        type: ViewOptionType.select,
        title: 'Låda/Lucka',
        name: 'drawerDoor',
        values: ['Låda', 'Lucka'],
        selection: option.value.name,
        disabled: false
      },
    ]
    // Always active since it is either door or drawer. Drawers
    this.active = true
    this.description = 'MISSING'
    this.title = 'optDrawerDoor'
    this.setPrice()
  }

  public update(data: any) {
    super.update(data)
    this.setFromProperties(data)
    this.setPrice()
  }

  protected getCustomCustomerListing(lc: string): string[] {
    return this.getCustomerListingByKey(lc, this.viewOptions[0].selection)
  }

  private setPrice(): void {
    this.resetPrices()
    if (this.viewOptions[0].selection === 'Låda') {
      this.price = this.product.hiDrPr.price
      this.labor = this.product.hiDrPr.labor
      const item = this.addReceiptItem()
      item.itemText = `Pris för låda`
      item.price = this.product.hiDrPr.price
      item.labor = this.product.hiDrPr.labor
      this.shouldHavePrice = true
    }
    this.addPriceFromComments()
  }
}

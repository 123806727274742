import {Component, Inject} from '@angular/core'
import {CabinetOptionComponent} from '../cabinet-option/cabinet-option.component'
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog'

@Component({
  selector: 'kdl-option-dialog',
  templateUrl: './option-dialog.component.html',
})
/**
 * This is a wrapper for the CabinetOption to show it in
 * a dialog. Nothing more nothing less.
 */
export class OptionDialogComponent {


  constructor(
    public dialogRef: MatDialogRef<CabinetOptionComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
  }
}

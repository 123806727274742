import {Injectable} from '@angular/core'
import {ReplaySubject} from 'rxjs'
import {CounterTop, ICounterTopCabinet} from '../model/counter-top'
import {ProjectHelperService} from '../../services/project-helper.service'
import {IProject} from '../../services/project-types'
import {filter} from 'rxjs/operators'

const translations = {
  sv: {
    and: 'och',
    cabinet: 'Skåp',
    plural: ''
  },
  en: {
    and: 'and',
    cabinet: 'Cabinet',
    plural: 's'
  }
}

export const indexesToList = (indexes: number[] | string[], lang: 'en' | 'sv'): string => {

  if (indexes.length === 0) {
    return ''
  }

  // Remove duplicates and make a new list to keep the original safe
  const list = [...new Set<string>(indexes.map(i => i + ''))]
  // Sort 'em
  list.sort((a: string, b: string) => a.localeCompare(b))

  let result = `${list[0]}`
  if (list.length > 1) {
    const lastIndex = list.pop()
    result = list.join(', ') + ` ${translations[lang].and} ${lastIndex}`
  }
  return result
}

/**
 * Return a list of type 1, 3, 5 and 8 based on the cabinets on this counter top
 * replaces and with "och" if sv is passed as language parameter
 *
 * @param counterTop - The counter top object (instantiated class CounterTop)
 * @param lang - The word before the last cabinet
 */
export const getCabinetIndexList = (counterTop: CounterTop, lang: 'en' | 'sv'): string | undefined => {
  const cabinetIndexes = counterTop.cabinets.map((cabinet: ICounterTopCabinet) => cabinet.index)
  return indexesToList(cabinetIndexes, lang)
}

type TDelimiters = ',' | '.' | ''

export const getCabinetListString = (counterTop: CounterTop, lang: 'en' | 'sv' = 'en', delimiter: TDelimiters = '.'): string => {
  if (counterTop.cabinets.length === 0) {
    return ''
  }

  let plural = ''
  if (counterTop.cabinets.length > 1) {
    plural = translations[lang].plural
  }
  return `${translations[lang].cabinet}${plural}: ${getCabinetIndexList(counterTop, lang)}${delimiter}`
}

@Injectable({
  providedIn: 'root'
})
export class CounterTopService {

  public allSelectedCabinets$: ReplaySubject<string[]> = new ReplaySubject<string[]>(1)

  /**
   * Possibly a list of all selected cabinets for this countertop?
   */
  private allSelectedCabinets: string[] = []

  constructor(
    private projectHelperService: ProjectHelperService
  ) {
    this.projectHelperService.project$
      .pipe(filter(Boolean))
      .subscribe({
        next: (project: IProject) => {
          this.allSelectedCabinets.length = 0
          project.counterTops.forEach((counterTop: CounterTop) => {
            this.allSelectedCabinets = this.allSelectedCabinets
              .concat(counterTop.cabinets.map((ct: ICounterTopCabinet) => ct.uid))
          })
          this.allSelectedCabinets$.next(this.allSelectedCabinets)
        }
      })
  }

  public addToAllCabinets(cabinetId: string): void {
    this.allSelectedCabinets.push(cabinetId)
    this.allSelectedCabinets$.next(this.allSelectedCabinets)
  }
}

import {IProduct, OUTSIDE_SKIRTING, STANDARD_SKIRTING} from '../common/interface/product-types'
import {CabinetOption, ViewOptionType} from './cabinet-option'
import {ProdboardCabinetOption} from '../services/prodboard-types'

export class Skirting extends CabinetOption {
  /**
   * Must be statically set _before_ constructor?
   */
  public readonly optionSelectName = 'Skirting'

  private selectionOptions = {
    reg: STANDARD_SKIRTING,
    sk: OUTSIDE_SKIRTING,
  }

  constructor(option: ProdboardCabinetOption, product: IProduct, cabinetId: number) {
    super(option, product, cabinetId)
    this.setNameAndOptions()

    this.priority = 20
    this.viewOptions = [
      {
        type: ViewOptionType.select,
        title: 'Typ av sockel',
        name: 'skirting',
        values: [STANDARD_SKIRTING, OUTSIDE_SKIRTING],
        selection: '',
        disabled: true
      }
    ]

    this.description = 'Decides what kind of socle style the cabinet has - there are 2 different styles'
    this.title = 'optSkirting'

    const sOption = Skirting.sanitize(option)
    this.setValuesFromProdboardData(sOption)
    this.addPriceFromComments()
  }

  private static sanitize(option: ProdboardCabinetOption): ProdboardCabinetOption {
    option.value = option.value || {} as any
    option.value.options = option.value.options || {} as any
    option.value.options.plinth = option.value.options.plinth || {} as any
    option.value.options.plinth.type = option.value.options.plinth.type || ''
    return option
  }

  /**
   * For CAD, we just want the legs
   */
  public valueMap(): { [p: string]: string | number | boolean } {
    return {
      skirt: ['standard plinth board', 'outside skirting board'][this.viewOptions[0].values.indexOf(this.viewOptions[0].selection)],
    }
  }

  public update(data: any): void {
    super.update(data)
    this.resetPrices()
    this.setFromProperties(data)
    this.addPriceFromComments()
  }

  protected getCustomCustomerListing(lc: string): string[] {
    const answer: string[] = []
    if (this.viewOptions[0].selection === STANDARD_SKIRTING) {
      answer.push(this.optionValueNames[0][lc])
    }
    if (this.viewOptions[0].selection === OUTSIDE_SKIRTING) {
      answer.push(this.optionValueNames[1][lc])
    }
    return answer
  }

  private setValuesFromProdboardData(option: ProdboardCabinetOption): void {
    this.viewOptions[0].selection = this.selectionOptions[option.value.options.plinth.type]
    this.active = option.value.options.plinth.type === 'sk' || option.value.options.plinth.type === 'reg'
    this.viewOptions[0].disabled = false
  }
}

import {CabinetOption} from './cabinet-option'
import {IProduct} from '../common/interface/product-types'
import {ProdboardCabinetOption} from '../services/prodboard-types'

export class UnknownOption extends CabinetOption {
  readonly optionSelectName = 'UnknownOption'
  constructor(option: ProdboardCabinetOption, product: IProduct, cabinetId, key: string) {
    super(option, product, cabinetId)
    this.name = 'UnknownOption' + '_' + option.count
    this.title = `Okänt alternativ (${key})`
    this.priority = 101
  }
}

import {IProduct} from '../common/interface/product-types'
import {CabinetOption, ViewOptionType} from './cabinet-option'
import {OptionValueNameItem} from '../services/override.service'
import {ProdboardCabinetOption} from '../services/prodboard-types'

export const NO_DOOR_SELECTED = 'Ingen lucka'

export class Door extends CabinetOption {

  /**
   * Must be statically set _before_ constructor
   */
  public readonly optionSelectName = 'Door'

  /**
   * Set this to notify factory if no door/glass door and NOT
   * painted inside
   */
  public paintedInsideNote = ''

  constructor(option: ProdboardCabinetOption, product: IProduct, cabinetId: number) {
    super(option, product, cabinetId)
    this.setNameAndOptions()

    this.priority = 10
    this.viewOptions = [
      {
        type: ViewOptionType.select,
        title: 'Lucktyp',
        name: 'doorType',
        values: ['Vitrinlucka', 'Standardlucka', NO_DOOR_SELECTED],
        selection: 'Standardlucka',
        disabled: false
      },
      {
        type: ViewOptionType.binary,
        title: 'Målad insida',
        name: 'paintedInside',
        values: ['Ja', 'Nej'],
        selection: 'Nej',
        disabled: false
      }
    ]
    this.active = true // Door is always active b/c if you remove the door you still have a door.

    this.description = 'Different door settings: Glass door, or "no door", etc'
    this.title = 'optDoor'
    Door.sanitize(option)
    this.setValuesFromProdboardData(option)
    this.setPrice()
  }

  private static sanitize(option: ProdboardCabinetOption): ProdboardCabinetOption {
    option.value = option.value || {} as any
    option.value.name = option.value.name || ''
    option.value.door = option.value.door || {} as any
    option.value.door.no_door = option.value.door.no_door || {} as any
    option.value.door.no_door.option = option.value.door.no_door.option || {} as any
    option.value.door.no_door.option.name = option.value.door.no_door.option.name || ''
    return option
  }

  /**
   * For CAD, we just want the paint, true | false and door type
   */
  public valueMap(): { [p: string]: string | number | boolean } {
    return {
      paint: [true, false][this.viewOptions[1].values.indexOf(this.viewOptions[1].selection)]
    }
  }

  /**
   * This is called from the outside to update the values of
   * this item based on data stored in the project. The data
   * will match the data we have on the form.
   */
  public update(data: any): void {
    super.update(data)
    if (data.doorType) {
      this.viewOptions[0].selection = data.doorType
      this.viewOptions[1].selection = data.paintedInside || 'Nej'
    }
    this.viewOptions[1].disabled = this.viewOptions[0].selection === 'Standardlucka'
    this.checkPaintedInsideNote()
    this.setPrice()
  }

  protected getCustomCustomerListing(lc: string): string[] {
    const answer: string[] = []

    this.optionValueNames.forEach((option: OptionValueNameItem) => {
      if (option.key === this.viewOptions[0].selection) {
        answer.push(option[lc])
      }
    })
    if (this.viewOptions[1].selection === 'Ja') {
      answer.push(this.optionValueNames[2][lc])
    }
    return answer
  }

  /**
   * This function converts the complex structure from Prodboard to
   * out own complex structure that we use in presentation.
   */
  private setValuesFromProdboardData(option: ProdboardCabinetOption): void {
    this.viewOptions[0].selection = 'Standardlucka'
    this.viewOptions[1].selection = 'Nej' // We say that you cannot paint inside if you have a door.
    if (option.value.name === 'Vitrinlucka') {
      this.viewOptions[0].selection = 'Vitrinlucka'
    }
    if (option.value.name === 'Välj bort lucka') {
      this.viewOptions[0].selection = NO_DOOR_SELECTED
    }
    if (option.value.door.no_door.option.name === 'Målad insida') {
      this.viewOptions[1].selection = 'Ja'
    }
    /**
     * If no price for Glass Door, then we cannot have glass doors either.
     * Remove it from the selection list
     */
    if (!this.product.glDoPr.price) {
      this.viewOptions[0].values.splice(0, 1)
    }
    this.checkPaintedInsideNote()
  }

  private checkPaintedInsideNote(): void {
    this.paintedInsideNote = ''
    if (['Vitrinlucka', NO_DOOR_SELECTED].indexOf(this.viewOptions[0].selection) !== -1 &&
      this.viewOptions[1].selection === 'Nej') {
      this.paintedInsideNote = 'Standard waxed insides (NOT painted)'
    }
  }

  /**
   * Note that we calculate prices on OUR model.
   */
  private setPrice(): void {
    this.resetPrices()

    if (this.viewOptions[0].selection === 'Vitrinlucka') {
      this.price = this.product.glDoPr.price
      this.labor = this.product.glDoPr.labor
      const item = this.addReceiptItem()
      item.itemText = 'Vitrinlucka'
      item.priceProperty = 'glDoPr'
      this.shouldHavePrice = true
    }

    if (this.viewOptions[0].selection === 'Ingen lucka') {
      this.price += this.product.noDoPr.price || 0
      this.labor += this.product.noDoPr.labor || 0

      const item = this.addReceiptItem()
      item.itemText = 'Ingen lucka'
      item.priceProperty = 'noDoPr'
    }
    // Painted inside, this ADDS to the price so we do this last
    if (this.viewOptions[1].selection === 'Ja') {
      this.price += this.product.paInPr.price
      this.labor += this.product.paInPr.labor
      const item = this.addReceiptItem()
      item.itemText = 'Ja'
      item.price = this.product.paInPr.price
      item.labor = this.product.paInPr.labor
      item.material = 0
      item.priceProperty = 'paInPr'
      this.shouldHavePrice = true
    }
    this.addPriceFromComments()
  }
}

import {Component, OnInit, OnDestroy} from '@angular/core'
import {filter} from 'rxjs/operators'
import {ProjectHelperService} from '../../../services/project-helper.service'
import {IProject} from '../../../services/project-types'
import {Subscription} from 'rxjs'

@Component({
  selector: 'kdl-project-price-summary',
  templateUrl: './project-price-summary.component.html',
  styleUrls: ['./project-price-summary.component.scss']
})
export class ProjectPriceSummaryComponent implements OnInit, OnDestroy {

  public snickerierTotal = 0

  public applianceTotal = 0

  public customerPrice: number | undefined

  private sub$ = new Subscription()

  constructor(
    public projectService: ProjectHelperService
  ) {
  }

  public ngOnInit(): void {
    this.sub$ = this.projectService.project$.pipe(
      filter(Boolean)
    ).subscribe({
      next: (project: IProject) => {
        this.customerPrice = project.form.customerPrice
      }
    })
  }

  public ngOnDestroy(): void {
    this.sub$.unsubscribe()
  }

  public setTotal(total: number, type: string): void {
    //
    // To avoid "changedAfterCheck" we have to set this on next
    // cycle...
    setTimeout(() => this[type] = total, 1)
  }
}

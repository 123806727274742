<div class="shortcut">
  <div [routerLink]="['dashboard','project']" class="shortcut-holder project">
    <mat-icon class="material-icons-outlined" fontIcon="change_circle"></mat-icon>
    <div kdlI18n label="switchProjectShortCut"></div>
  </div>
  <div (click)="openProject()" class="shortcut-holder project special">
    <mat-icon class="material-icons-outlined" fontIcon="add"></mat-icon>
    <div kdlI18n label="newProjectShortCut"></div>
  </div>
  <div class="filler"></div>
  <div [routerLink]="editProjectLink" class="shortcut-holder project">
    <mat-icon class="material-icons-outlined" fontIcon="edit"></mat-icon>
    <div kdlI18n label="editProjectShortCut"></div>
  </div>

</div>

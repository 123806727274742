import {Component, OnDestroy} from '@angular/core'
import {Subscription} from 'rxjs'
import {ProjectService} from '../../services/project.service'
import {ProdboardCabinet} from '../../model/cabinet/prodboard-cabinet'
import {FactoryCabinet} from '../../factory-internal/helpers/factory-cabinet'
import {IProject} from '../../services/project-types'
import {filter, first, switchMap} from 'rxjs/operators'
import {CabinetOption} from '../../model/cabinet-option'
import {Comment} from '../../comments/model/comment'
import {SummaryProperties} from '../../model/project-option/project-option'

@Component({
  selector: 'kdl-cad',
  templateUrl: './cad.component.html',
  styleUrls: ['./cad.component.scss']
})
export class CadComponent implements OnDestroy {

  private sub$ = new Subscription()

  private rowItems = [
    'Cabinet number',
    'Module name',
    'Description',
    'IMPORTANT NOTES',
    'Extra',
    '(A) TOTAL WIDTH (mm)',
    '(B) FRAME LEFT width (mm)',
    '(C) Filler left side (i.e. recessed carcass position)',
    '(D) FRAME RIGHT width (mm)',
    '(E) Filler right side (i.e. recessed carcass position)',
    'Leg? options:"yes right side" "yes left side" "yes both sides"',
    'MId-post? options: "standard mid-post" "N.B mid-post fixed to door" "N.B. no mid-post"',
    '(F) HOLE WIDTH (for door/drawer) (mm)',
    '(G) Height (mm)',
    '(H) Top frame',
    '(I) Bottom frame',
    '(J) Door Height',
    '(Hinge Location) Left/Right/Both/Top',
    '(K) Depth (mm)',
    'Number of shelves',
    'Color',
    'Endpanels',
    '"Console" decoration on end panel',
    '"Curve" in the bottom corners',
    'Chopping board',
    'Backpanel',
    'Cornice',
    'Lights',
    'Cutlery dividers',
    'Knife block',
    'Wavy block',
    '"Hidden" internal drawer',
    'Skirting',
    'Legs',
    'Door Type',
    'Painted inside of carcass',
    'Built-in oven?',
    'Built-in microwave?',
    'Built-in hood',
    'Drawer 1 Height',
    'Drawer 2 Height',
    'Drawer 3 Height',
    'Drawer 4 Height',
    'Drawer5 Height',
    'Types of Hinges',
    'X', // This is the dimensions of the cabinet
    'Y',
    'Z',
    'pX', // These represent to location of the cabinet. Note it is the center
    'pY',
    'pZ',
  ]


  constructor(
    private projectService: ProjectService
  ) {
  }

  public ngOnDestroy(): void {
    this.sub$.unsubscribe()
  }

  public save(): void {
    let project: IProject
    this.sub$ = this.projectService.currentProject$
      .pipe(
        filter(Boolean),
        switchMap((p: IProject) => {
          project = p
          return this.projectService.cabinets$
        }),
        filter((cabinets: ProdboardCabinet[]) => cabinets && cabinets.length > 0),
        first()
      )
      .subscribe({
        next: (cabinets: ProdboardCabinet[]) => {
          const rows: string[] = cabinets.map((cab: ProdboardCabinet) => {
            const optionMap = this.getOptionValues(cab.options)
            const factoryCabinet = new FactoryCabinet(cab)
            const row = []
            this.addProperty(row, cab.index) // Cabinet number
            this.addProperty(row, cab.cat) // Module Name
            this.addProperty(row, cab.description) // Description from product
            this.addProperty(row, factoryCabinet.importantNotes.join(' xx_xx ')) // IMPORTANT NOTES
            this.addProperty(row, 'Extra')
            this.addProperty(row, factoryCabinet.a)
            this.addProperty(row, factoryCabinet.b)
            this.addProperty(row, factoryCabinet.c)
            this.addProperty(row, factoryCabinet.d)
            this.addProperty(row, factoryCabinet.e)
            this.addProperty(row, optionMap.Legs)
            this.addProperty(row, this.resolveSummary(factoryCabinet.getCenterPost())) // Known as Midpost in CAD
            this.addProperty(row, factoryCabinet.f)
            this.addProperty(row, factoryCabinet.g)
            this.addProperty(row, factoryCabinet.h)
            this.addProperty(row, factoryCabinet.i)
            this.addProperty(row, factoryCabinet.j)
            this.addProperty(row, optionMap.Hanging)
            this.addProperty(row, factoryCabinet.k)
            this.addProperty(row, cab.numberOfShelves)
            this.addProperty(row, project.form.color)
            this.addProperty(row, optionMap.CoverSide)
            this.addProperty(row, 'tbd') // '"Console" decoration on end panel'
            this.addProperty(row, 'tbd') // "Curve" in the bottom corners'
            this.addProperty(row, optionMap.CuttingBoard)
            this.addProperty(row, optionMap.BackPanel)
            this.addProperty(row, optionMap.Cornice)
            this.addProperty(row, optionMap.Lightning)
            this.addProperty(row, optionMap.Cutlery)
            this.addProperty(row, optionMap.Knife)
            this.addProperty(row, optionMap.Wavy)
            this.addProperty(row, optionMap.HiddenDrawer)
            this.addProperty(row, optionMap.Skirt)
            this.addProperty(row, this.resolveSummary(factoryCabinet.getDoors()) /*'Door Type'*/)
            this.addProperty(row, optionMap.Paint) // 'Painted inside of carcass'
            this.addProperty(row, cab.isOven)
            this.addProperty(row, 'n/a') // Microwave not supported
            this.addProperty(row, optionMap.FanAdoption)
            for (let i = 0; i < 5; i++) {
              this.addProperty(row, cab.drawers[i] || '') // Drawer 1 height
            }
            this.addProperty(row, this.resolveSummary(factoryCabinet.getHinges()))
            this.addProperty(row, cab.dimensions.x)
            this.addProperty(row, cab.dimensions.y)
            this.addProperty(row, cab.dimensions.z)
            this.addProperty(row, cab.position.center.x)
            this.addProperty(row, cab.position.center.y)
            this.addProperty(row, cab.position.center.z)
            return row.join(',')
          })
          const headerRow = this.rowItems.join(',')
          rows.unshift(headerRow)
          const result = rows.join('\n')
          const taBlob = new Blob([result], {type: 'text/csv;charset=utf-8'})
          const href = URL.createObjectURL(taBlob)
          const el = document.createElement('a')
          el.href = href
          el.download = `${project.customer.name}-CAD.csv`
          el.click()
          el.remove()
        }
      })
  }

  private addProperty(row: Array<string | number>, prop: string | boolean | number): void {
    if (typeof prop === 'string') {
      // Remove all white space (tabs, \n \r etc.) replace with simple ' '
      // Then replace all ';' with ##_(.)(.)_## to avoid extra semis
      let res = prop.replace(/\s+/g, ' ').trim()
      res = res.replace(/,/g, '##_(x)(x)_##')
      row.push(res)
      return
    }

    if (typeof prop === 'boolean') {
      row.push(prop ? 'yes' : 'no')
      return
    }
    row.push(prop)
  }

  private getOptionValues(options: CabinetOption[]): { [key: string]: string } {
    const optionMap: { [key: string]: string } = {}
    options
      .filter((option: CabinetOption) => option.active && option.getCustomerListing('factory').length > 0)
      .forEach((option: CabinetOption) => {

        switch (option.optionSelectName) {
          case 'Skirting': {
            const valueMap = option.valueMap()
            optionMap.Skirt = valueMap.skirt as string
            break
          }
          case 'Legs': {
            const valueMap = option.valueMap()
            optionMap.Legs = valueMap.legs as string
            break
          }
          case 'Door': {
            const valueMap = option.valueMap()
            optionMap.Paint = valueMap.paint as string
            break
          }
          case 'DrawerInsert': {
            const valueMap = option.valueMap()
            optionMap.Cutlery = valueMap.cutlery as string
            optionMap.Knife = valueMap.knife as string
            optionMap.Wavy = valueMap.wavy as string
            break
          }
          default:
            this.setDefaultOptionValues(option, optionMap)
        }
      })
    return optionMap
  }

  private setDefaultOptionValues(option: CabinetOption, optionMap: { [key: string]: string }): void {
    const opt: string[] = option.getCustomerListing('factory')
    const comments = option.comments
      .filter((comment: Comment) => comment.translation)
      .map((comment: Comment) => comment.translation).join('; ')
    if (comments) {
      opt.push(comments)
    }
    optionMap[option.optionSelectName] = opt.join('; ')
  }

  private resolveSummary(sps: SummaryProperties[]): string {
    return sps.map((sp: SummaryProperties) => sp.description).join('')
  }
}

import {Component, EventEmitter, OnDestroy, OnInit, Output} from '@angular/core'
import {ReceiptService} from '../../../services/receipt.service'
import {ReplaySubject, Subscription} from 'rxjs'
import {ReceiptItem} from '../../../model/receipt/receipt-item'

interface Item {
  text: string

  /**
   * Price is the current price,
   * rPrice is the running total
   * cPrice is the total of the cabinet, all options all comments
   */
  price: number
  rPrice: number
  cPrice: number

  labor: number
  rLabor: number
  cLabor: number

  material: number
  rMaterial: number
  cMaterial: number

  /**
   * The source is used for formatting, 'cabinet', 'comment' 'option' etc.
   */
  source: string

  /**
   * Cabinet last is the last item of this cabinet.
   * This so that we can set the subtotal.
   */
  cabinetLast: boolean
}

@Component({
  selector: 'kdl-kitchen-receipt',
  templateUrl: './kitchen-receipt.component.html',
  styleUrls: ['./kitchen-receipt.component.scss']
})
export class KitchenReceiptComponent implements OnInit, OnDestroy {

  @Output() total: EventEmitter<number> = new EventEmitter<number>()

  public items$: ReplaySubject<Item[]> = new ReplaySubject<Item[]>(1)

  public rPrice = 0
  public rLabor = 0
  public rMaterial = 0

  private sub$: Subscription = new Subscription()

  constructor(
    private receiptService: ReceiptService
  ) {
  }

  public ngOnInit(): void {
    this.sub$ = this.receiptService.items$.subscribe({
      next: (receiptItems: ReceiptItem[]) => {
        /**
         * Remove ALL items that are NOT of relevance. Currently
         * only 'appliance' but take care when you add more...
         */
        receiptItems = receiptItems.filter((receiptItem: ReceiptItem) => receiptItem.source !== 'appliance')
        this.rPrice = 0
        this.rLabor = 0
        this.rMaterial = 0
        let cabinetIndex = -1

        let cPrice = 0
        let cLabor = 0
        let cMaterial = 0

        let previous: Item = {} as any
        const list: Item[] = receiptItems.map((receiptItem: ReceiptItem) => {
          this.rPrice += Math.round(receiptItem.price)
          this.rLabor += Math.round(receiptItem.labor)
          this.rMaterial += Math.round(receiptItem.material)
          if (cabinetIndex !== receiptItem.cabinetIndex) {
            previous.cabinetLast = true
            cabinetIndex = receiptItem.cabinetIndex
            cPrice = 0
            cLabor = 0
            cMaterial = 0
          }
          cPrice += Math.round(receiptItem.price)
          cLabor += Math.round(receiptItem.labor)
          cMaterial += Math.round(receiptItem.material)
          const result: Item = {
            text: receiptItem.itemText,
            price: Math.round(receiptItem.price),
            labor: Math.round(receiptItem.labor),
            material: Math.round(receiptItem.material),
            rPrice: this.rPrice,
            rLabor: this.rLabor,
            rMaterial: this.rMaterial,
            cPrice,
            cLabor,
            cMaterial,
            source: receiptItem.source,
            cabinetLast: false
          }
          previous = result
          return result
        })
        previous.cabinetLast = true
        this.total.emit(this.rPrice)
        this.items$.next(list)
      }
    })
  }

  public ngOnDestroy(): void {
    this.sub$.unsubscribe()
  }
}

import {Injectable} from '@angular/core'
import {ProjectService} from './project.service'
import {ProdboardCabinet} from '../model/cabinet/prodboard-cabinet'
import {filter, first, map, switchMap, tap} from 'rxjs/operators'
import {BehaviorSubject, forkJoin, from, Observable, of, ReplaySubject} from 'rxjs'
import {Comment} from '../comments/model/comment'
import {IApplianceService} from '../appliances/service/appliance.service'
import {Problem, ProblemService} from './problem.service'
import {CabinetOption} from '../model/cabinet-option'
import {ReceiptItem} from '../model/receipt/receipt-item'
import {CabinetSettings} from '../model/cabinet-settings/cabinet-setting'
import {CounterTop, ICounterTop, ICounterTopCabinet} from '../counter-top/model/counter-top'
import {Appliance, IAppliance} from '../appliances/model/appliance'
import {WarningService} from '../warnings/service/warning.service'
import {IProject, ProjectFormData} from './project-types'
import {IProjectImage, TEMP_SOURCE_ID} from '../images/model/project-image'
import {filterImages} from '../images/services/image-filter'
import {ImagesService} from '../images/services/images.service'

/**
 * The project helper service help setting data from
 * the prodboard "file" to the project and vice versa.
 *
 */

/**
 * The Cabinet Options that are emitted.
 */
export interface CabinetOptionChange {

  /**
   * Cabinet Id/index??
   */
  cabinet: number

  /**
   * This is the unique name/id of the option.
   */
  name: string

  /**
   * We do not care about the data as such.
   */
  data: any

  /**
   * Active is very special?
   */
  active: boolean
}

@Injectable({
  providedIn: 'root'
})
export class ProjectHelperService implements IApplianceService {

  /**
   * This is the cabinets after processing.
   */
  public cabinets$: ReplaySubject<ProdboardCabinet[]> = new ReplaySubject<ProdboardCabinet[]>(1)

  /**
   * This is where we publish the project after initial processing.
   */
  public project$: Observable<IProject | null>

  /**
   * On this subject each option on each cabinet can emit changes
   * We populate the data sent to the project
   */
  public cabinetOptionsChanges$ = new ReplaySubject<CabinetOptionChange>(1)

  /**
   * We currently hold a representation of project here.
   */
  private project: IProject = null

  /**
   * To make sure no-one else is publishing projects
   */
  private pProject$: BehaviorSubject<IProject | null> = new BehaviorSubject<IProject | null>(null)

  /**
   * We listen for project and cabinets. From the project service.
   */
  constructor(
    private projectService: ProjectService,
    private problemService: ProblemService,
    private warningService: WarningService,
    private imagesService: ImagesService
  ) {
    this.project$ = this.pProject$.asObservable()

    // Whenever a new project arrives we need to
    // replace?! Our internal project with the new
    // project. Its "always" a server project or a 'new'
    // project.
    projectService.currentProject$.pipe(
      tap((project: IProject) => {
        this.project = project
        if (this.project === null) {
          this.warningService.reset()
          this.cabinets$.next([])
          this.pProject$.next(null)
        }
      }),
      filter(Boolean),
      switchMap(() => {
        // Make sure the appliances and counterTops are real objects
        this.project.appliances = this.project.appliances.map((a: IAppliance) => new Appliance(a))
        this.project.counterTops = this.project.counterTops.map((c: ICounterTop) => new CounterTop(c))
        this.runMigrations(this.project)
        return this.projectService.cabinets$
      }),
      filter((cabinets: ProdboardCabinet[]) => cabinets.length > 0 && !!this.project),
      map((cabinets: ProdboardCabinet[]) => {
        this.warningService.reset()
        // Shortcut to the current cabinets.
        const cabs = this.project.cabinets

        // Create a new one to set properly
        const newCabs: { [key: string]: any } = {}

        cabinets.forEach((cabinet: ProdboardCabinet) => {
          // Shorthands so that we do _not_ forget to _not_ overwrite these.
          const index = cabinet.index
          const uid = cabinet.uid

          // If the new Cabinet has uid we go for UID approach
          if (cabinet.uid) {
            const existingProjectCabinetKey = Object.keys(cabs).find((key: string) => {
              // If the old cabinet has uid, use that for comparison,
              // if not then use classic index comparison
              if (cabs[key] && cabs[key].uid) {
                return cabs[key].uid === uid
              }
              return +key === index
            })
            if (existingProjectCabinetKey) {
              newCabs[cabinet.index] = Object.assign({comments: []}, cabs[existingProjectCabinetKey])
            } else {
              newCabs[index] = {comments: []}
            }
            // All future cabinets will have uid.
            newCabs[cabinet.index].uid = uid
          } else {
            newCabs[index] = Object.assign({comments: []}, cabs[index])
          }
        })
        // First we must remove all keys that do not exist in the new object
        // but do in the old.
        Object.keys(cabs).forEach((k: string) => {
          if (!newCabs[k]) {
            delete cabs[k]
          }
        })

        // This we do to make sure all cabinets exist in the cabs object.
        Object.keys(newCabs)
          .forEach((key: string) => this.project.cabinets[key] = this.project.cabinets[key] || {comments: []})

        // Here we assign values to the existing object. If we remove it the application
        // explodes b/c the object is referenced like everywhere :-p .
        Object.keys(newCabs).forEach((key: string) => this.project.cabinets[key] = newCabs[key])

        // Now make sure all comments have the proper index
        Object.keys(cabs).forEach(k => {
          cabs[k].comments.forEach((ck: Comment) => ck.cabinetIndex = +k)
        })
        return cabinets
      })
    ).subscribe({
      next: ((cabinets: ProdboardCabinet[]) => {
        //
        // Reactive programming mayhem!
        // Convert the array to an observable
        from<ProdboardCabinet[]>(cabinets)
          .subscribe({
            next: (cabinet: ProdboardCabinet) => {
              cabinet.update(this.project.cabinets[cabinet.index])
              cabinet.setSettings(this.project.cabinets[cabinet.index].settings)
              this.warningService.analyzeCabinet(cabinet)
            }
          })
        /**
         * Send and reset the problem list
         */
        cabinets.forEach((cabinet: ProdboardCabinet) => {
          cabinet.options.forEach((option: CabinetOption) => {
            option.problems.forEach((problem: Problem) => {
              this.problemService.problems$.next(problem)
            })
            option.problems.length = 0
          })
        })
        this.cabinets$.next(cabinets)
        // now we can update the project stats. It will take the latest
        // from the $ above + this.project and do its magix
        this.calculateProjectStats()
        this.warningService.analyzeProject(this.project)
        this.warningService.generateWarnings()
      })
    })

    this.cabinetOptionsChanges$.subscribe({
      next: (co: CabinetOptionChange) => this.updateCabinetOptions(co)
    })

    /**
     * We listen for changes and as soon as something has changed
     * we will emit the project.
     */
    this.projectService.changes$.pipe(
      filter(Boolean),
      filter(() => !!this.project),
      switchMap(() => this.cabinets$)
    ).subscribe({
      next: (cabinets: ProdboardCabinet[]) => {
        cabinets.forEach((cabinet: ProdboardCabinet) => {
          cabinet.update(this.project.cabinets[cabinet.index])
        })
        this.calculateProjectStats()
      }
    })
  }

  public saveAppliance(appliance: Appliance): Observable<Appliance> {
    const exist = this.project.appliances.find((existing: Appliance) => existing.id === appliance.id)
    if (exist) {
      Object.assign(exist, appliance)
    } else {
      this.project.appliances.push(appliance)
    }
    this.projectService.changes$.next({changed: true, fileChanged: false})
    return of(appliance)
  }

  public deleteAppliance(appliance: Appliance): Observable<any> {
    this.project.appliances = this.project.appliances.filter((a: Appliance) => a.id !== appliance.id)
    this.projectService.changes$.next({changed: true, fileChanged: false})
    return of({})
  }

  public addImage(image: IProjectImage): void {
    this.project.images.push(image)
    this.pProject$.next(this.project)
    this.projectService.changes$.next({changed: true, fileChanged: false})
  }

  /**
   * The assumption here is that the real image object is passed
   * Future improvement can be to actually check the available
   * images and modify, who knows.
   */
  public saveImage(_image: IProjectImage): void {
    this.projectService.changes$.next({changed: true, fileChanged: false})
  }

  public removeImage(id: string): void {
    this.project.images = this.project.images.filter((i: IProjectImage) => i.id !== id)
    this.imagesService.deleteImage(id).subscribe()
    this.pProject$.next(this.project)
    this.projectService.changes$.next({changed: true, fileChanged: false})
  }

  /**
   * Call this from comments only!
   */
  public updateImageSource(id: string): void {
    filterImages(this.project.images, undefined, TEMP_SOURCE_ID)
      .forEach((pi: IProjectImage) => {
        pi.sourceId = id
      })
    this.projectService.changes$.next({changed: true, fileChanged: false})
  }

  public getImage(id: string): Observable<IProjectImage> {
    let image: IProjectImage | undefined
    return this.project$.pipe(
      first(),
      filter(Boolean),
      switchMap((project: IProject) => {
        image = project.images.find(i => i.id === id)
        if (!image) {
          image = {
            scope: 'COMMENT',
            name: 'IMAGE MISSING',
            displayName: 'IMAGE_MISSING',
            viewUrl: './assets/photo_black.png',
            sourceId: '',
            title: 'IMAGE MISSING'
          }
          return of('/assets/photo_black.png')
        }
        return this.imagesService.getViewUrl(id)
      }),
      map((url: string) => {
        image.viewUrl = url
        return image
      })
    )
  }

  public addCounterTop(counterTop: CounterTop): void {
    const existing = this.project.counterTops.find((ct: ICounterTop) => counterTop.id === ct.id)
    if (existing) {
      Object.assign(existing, counterTop)
    } else {
      this.project.counterTops.push(counterTop)
    }
    // There shall basically always be one or more cabinets.
    this.setCounterTopToCabinets(counterTop)
  }

  public setCounterTopToCabinets(counterTop: ICounterTop): void {
    this.cabinets$.pipe(
      first(),
      switchMap((cabinets: ProdboardCabinet[]) => {
        // Make sure to always have something for ForkJoin to chew
        const res: Observable<any>[] = [of({})]

        // Create a flat array of valid UID:s [as-d-1, asd-12-, ...]
        const cabinetUIDs: string[] = counterTop.cabinets.map((c: ICounterTopCabinet) => c.uid)
        cabinets.forEach((c: ProdboardCabinet) => {
          // Remember if we have fiddled with this cabinet or not.
          let changed = false

          if (c.counterTopId === counterTop.id) {
            // We remove our self from this cabinet b/c we are not sure if it should be there
            c.counterTopId = ''
            // Remember that we have changed this cabinet and need to save it.
            changed = true
          }
          // If this is a cabinet with proper UID we add this counterTop
          if (cabinetUIDs.indexOf(c.uid) !== -1) {
            c.counterTopId = counterTop.id
            changed = true
          }

          if (changed) {
            // If we have changed this cabinet add it to change list
            // Copies the settings to the project
            res.push(this.setCabinetSettings(c, c.getSettings()))
          }
        })
        return forkJoin(res)
      })
    ).subscribe({
      next: () => {
        // Make sure to save even if no cabinets, rare.
        this.projectService.changes$.next({changed: true, fileChanged: false})
      }
    })
  }

  public removeCounterTop(index: number): void {
    this.cabinets$.pipe(first()).subscribe({
      next: (cabinets: ProdboardCabinet[]) => {
        this.project.counterTops[index].cabinets.forEach((cab: ICounterTopCabinet) => {
          const cabToRemove = cabinets.find((cabinet: ProdboardCabinet) => cab.uid === cabinet.uid)
          if (cabToRemove) {
            cabToRemove.setSettings({counterTop: ''} as any)
          }
        })
        this.project.counterTops.splice(index, 1)
        this.projectService.changes$.next({changed: true, fileChanged: false})
      }
    })
  }

  public getCounterTops(): CounterTop[] {
    return this.project.counterTops.map((ct: ICounterTop) => new CounterTop(ct))
  }

  public setProjectForm(data: ProjectFormData): Observable<IProject> {
    return this.projectService.currentProject$.pipe(
      first(),
      map((project: IProject) => {
        Object.assign(this.project.form, data)
        project.customer.name = data.customerName
        this.projectService.changes$.next({changed: true, fileChanged: false})
        return project
      })
    )
  }


  /**
   * This sets settings from the outside (settings component) to
   * the cabinet and to the project for later saving
   *
   * @param cabinet
   * @param settings
   */
  public setCabinetSettings(cabinet: ProdboardCabinet, settings: CabinetSettings): Observable<CabinetSettings> {
    return this.projectService.currentProject$.pipe(
      first(),
      map((project: IProject) => {
        // HEADS UP! This relies on cabinet index rather than UUID, should be fixed
        const projectCab = project.cabinets[cabinet.index]
        cabinet.setSettings(settings)

        if (!projectCab.settings) {
          projectCab.settings = new CabinetSettings()
        }

        Object.assign(projectCab.settings, settings)
        this.projectService.changes$.next({changed: true, fileChanged: false})
        return settings
      }))
  }

  public resetSettings(cabinet: ProdboardCabinet): Observable<CabinetSettings> {
    return this.projectService.currentProject$.pipe(
      first(),
      switchMap((project: IProject) => {
        const projectCab = project.cabinets[cabinet.index]
        cabinet.resetSettings() // In case ?
        projectCab.settings = new CabinetSettings()
        return this.setCabinetSettings(cabinet, projectCab.settings)
      })
    )
  }

  /**
   * Called by our subscription to set the options on the project
   */
  private updateCabinetOptions(cabinetOption: CabinetOptionChange): void {
    // First set the data in the project. It can be anything and will be put back
    cabinetOption.data.active = cabinetOption.active
    const existing = this.project.cabinets[cabinetOption.cabinet][cabinetOption.name] || {}
    this.project.cabinets[cabinetOption.cabinet][cabinetOption.name] = cabinetOption.data
    if (existing.comments) {
      this.project.cabinets[cabinetOption.cabinet][cabinetOption.name].comments = existing.comments
    }
    // Let the world know we have changes. And us too!
    this.projectService.changes$.next({changed: true, fileChanged: false})
  }

  /**
   * Whenever 'something' changes this method is called. It looks at the
   * 'cabinets' and make som additional calculations.
   *
   * It just updates "this.project" and hopes that all understans what
   * is going on... stupid shit!
   *
   * I hereby add a "receipt" property to the mess.
   *
   */
  private calculateProjectStats(): void {
    this.cabinets$
      .pipe(
        first(), // As we invoke this manually we do first and then complete
        filter((cabinets: ProdboardCabinet[]) => Array.isArray(cabinets)),
        filter(() => !!this.project)
      ).subscribe({
      next: (cabinets: ProdboardCabinet[]) => {
        this.setProjectForm(this.project.form)
        this.project.receipt.length = 0
        const price = {
          price: 0,
          labor: 0,
          material: 0
        }

        /**
         * Iterate all cabinets and get base values
         */
        cabinets.forEach((cabinet: ProdboardCabinet) => {
          price.price += cabinet.price
          price.labor += cabinet.labor
          price.material += cabinet.material
        })

        /**
         * This is done before we emit to the comments service?!
         */
        this.project.comments.forEach((c: Comment) => {
          price.price += +c.price
          price.labor += +c.labor
          price.material += +c.material
          const receiptItem = new ReceiptItem(c.comment)
          receiptItem.source = 'comment'
          receiptItem.price = c.price
          receiptItem.labor = c.labor
          receiptItem.material = c.material
          this.project.receipt.push(receiptItem)
        })


        this.project.computed = {
          cabinets: cabinets.length,
          price
        }

        // This is the ONLY place where we _emit_ the project
        this.pProject$.next(this.project)
      }
    })
  }

  private runMigrations(project: IProject): void {
    /**
     * Migration for old images that was named "BLUEPRINT"??
     */

    project.images
      .filter((i: IProjectImage) => i.scope === 'BLUEPRINT' as any)
      .forEach((i: IProjectImage) => {
        i.scope = 'PROJECT'
      })
  }
}

import {CabinetOption, ViewOptionType} from './cabinet-option'
import {IProduct} from '../common/interface/product-types'
import {OptionValueNameItem} from '../services/override.service'
import {ProdboardCabinetOption} from '../services/prodboard-types'
import {
  COVER_CONSOLE_POS,
  COVER_EXTEND_POS,
  COVER_SIDE_CONSOLE_CONCAVE,
  COVER_SIDE_CONSOLE_CONVEX,
  COVER_SIDE_CONSOLE_NONE,
  COVER_SIDE_MIRROR,
  COVER_SIDE_NONE,
  COVER_SIDE_PLAIN,
  COVER_TYPE_POS
} from './model-types'


export class CoverSide extends CabinetOption {

  /**
   * Must be statically set _before_ constructor
   */
  public readonly optionSelectName = 'CoverSide'

  private sideSelection = {
    left: {sv: 'på vänster sida', en: 'left'},
    right: {sv: 'på höger sida', en: 'right'}
  }

  private readonly side: 'left' | 'right' = 'left'

  // No, Slät or mirror (coverType), option.value.name
  private type: string

  // extendCovers, option.value.options.[right]_panel.depth
  private extend = 0

  // console option.value.options.side_panels.[right_side].name
  private console: string

  constructor(option: ProdboardCabinetOption, product: IProduct, cabinetId: number, side: 'left' | 'right') {
    super(option, product, cabinetId)
    this.setNameAndOptions()

    this.type = COVER_SIDE_NONE
    // this.filler = ''
    this.side = side
    this.priority = 8
    this.viewOptions = [
      {
        type: ViewOptionType.select,
        title: 'Typ av täcksida',
        name: 'coverType',
        values: [COVER_SIDE_NONE, COVER_SIDE_PLAIN, COVER_SIDE_MIRROR],
        selection: COVER_SIDE_NONE,
        disabled: false,
      },
      {
        type: ViewOptionType.text,
        title: 'Förläng täcksidor (mm)',
        name: 'extendCovers',
        values: [],
        selection: '',
        disabled: false,
      },
      {
        type: ViewOptionType.select,
        title: 'Konsol',
        name: 'console',
        values: [COVER_SIDE_CONSOLE_CONVEX, COVER_SIDE_CONSOLE_CONCAVE, COVER_SIDE_CONSOLE_NONE],
        selection: COVER_SIDE_CONSOLE_NONE,
        disabled: false
      }
    ]
    this.active = false
    this.description = 'When the cabinet\'s side is visible, an endpanel is added'
    this.title = 'optCoverSide' + this.sideSelection[side].en.charAt(0).toUpperCase()
    this.sanitize(option)
    this.setPrice()
  }

  public update(data: any): void {
    super.update(data)
    this.setFromProperties(data)
    this.active = data.coverType !== COVER_SIDE_NONE
    this.setPrice()
  }

  public valueMap(): { [p: string]: string } {
    return {
      side: this.side
    }
  }

  protected getCustomCustomerListing(lc: string): string[] {
    const answer: string[] = []

    if (this.viewOptions[COVER_TYPE_POS].selection === COVER_SIDE_PLAIN) {
      answer.push(this.optionValueNames[0][lc])
    }

    if (this.viewOptions[COVER_TYPE_POS].selection === COVER_SIDE_MIRROR) {
      answer.push(this.optionValueNames[1][lc])
    }

    if (+this.viewOptions[COVER_EXTEND_POS].selection > 0 && lc !== 'sv') {
      answer.push(`${this.optionValueNames[2][lc]}: ${this.viewOptions[1].selection} mm`)
    }

    this.optionValueNames.forEach((o: OptionValueNameItem) => {
      if (this.viewOptions[COVER_CONSOLE_POS].selection === o.key) {
        answer.push(o[lc])
      }
    })
    answer.push(this.sideSelection[this.side][lc])

    return [answer.join(', ')]
  }

  private sanitize(option: ProdboardCabinetOption): ProdboardCabinetOption {

    if (!option.value) {
      option.value = {} as any
    }

    // Type - We 'assume" this is always present
    this.type = option.value.name
    if (this.type && this.type !== COVER_SIDE_NONE) {
      this.viewOptions[COVER_TYPE_POS].selection = this.type
      this.active = true
    }

    if (!option.value.options) {
      option.value.options = {} as any
    }

    // Extend covers value
    if (!option.value.options[`${this.side}_panel`]) {
      option.value.options[`${this.side}_panel`] = {} as any
    }

    /**
     * Old JSON had "depth" new JSON has offset.
     */
    let marker = 'depth'
    if (option.value.options[`${this.side}_panel`].offset) {
      marker = 'offset'
    }

    if (option.value.options[`${this.side}_panel`][marker]) {
      this.extend = option.value.options[`${this.side}_panel`][marker]
      this.viewOptions[COVER_EXTEND_POS].disabled = false
    }

    // Fillers
    if (!option.value.options[`rec_side_${this.side}`]) {
      option.value.options[`rec_side_${this.side}`] = {} as any
    }

    // Console
    if (!option.value.options.side_panels) {
      option.value.options.side_panels = {} as any
    }

    if (!option.value.options.side_panels[`${this.side}_side`]) {
      option.value.options.side_panels[`${this.side}_side`] = {} as any
    }

    if (option.value.options.side_panels[`${this.side}_side`].name) {
      this.console = option.value.options.side_panels[`${this.side}_side`].name
      this.active = true
    }

    this.viewOptions[1].selection = this.extend + ''

    this.viewOptions[COVER_CONSOLE_POS].selection = this.console || COVER_SIDE_CONSOLE_NONE

    return option
  }

  private setPrice(): void {
    this.resetPrices()

    if (this.viewOptions[COVER_TYPE_POS].selection === COVER_SIDE_PLAIN) {
      this.price = this.product.enPaPr.price / 2
      this.labor = this.product.enPaPr.labor / 2

      const item = this.addReceiptItem()
      item.itemText = 'Täcksida slät'
      item.priceProperty = 'enPaPr'

      this.shouldHavePrice = true
    }

    if (this.viewOptions[COVER_TYPE_POS].selection === COVER_SIDE_MIRROR) {
      this.price = this.product.enPaPr.price
      this.labor = this.product.enPaPr.labor
      const item = this.addReceiptItem()
      item.itemText = COVER_SIDE_MIRROR
      item.priceProperty = 'enPaPr'
      this.shouldHavePrice = true
    }

    if (this.viewOptions[COVER_CONSOLE_POS].selection !== COVER_SIDE_CONSOLE_NONE) {
      this.price = this.product.coDeEnPaPr.price
      this.labor = this.product.coDeEnPaPr.labor
      const item = this.addReceiptItem()
      item.itemText = this.viewOptions[COVER_CONSOLE_POS].selection
      item.priceProperty = 'coDeEnPaPr'
      this.shouldHavePrice = true
    }
    this.addPriceFromComments()
  }
}

import {Inject, Injectable} from '@angular/core'
import {BehaviorSubject, Observable, of} from 'rxjs'
import {LOCAL_STORAGE} from '../application/localstorage.provider'
import {CognitoIdToken} from 'amazon-cognito-identity-js'
import {CognitoService} from './cognito.service'
import {environment} from '../../environments/environment'
import {EAuthState} from '../common/interface/auth'
import {first, map, switchMap, tap} from 'rxjs/operators'
import {KulladalUser, UserService} from './user.service'

export interface IAuthInfo {

  /**
   * The "access token" that we send with requests to API gw.
   */
  accessToken?: string

  /**
   * The current state, see IAuthState
   */
  state: EAuthState

  /**
   * Random data depending on state.
   */
  data?: any

  /**
   * Error information, if any
   */
  error?: any

  /**
   * The complete user, with roles and everything
   * This is populated by the AuthService as soon as
   * we (potentially) have authenticated.... ?
   */
  user?: KulladalUser
}

/**s
 * Abstracts the Authentication stuff for the rest of the application.
 */
@Injectable({
  providedIn: 'root'
})
export class AuthService {

  /**
   * Should be a subscription-able string of the login state
   */
  public authState$: Observable<IAuthInfo>

  /**
   * Internal representation that kan have next etc.
   */
  private pAuthState$ = new BehaviorSubject<IAuthInfo>({
    state: EAuthState.start
  })

  constructor(
    @Inject(LOCAL_STORAGE) private injectedLocalStorage: Storage,
    private cognitoService: CognitoService,
    private userService: UserService
  ) {
    this.authState$ = this.pAuthState$.asObservable()
  }

  public checkLogin(): Observable<boolean> {
    // Check if we have the data stored, if so we try to log 'em in.
    const username = this.injectedLocalStorage
      .getItem(`CognitoIdentityServiceProvider.${environment.clientId}.LastAuthUser`)
    const user = this.cognitoService.getExistingUser(username)

    // If cognito reports a user we try to refresh tokens for it
    if (user) {
      return this.updateStateWithToken()
    }
    // Else we just resolve and let us end up on login.
    return of(false)
  }

  public login(username: string, password: string): Observable<EAuthState> {
    return this.cognitoService.login(username, password).pipe(
      map((state: EAuthState) => {
          if (state === EAuthState.error) {
            this.pAuthState$.next({
              state,
              error: 'wrongPassUser'
            })
          }
          if (state === EAuthState.newPassword) {
            this.pAuthState$.next({
              state,
            })
          }
          if (state === EAuthState.resetPassword) {
            this.pAuthState$.next({
              state,
            })
          }
          if (state === EAuthState.pendingUser) {
            this.updateStateWithToken().subscribe()
          }
          return state
        }
      ))
  }

  /**
   * Send logout to cognito and re-initialize the auth loop.
   */
  public logout(): Observable<void> {
    return this.cognitoService.logout().pipe(
      map(() => {
        this.pAuthState$.next({
          state: EAuthState.start,
          accessToken: undefined,
          user: undefined
        })
      })
    )
  }

  /**
   * When set password succeeeds the user is logged in.
   *
   * @param newPassword
   */
  public setPassword(newPassword: string): Observable<boolean> {
    return this.cognitoService.setPassword(newPassword).pipe(
      switchMap((res: EAuthState) => {
          if (res === EAuthState.authenticated) {
            return this.updateStateWithToken()
          }
          this.pAuthState$.next({
            state: EAuthState.error,
            error: 'noPass'
          })
          return of(false)
        }
      )
    )
  }

  public resetPassword(username: string, newPassword: string, code: string): Observable<any> {
    return this.cognitoService.confirmPassword(newPassword, code).pipe(
      switchMap((res: EAuthState) => {
        // Cognito service
        if (res === EAuthState.passwordUpdated) {
          return this.login(username, newPassword)
        }
        this.pAuthState$.next({
          state: EAuthState.error,
          error: 'noPass'
        })
        return of(false)
      })
    )
  }

  public forgotPassword(username: string): void {
    this.cognitoService.forgotPassword(username).subscribe({
      next: (res: EAuthState) => {
        if (res === EAuthState.passwordUpdated) {
          return this.pAuthState$.next({
            state: EAuthState.resetPassword
          })
        }
        this.pAuthState$.next({
          state: EAuthState.error,
          error: 'noPass'
        })
      }
    })
  }

  private updateStateWithToken(): Observable<boolean> {
    const user = this.cognitoService.user
    return this.cognitoService.getExistingSession(user)
      .pipe(
        switchMap((res: CognitoIdToken | boolean) => {
          if (res === false) {
            this.pAuthState$.next({
              state: EAuthState.start
            })
            return of(res as boolean)
          } else {
            this.pAuthState$.next({
              state: EAuthState.pendingUser,
              accessToken: this.cognitoService.getUserJwt(res as CognitoIdToken)
            })
            return this.getUserSelfForFun()
          }
        }))
  }

  /**
   * Whenever we think we want to, we go fetch the user...
   *
   */
  private getUserSelfForFun(): Observable<boolean> {
    let user: KulladalUser
    return this.userService.getUserSelf()
      .pipe(
        tap((self: KulladalUser) => user = self),
        switchMap(() => this.pAuthState$),
        first(), // Take just one, because we will emit here just after!
        map((state: IAuthInfo) => {
          state.state = EAuthState.authenticated
          state.user = user
          this.pAuthState$.next(state)
          return true
        })
      )
  }
}

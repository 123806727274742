import {IProduct} from '../common/interface/product-types'
import {CabinetOption, ViewOptionType} from './cabinet-option'
import {ProdboardCabinetOption} from '../services/prodboard-types'

export class DoorType extends CabinetOption {

  /**
   * Must be statically set _before_ constructor
   */
  public readonly optionSelectName = 'DoorType'

  private readonly prices = [
    {
      name: '1. Överfalsad profilerad lucka',
      price: 400,
    },
    {
      name: '2. Innanförliggande profilerad lucka',
      price: 200,
    },
    {
      name: '3. Innanförliggande shakerlucka',
      price: 0,
    },
  ]

  constructor(option: ProdboardCabinetOption, product: IProduct, cabinetId: number) {
    super(option, product, cabinetId)
    this.setNameAndOptions()
    this.priority = 10
    this.viewOptions = [
      {
        type: ViewOptionType.select,
        title: 'Lucktyp',
        name: 'typeDoor',
        values: [
          '0. Ej specificerat',
          '1. Överfalsad profilerad lucka',
          '2. Innanförliggande profilerad lucka',
          '3. Innanförliggande shakerlucka',
          'Door style 4'],
        selection: '',
        disabled: false
      }
    ]
    this.active = true
    this.description = 'Decides what kind of door style the cabinet has - there are 5 different styles'
    this.title = 'optDoorType'

    const sOption = DoorType.sanitize(option)
    this.setValuesFromProdboardData(sOption)
    this.product.nuDo = this.product.nuDo || 0
    this.product.nuDr = this.product.nuDr || 0
    this.setPrice()
  }

  private static sanitize(option: ProdboardCabinetOption): ProdboardCabinetOption {
    option.collection = option.collection || {} as any
    option.collection.name = option.collection.name || ''
    return option
  }

  public update(data: any) {
    super.update(data)
    this.active = true
    this.setFromProperties(data)
    if (this.product.nuDo + this.product.nuDr < 1) {
      this.active = false
      this.viewOptions[0].selection = '0. Ej specificerat'
    }
    this.setPrice()
  }

  protected getCustomCustomerListing(lc: string): string[] {
    return this.getCustomerListingByKey(lc, this.viewOptions[0].selection)
  }

  private setValuesFromProdboardData(option: any) {
    this.viewOptions[0].selection = option.collection.name

    if (!this.product.nuDo && !this.product.nuDr) {
      this.active = false
      this.viewOptions[0].selection = '0. Ej specificerat'
    }
  }

  private setPrice(): void {
    this.resetPrices()
    const findPrice = this.prices.find((price) => price.name === this.viewOptions[0].selection) || {price: 0}
    this.price = findPrice.price * (this.product.nuDo + this.product.nuDr)
    if (findPrice.price > 0) {
      const item = this.addReceiptItem()
      item.priceProperty = 'nuDo || nuDr'
      item.itemText = this.viewOptions[0].selection
      this.shouldHavePrice = true
    }
    this.addPriceFromComments()
  }
}

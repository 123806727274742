import {
  cRecessMap,
  IDefaultFrameAndRecessItem,
  IProduct,
  STANDARD_SKIRTING,
  TRecessSkirtingType
} from '../common/interface/product-types'
import {Comment, CommentHomeEntity, Comments} from '../comments/model/comment'
import {FactoryData} from './factory/factory-data'
import {ReceiptItem} from './receipt/receipt-item'
import {Problem} from '../services/problem.service'
import {OptionValueNameItem} from '../services/override.service'
import {getDefaultOptionNameValue} from '../services/override.defaults'
import {Skirting} from './skirting'
import {Subject} from 'rxjs'
import {FrameWidth} from './frame-width/frame-width'
import {ProdboardCabinetOption} from '../services/prodboard-types'

/**
 * This is used to define the type of view option. Basically the
 * same that are available in any form. The "enum" thing is just
 * to avoid typos, nothing more nothing less.
 */
export enum ViewOptionType {
  binary,
  text,
  select,
}


export interface ViewOption {
  /**
   * This is a name, like "drawer", it will be used as a "property"
   * on an object so please select names carefully.
   */
  name: string

  /**
   *  This is what we display on the form item. It should be human-readable
   *  in Swedish. We have to think about translations later.
   */
  title: string

  /**
   * Values is always an array. If it is a simple input it is just one.
   * It could be [ 'Ja', 'Nej' ] for a radio. It should be in Swedish until
   * we have decided on translation magix
   */
  values: string[]

  /**
   * See the type, currently we support binary (on/off), text and select.
   * I guess next level is checkbox. Are there more?
   */
  type: ViewOptionType

  /**
   * This is the value that is currently selected. Depending
   * on complexity the class will handle this in various ways.
   */
  selection: string

  /**
   * If this option should be read only.
   */
  disabled: boolean
}

export type TOptionSelectName =
  'BackPanel'
  | 'BrassPlate'
  | 'CarpenterJoy'
  | 'CenterPost'
  | 'CombinedUnit'
  | 'Cornice'
  | 'CoverSide'
  | 'CuttingBoard'
  | 'Door'
  | 'DoorAttachment'
  | 'DoorType'
  | 'DrawerDoor'
  | 'DrawerFront'
  | 'DrawerInsert'
  | 'FanAdoption'
  | 'Filler'
  | 'FrameWidth'
  | 'HandleDoor'
  | 'HandleDrawer'
  | 'Hanging'
  | 'HiddenDrawer'
  | 'HiddenDrawerSink'
  | 'HiddenVisibleWidth'
  | 'Hinges'
  | 'Legs'
  | 'Lighting'
  | 'PaintProcess'
  | 'PaintSide'
  | 'Plinth'
  | 'Scribings'
  | 'Shelves'
  | 'ShelvesAdjustable'
  | 'Skirting'
  | 'SpiceRack'
  | 'UnknownOption'

export class CabinetOption implements Comments {

  public commentHome: CommentHomeEntity = {type: 'OPTION', id: ''}
  /**
   * This is used to identify the option in the override
   */
  public readonly optionSelectName: TOptionSelectName

  /**
   * new entities for displaying the item translated
   */
  public description: string
  public title: string
  /**
   * Price is price to end customer.
   */
  public price = 0

  /**
   * This is what we pay when ordering it.
   */
  public labor = 0

  /**
   * A few options have extra metarial cost.
   */
  public material = 0

  /**
   * Let us put price items in a receipt
   */
  public readonly receipt: ReceiptItem[] = []

  /**
   * Name is a unique short combo like PaintProcess_1
   * it should not ever be used for display purposes
   */
  public name = 'You forgot to set this!'

  /**
   * This is used to sort options only.
   *
   * If not active it will be given the lowest priority
   *
   */
  public active = false

  /**
   * Default priority is low. Depending on stuff an option
   * can give itself higher or lower priority. This is only used
   * to control the ordering of options.
   */
  public priority = 100

  /**
   * This is an array of options to use in forms to display/change
   */
  public viewOptions: ViewOption[] = [{} as any]

  public comments: Comment[] = []

  /**
   * We do not want to import the problem service so, we
   * populate this list with problems and let someone else
   * iterate it.
   */
  public problems: Problem[] = []

  /**
   * Names and translations for this option name.
   */
  public optionValueNames: OptionValueNameItem[]

  public valueChanges$ = new Subject<boolean>()

  /**
   * This is for the warning system to be able to throw
   * a warning if an option have a price of 0 when it shouldn't
   */
  public shouldHavePrice = false

  /**
   * This is the uid of the cabinet this is a future replacement
   * for "index" that we have all over the place.
   *
   * When introduced this was pushed to the option on instantiation
   * in the prodboard cabinet when doing comments refactoring
   */
  public cabinetId: string

  /**
   * An attempt to give uuid to all options?s
   */
  public id: string

  public constructor(
    protected option: ProdboardCabinetOption,
    protected product: IProduct, // This is the product from the product database.
    public cabinetIndex: number
  ) {

    // Let us make sure there is always prices present
    this.product = Object.assign(
      {
        pr: {},
        enPaPr: {},
        baPaPr: {},
        cuDiPr: {},
        knBlPr: {},
        waBlPr: {},
        glDoPr: {},
        exShPr: {},
        hiDrPr: {},
        liPr: {},
        noDoPr: {},
        cuBoCoPr: {}, // Carpenter Joy
        cuBoPr: {}, // Cutting board (utdragbar skärbräda)
        adShPr: {},
        shIdPr: {price: 0, labor: 0, material: 0}, // Shelves inside door
        coDeEnPaPr: {price: 0, labor: 0} // Console decorations
      },
      this.product
    )
    this.setNameAndOptions()
  }

  /**
   * This is a half-miserable sorting function to sort options based
   * on priority and active.
   *
   * It sorts the ACTIVE options and the non-active then returns the
   * concatenated result.
   */
  public static sortOptions(options: CabinetOption[]): CabinetOption[] {
    const active = options.filter((o) => o.active)
    const inActive = options.filter((o) => !o.active)
    active.sort(CabinetOption.sort)
    inActive.sort(CabinetOption.sort)
    return active.concat(inActive)
  }

  private static sort(a: CabinetOption, b: CabinetOption): number {
    if (a.priority > b.priority) {
      return 1
    } else {
      return a.priority === b.priority ? 0 : -1
    }
  }

  /**
   * Since the "cabinet" is just referenced by "index" we add this
   * method. It is called by the cabinet after the option is instantiated
   * All in one go or one by one.
   *
   * @param id - The holding cabinet uid
   */
  public setCommentHomeId(id: string): void {
    this.commentHome.id = `${id}_${this.name}`
  }

  public getOptionMap(): any {
    const res: any = {}
    this.viewOptions.forEach((opt: ViewOption) => {
      res[opt.name] = opt.selection
    })
    return res
  }

  /**
   * This works just fine when the Option impl has set its
   * optionValueNames array. However not all options do!
   *
   * We should revisit all options and make sure it is set.
   *
   * @param lc - Swedish or English
   */
  public getOption(lc: string = 'sv'): string {
    const defaultName = `${this.name} has not implemented getOption(lc = ${lc})`
    return this.optionValueNames
      .reduce((optionName: string, value: OptionValueNameItem) =>
        value.key === this.viewOptions[0].selection ? value[lc] : optionName, defaultName)
  }

  /**
   * This is used by the matrix view and import when showing the
   * complete "config" as a one-liner.
   *
   */
  public createOptionsSummary(): string {
    return this.viewOptions.map((option) =>
      option.selection
    ).join(' ')
  }

  /**
   * This is used by the matrix view and import when showing the
   * complete "config" as a one-liner.
   *
   */
  public createOptionsSummaryLong(): string {
    return this.viewOptions.map((option) =>
      `${option.title}: ${option.selection}`
    ).join(' ♢ ')
  }

  /**
   * All cabinet options must be able to get updated data
   * from the outside. This is on the "base" class so that
   * we can work generally.
   *
   * Resets problems and sets comments, nothing else
   */
  public update(options: any): void {
    // Reset the problem list
    this.problems.length = 0
    if (options.comments) {
      this.comments = options.comments
    }
  }

  public addPriceFromComments(): void {
    this.comments.forEach((comment: Comment) => {
      this.price += comment.price
      this.material += comment.material
      this.labor += comment.labor
      const item = new ReceiptItem(`KOMMENTAR: ${comment.comment}`, this.cabinetIndex, this.name)
      item.price = comment.price
      item.labor = comment.labor
      item.material = comment.material
      item.source = 'comment'
      this.receipt.push(item)
    })
  }

  /**
   * All options should be able to return the
   * actual production values, these come in various
   * shapes and forms.
   */
  public getFactoryData(): FactoryData {
    // This should be implemented for CAD later.
    return {}
  }

  /**
   *
   */
  public getCustomerListing(type: 'customer' | 'factory'): string[] {
    return this.getCustomCustomerListing(type === 'customer' ? 'sv' : 'en')
  }

  /**
   * A per option value map with "core" values. Introduced for
   * the cad export. Basically to avoid variable texts.
   */
  public valueMap(): { [key: string]: string | number | boolean } {
    return {not: 'implemented'}
  }

  /**
   * Resolves the default bottom and top recess and frame widths. Public
   * for testing purposes only
   *
   * @param product - Should have the rct value set
   * @param skirting - Should be outside or standard.
   * @param frame - The frame is needed to check the specials
   */
  public getDefaultRecess(product: IProduct, skirting: Skirting, frame: FrameWidth): IDefaultFrameAndRecessItem {
    const skirtType: TRecessSkirtingType = skirting.viewOptions[0].selection === STANDARD_SKIRTING ? 'standard' : 'outside'
    const recessType = product.rct
    if (cRecessMap[recessType]) {
      if (['wall-exc', 'wall'].indexOf(recessType) !== -1) {
        const res: IDefaultFrameAndRecessItem = {bf: 0, br: 0, tf: 0, tr: 0}
        Object.assign(res, cRecessMap[recessType][skirtType])
        const bottomRecess = frame.bottom - 20
        res.br = bottomRecess >= 0 ? bottomRecess : 0
        return res as any
      }
      return Object.assign({}, cRecessMap[recessType][skirtType])
    } else {
      this.problems.push({description: `Produkten ${product.pc} har ingen Recess Cabinet Type`, handled: false})
      return {bf: 0, br: 0, tf: 0, tr: 0}
    }
  }

  protected getCustomCustomerListing(lc: string): string[] {
    return [this.optionValueNames[0][lc]]
  }

  /**
   * Helper function to fetch the listing by the key name, see e.g. Door/Drawer
   *
   * @param lc - The language code
   * @param key - The key to match
   */
  protected getCustomerListingByKey(lc: string, key: string): string[] {
    const answer = []
    this.optionValueNames.forEach((option: OptionValueNameItem) => {
      if (option.key === key) {
        answer.push(option[lc])
      }
    })
    return answer
  }

  protected setNameAndOptions(): void {
    this.optionValueNames = getDefaultOptionNameValue(this.optionSelectName)
    this.name = `${this.optionSelectName}_${this.option.count}`
  }

  /**
   * Set the values (selection) of viewOptions, assuming that the viewOptions come in the
   * order of the properties...
   *
   * @param input - An object with keys matching the properties in the viewOptions
   */
  protected setFromProperties(input: { [key: string]: any }): void {
    this.viewOptions
      .map((o: ViewOption) => o.name)
      .forEach((prop, index) => {
        if (input[prop]) {
          this.viewOptions[index].selection = input[prop]
        }
      })
  }

  /**
   * Set the basic data and push the item to the receipt
   *
   * Before calling this method make sure you have
   * a) A clean receiptlist
   * b) Set your prices accordingly
   *
   */
  protected addReceiptItem(): ReceiptItem {
    const priceItem = new ReceiptItem(this.title, this.cabinetIndex, this.name)
    priceItem.price = this.price
    priceItem.labor = this.labor
    priceItem.material = this.material
    this.receipt.push(priceItem)
    return priceItem
  }

  /**
   * Simple helper to make sure we reset all price info.
   */
  protected resetPrices(): void {
    this.price = 0
    this.material = 0
    this.labor = 0
    // Reset the array, do not replace it!
    this.receipt.length = 0
    this.shouldHavePrice = false
  }

  /**
   * Make sure the selection is a string report a problem if not.
   * See Hanging for an example.
   *
   * @param selection - Anything that you expect to be a string.
   */
  protected verifySelectionOption(selection: string): string {
    if (!selection) {
      this.problems.push({
        description: `Searching for value ${this.name} but found none ${this.cabinetIndex}`,
        handled: false
      })
      return ''
    }
    return selection
  }
}

import {IProduct} from '../common/interface/product-types'
import {CabinetOption, ViewOptionType} from './cabinet-option'
import {ProdboardCabinetOption} from '../services/prodboard-types'
import {
  LINSEED_BASE,
  LINSEED_COMPLETE,
  NO_PAINT,
  NO_PAINT_WAXED_INSIDE,
  ORDINARY_PAINT_COMPLETE,
  ORDINARY_PAINT_BASE
} from './model-types'
import {TPaintProcessValues} from '../services/project-types'

/**
 * All the below b/c we have fucked up in project form
 * that uses a number (1..5) for selection while this one
 * uses "strings".
 */
const selections = [
  LINSEED_COMPLETE, // 1
  LINSEED_BASE, // 2
  ORDINARY_PAINT_COMPLETE,
  NO_PAINT_WAXED_INSIDE, // 4
  NO_PAINT, // 5
  ORDINARY_PAINT_BASE // 6
]

const selectionList = ['', LINSEED_COMPLETE, LINSEED_BASE, ORDINARY_PAINT_COMPLETE, NO_PAINT_WAXED_INSIDE, NO_PAINT, ORDINARY_PAINT_BASE]
export const selectionToName = (selection: TPaintProcessValues): string => selectionList[selection]

export class PaintProcess extends CabinetOption {
  /**
   * Must be statically set _before_ constructor?
   */
  public readonly optionSelectName = 'PaintProcess'

  /**
   * Fultypar this since I do not have the energy
   */
  private reductionMap = new Map<string, { price: number; labor: number }>(
    [
      [LINSEED_COMPLETE, {price: 0, labor: 0}],
      [LINSEED_BASE, {price: 5, labor: 0}],
      [ORDINARY_PAINT_COMPLETE, {price: 0, labor: 0}],
      [NO_PAINT_WAXED_INSIDE, {price: 8, labor: 8}],
      [ORDINARY_PAINT_BASE, {price: 12, labor: 12}],
      [NO_PAINT, {price: 5, labor: 0}]
    ]
  )

  constructor(option: ProdboardCabinetOption, product: IProduct, cabinetId: number) {
    super(option, product, cabinetId)
    this.setNameAndOptions()
    this.priority = 10
    this.viewOptions = [
      {
        type: ViewOptionType.select,
        title: 'Målningsprocess',
        name: 'paintProcess',
        values: selections,
        selection: '',
        disabled: false
      }
    ]
    this.description = 'Decides if the cabinet should be fully painted, or just primered, etc'
    this.title = 'optPaintProcess'

    const sOption = PaintProcess.sanitize(option)
    this.setValuesFromProdboardData(sOption)
    this.setPrice()
  }

  private static sanitize(option: ProdboardCabinetOption): ProdboardCabinetOption {
    option.value = option.value || {} as any
    option.value.name = option.value.name || NO_PAINT_WAXED_INSIDE
    return option
  }

  public update(data: any): void {
    super.update(data)
    this.setFromProperties(data)
    this.setPrice()
  }

  public paintProcess(): TPaintProcessValues {
    return selectionList.indexOf(this.viewOptions[0].selection) as TPaintProcessValues
  }

  /**
   * Returns the _name_ of the selection to be used in
   * the override defaults
   */
  public paintProcessSelection(): string {
    return this.viewOptions[0].selection
  }

  private setValuesFromProdboardData(option: ProdboardCabinetOption): void {
    this.viewOptions[0].selection = option.value.name
    this.active = true
  }

  private setPrice(): void {
    this.resetPrices()
    const reductions = this.reductionMap.get(this.viewOptions[0].selection)
    // The discount is n % of cabinet price, but since this is a deduction we multiply with -1
    this.price = Math.trunc((this.product.pr.price * reductions.price) / 100) * -1
    this.labor = Math.trunc((this.product.pr.labor * reductions.labor) / 100) * -1
    if (reductions.price !== 0) {
      const item = this.addReceiptItem()
      item.priceProperty = 'calculated'
      item.itemText = `${this.viewOptions[0].selection} =>  reducerat pris med ${this.price}`
      this.shouldHavePrice = true
    }

    this.addPriceFromComments()
  }
}

<div *ngIf="projectService.currentProject$ | async; let project" class="stuff">
  <div class="heading">Prodboard</div>
  <div class="info-item">
    <div class="info-title" kdlI18n label="name"></div>
    <div class="info-value">{{project.customer.name}}</div>
  </div>
  <div class="info-item">
    <div class="info-title" kdlI18n label="phone"></div>
    <div class="info-value">{{project.customer.phone}}</div>
  </div>
  <div class="info-item">
    <div class="info-title"><a [href]="project.customer.url" kdlI18n label="openInProdboard" target="_blank"></a></div>
    <div class="info-value"></div>
  </div>
  <div class="info-item">
    <div class="info-title" kdlI18n label="prodboardId"></div>
    <div class="info-value">{{project.customer.prodboardId}}</div>
  </div>
  <div class="info-item">
    <div class="info-title" kdlI18n label="prodboardCode">Prodboard-kod:</div>
    <div class="info-value">{{project.customer.prodboardNumber}}</div>
  </div>
</div>

import {IProduct} from '../common/interface/product-types'
import {CabinetOption, ViewOptionType} from './cabinet-option'
import {ProdboardCabinetOption} from '../services/prodboard-types'

export class Hanging extends CabinetOption {

  /**
   * Must be statically set _before_ constructor
   */
  public readonly optionSelectName = 'Hanging'

  private readonly selectionOptions = {
    doors0: {
      oddSide: {},
      sameSide: {}
    },
    doors1: {
      oddSide: {
        undef: 'FEL',
        top: 'Upptill',
        left: 'Vänster',
        right: 'Höger'
      }
    },
    doors2: {
      sameSide: {
        left: 'Vänster-Vänster',
        right: 'Höger-Höger'
      },
      oddSide: {
        undef: 'Vänster-Höger',
        top: 'Upptill',
        left: 'Vänster-Höger',
        right: 'Vänster-Höger'
      }
    },
    doors3: {
      oddSide: {
        undef: 'FEL',
        top: 'Upptill',
        left: 'Vänster-Vänster-Höger',
        right: 'Vänster-Höger-Höger'
      }
    },
    doors4: {
      oddSide: {
        undef: 'Vänster-Höger',
        left: 'Vänster-Höger x2',
        right: 'Vänster-Höger x2'
      },
      sameSide: {
        undef: 'Upptill',
        left: 'Vänster-Vänster x2',
        right: 'Höger-Höger x2'
      }
    },
    doors6: {
      sameSide: {
        undef: 'FEL',
        left: 'Vänster-Vänster-Höger x 2',
        right: 'Vänster-Höger-Höger x2'
      }
    }
  }

  constructor(option: ProdboardCabinetOption, product: IProduct, cabinetId: number) {
    super(option, product, cabinetId)
    this.setNameAndOptions()

    this.priority = 10
    this.viewOptions = [
      {
        type: ViewOptionType.select,
        title: 'Hängning',
        name: 'hanging',
        values: ['Vänster', 'Höger', 'Vänster-Höger', 'Vänster-Vänster', 'Höger-Höger', 'Vänster-Vänster-Höger',
          'Vänster-Höger-Höger', 'Upptill', 'Nertill', 'Vänster-Höger x2', 'Vänster-Vänster-Höger x2', 'Vänster-Höger-Höger x2', 'FEL'],
        selection: '',
        disabled: false
      }
    ]
    this.active = true

    this.description = 'Describes if the door is left, right or top hinged'
    this.title = 'optHanging'

    const sOption = Hanging.sanitize(option)
    this.setValuesFromProdboardData(sOption)
    this.addPriceFromComments()
  }

  private static sanitize(option: ProdboardCabinetOption): ProdboardCabinetOption {
    option.value = option.value || {} as any
    option.value.options = option.value.options || {} as any
    option.value.options.open = option.value.options.open || {} as any
    option.value.options.open.type = option.value.options.open.type || undefined

    return option
  }

  public update(data: any) {
    super.update(data)
    this.resetPrices()
    this.setFromProperties(data)
    this.addPriceFromComments()
  }

  protected getCustomCustomerListing(lc: string): string[] {
    const selectedOption = this.verifySelectionOption(this.viewOptions[0].selection).replace(/[åäö\s]/g, '_').toLowerCase()
    return this.getCustomerListingByKey(lc, selectedOption)
  }

  private setValuesFromProdboardData(option: ProdboardCabinetOption): void {
    const numDoors = 'doors' + this.product.nuDo
    let hangingSide = 'oddSide'
    const sameSideHangingCheck = this.product.pc.indexOf('x2')

    if (sameSideHangingCheck !== -1) {
      hangingSide = 'sameSide'
    }

    if (option.value.options.open.type === undefined) {
      this.viewOptions[0].selection = this.selectionOptions[numDoors][hangingSide].undef
    }

    if (option.value.options.open.type !== undefined) {
      this.viewOptions[0].selection = this.selectionOptions[numDoors][hangingSide][option.value.options.open.type.code]
    }
  }
}

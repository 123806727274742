import {FactoryData, IFDFillers, IFDFrame} from '../../model/factory/factory-data'
import {ProdboardCabinet} from '../../model/cabinet/prodboard-cabinet'
import {ProjectOption, SummaryProperties} from '../../model/project-option/project-option'
import {IComment} from '../../comments/model/comment'
import {IProjectImage} from '../../images/model/project-image'

export class FactoryCabinet {

  public cat: string

  public description: string

  public index: number

  public uid: string

  public option: ProjectOption = {} as any

  public hasFrame = false

  public productComment: string | undefined

  public counterTopUid = ''

  public images: IProjectImage[] = []

  private centerPost: number

  private pHeight: number

  private pWidth: number

  /**
   * Hidden width is only available on corner cabinets.
   */
  private pHiddenWidth = 0

  private pVisibleWidth = 0

  private pDepth: number

  private fillers: IFDFillers = {} as any

  private pFrame: IFDFrame = {} as any

  private pScribings = ''

  private comments: IComment[] = []


  constructor(public cabinet: ProdboardCabinet) {
    this.setValuesFromCabinet(cabinet)
    this.option = new ProjectOption([cabinet], 'en')
    this.productComment = cabinet.productComments.en
  }

  /**
   * A is equal to width
   */
  get a(): number {
    return this.width
  }

  get width(): number {
    return this.pWidth
  }

  /**
   * Frame width left
   */
  get b(): number {
    return this.getFrameSide('left')
  }

  /**
   * Filler left side
   */
  get c(): number {
    return this.fillers.left || 0
  }

  /**
   * Right side frame
   */
  get d(): number {
    return this.getFrameSide('right')
  }

  /**
   * Filler right side
   */
  get e(): number {
    return this.fillers.right || 0
  }

  /**
   * Hole width, calculated
   */
  get f(): number {
    return (this.pVisibleWidth - this.getFrameSide('left') - this.getFrameSide('right'))
  }

  /**
   * The cabinet full height
   */
  get g(): number {
    return this.height
  }

  get height(): number {
    return this.pHeight
  }

  /**
   * Frame top
   */
  get h(): number {
    return this.getFrameSide('top')
  }

  /**
   * Frame bottom
   */
  get i(): number {
    return this.getFrameSide('bottom')
  }

  /**
   * Door/drawer hole height
   */
  get j(): number {
    // Dishwashers should always be presented as 870 mm
    if (this.cabinet.isDishwasherCabinet) {
      return 870
    }
    /**
     * We should fix this in the Master. This value is only applicable for [B]ase
     * and [T]all cabinets
     */
    if (this.cat[0] === 'B' || this.cat[0] === 'T') {
      return (this.pHeight - this.getFrameSide('top') - this.getFrameSide('bottom') + this.m)
    }
    return (this.pHeight - this.getFrameSide('top') - this.getFrameSide('bottom'))
  }

  /**
   * Cabinet depth
   */
  get k() {
    return this.pDepth
  }

  /**
   * L and M are top and bottom recess values.
   */
  get l(): number {
    return this.fillers.top || 0
  }

  get m(): number {
    return this.fillers.bottom || 0
  }

  /**
   * N - Visible width, either the real width or the visible width.
   */
  get n(): number {
    return this.pVisibleWidth
  }

  /**
   * O (oh) - Hidden width, only applicable for corner cabinets. If > 0 it must be
   * a corner cabinet.
   */
  get o(): number {
    return this.pHiddenWidth
  }

  get importantNotes(): string[] {
    return this.comments.map((comment: IComment) => comment.translation)
      .concat(this.cabinet.checkIfPaintedInsideNote())
  }

  get frame(): IFDFrame {
    return this.pFrame
  }

  set frame(frame: IFDFrame) {
    this.pFrame = frame
  }

  /**
   * Here comes the options getters, they have to come here
   * b/c of es-lint.
   */
  public getDoors(): SummaryProperties[] {
    return this.option.getDoors()
  }

  public getHinges(): SummaryProperties[] {
    return this.option.getHinges()
  }

  public getCoverSides(): SummaryProperties[] {
    return this.option.getCoverSides()
  }

  public getSkirting(): SummaryProperties[] {
    return this.option.getSkirting()
  }

  public getPaints(): SummaryProperties[] {
    return this.option.getPaints()
  }

  public getShelves(): SummaryProperties[] {
    return this.option.getShelves()
  }

  public getCenterPost(): SummaryProperties[] {
    return this.option.getCenterPost()
  }

  public scribings(): string {
    return this.pScribings
  }

  private setValuesFromCabinet(cabinet: ProdboardCabinet): FactoryCabinet {
    cabinet.dimensions = cabinet.dimensions || {x: 0, y: 0, z: 0}

    this.index = cabinet.index
    this.uid = cabinet.uid
    this.cat = cabinet.cat
    this.description = cabinet.description
    this.pWidth = cabinet.dimensions.x
    this.pHeight = cabinet.actualHeight
    this.pDepth = cabinet.dimensions.z

    this.pVisibleWidth = cabinet.visibleWidth || this.pWidth
    this.pHiddenWidth = cabinet.hiddenWidth

    this.centerPost = 30

    cabinet.options.forEach((option) => {
      const data: FactoryData | null = option.getFactoryData()
      if (data !== null) {
        Object.assign(this, data)
      }
    })
    this.hasFrame = Object.values(this.pFrame)
      .reduce((s: number, val: number) => s + val, 0) > 0

    this.pScribings = this.cabinet.getScribings()

    this.counterTopUid = this.cabinet.counterTopId

    this.setImportantNotes()

    return this
  }

  private getFrameSide(side: string): number {
    return this.pFrame[side]
  }

  private setImportantNotes(): void {
    this.comments = this.cabinet.comments.filter((comment) => comment.translation)
    this.comments.unshift({translation: this.productComment} as any)
    this.comments = this.comments.filter((c: IComment) => c.translation)
  }
}

import {CabinetOption, ViewOptionType} from './cabinet-option'
import {IProduct} from '../common/interface/product-types'
import {CARDINALITY} from '../common/interface/helpers'
import {ProdboardCabinetOption} from '../services/prodboard-types'

export class Shelves extends CabinetOption {

  /**
   * Must be statically set _before_ constructor?
   */
  public readonly optionSelectName = 'Shelves'

  private default = 0

  private max = 6

  constructor(option: ProdboardCabinetOption, product: IProduct, cabinetId: number) {
    super(option, product, cabinetId)
    this.setNameAndOptions()

    this.priority = 10
    this.viewOptions = [
      {
        type: ViewOptionType.select,
        title: 'Antal hyllplan',
        name: 'shelves',
        values: ['1', '2', '3', '4', '5'],
        selection: '',
        disabled: false
      }
    ]
    this.description = 'MISSING'
    this.title = 'optShelves'

    this.product.exShPr.price = this.product.exShPr.price || 0
    this.product.exShPr.labor = this.product.exShPr.labor || 0

    const sOptions = Shelves.sanitize(option)
    this.setValuesFromProdboardData(sOptions)
    this.setPrice()
  }

  private static sanitize(option: ProdboardCabinetOption): ProdboardCabinetOption {
    // Object assign did not work so I made this instead.
    option.value = option.value || {} as any
    option.value.name = option.value.name || 'def 0 (max 6)'
    option.value.options = option.value.options || {} as any
    option.value.options.sh = option.value.options.sh || {} as any
    option.value.options.sh.qty = option.value.options.sh.qty || '0'
    return option
  }

  public update(data: any): void {
    super.update(data)
    this.setFromProperties(data)
    this.active = +this.viewOptions[0].selection < 0
    this.setPrice()
  }

  protected getCustomCustomerListing(lc: string): string[] {
    return [this.optionValueNames[0][lc] + this.viewOptions[0].selection + ' ' + CARDINALITY[lc]]
  }

  private setValuesFromProdboardData(option: ProdboardCabinetOption): void {
    this.viewOptions[0].selection = option.value.options.sh.qty + ''
    this.active = !!(+option.value.options.sh.qty)
    this.setMinMax(option.value.name)
  }

  private setPrice(): void {
    this.resetPrices()
    this.shouldHavePrice = this.active
    this.price = this.product.exShPr.price * Number(this.viewOptions[0].selection)
    this.labor = this.product.exShPr.labor * Number(this.viewOptions[0].selection)
    const item = this.addReceiptItem()
    item.priceProperty = 'exShPr'
    item.itemText = `Pris för ${Number(this.viewOptions[0].selection)} hyllplan`
    this.addPriceFromComments()
  }

  /**
   * Add default and max based on the cryptic name
   */
  private setMinMax(key: string): void {
    const val = key.replace(/\s/g, '')
    const match = val.match(/def(\d)\(max(\d.*)\)/)
    const array: string[] = this.viewOptions[0].values
    if (match[1] && match[2]) {
      this.max = +match[2]
      array.length = 0
      for (let i = 0; i < +match[2] + 1; i++) {
        array.push(i + '')
      }
    }
    const numShelf = +this.viewOptions[0].selection
    if (numShelf > this.max) {
      this.problems.push({
        description:
          `Antal hyllor är fel? ${numShelf} (${this.default} < > ${this.max}) [${this.cabinetIndex}/${this.name}]`,
        handled: false
      })
      array.push(`${numShelf}`)
    }
  }
}

import {CabinetOption, ViewOptionType} from './cabinet-option'
import {IProduct} from '../common/interface/product-types'
import {ProdboardCabinetOption} from '../services/prodboard-types'

export class CarpenterJoy extends CabinetOption {

  /**
   * Must be statically set _before_ constructor?
   */
  public readonly optionSelectName = 'CarpenterJoy'

  public selectionOptions = {
    true: 'Ja',
    false: 'Nej',
  }

  constructor(option: ProdboardCabinetOption, product: IProduct, cabinetId: number) {
    super(option, product, cabinetId)
    this.setNameAndOptions()
    this.priority = 10

    this.viewOptions = [
      {
        type: ViewOptionType.select,
        title: 'Vänster hörn',
        name: 'left',
        values: ['Ja', 'Nej'],
        selection: '',
        disabled: false,
      },
      {
        type: ViewOptionType.select,
        title: 'Höger hörn',
        name: 'right',
        values: ['Ja', 'Nej'],
        selection: '',
        disabled: false
      }
    ]

    this.description = 'A special kind of "decoration" in the facade frame of some wall cabinets'
    this.title = 'optCarpenterJoy'
    const sOption = CarpenterJoy.sanitize(option)
    this.setValuesFromProdboardData(sOption)
    this.setPrice()
  }

  private static sanitize(option: ProdboardCabinetOption): ProdboardCabinetOption {
    option.value = option.value || {code: 'Off', name: 'Off'} as any
    option.value.options = option.value.options || {} as any
    // eslint-disable-next-line @typescript-eslint/naming-convention
    option.value.options.frames = option.value.options.frames || {fr_left: false, fr_right: false} as any
    return option
  }

  public update(data: any): void {
    super.update(data)
    this.setFromProperties(data)
    this.active = this.viewOptions[0].selection === 'Ja' || this.viewOptions[1].selection === 'Ja'
    this.setPrice()
  }

  /**
   * Do not call this if you are not "active"!
   */
  protected getCustomCustomerListing(lc: string): string[] {
    if (this.viewOptions[0].selection === 'Ja' && this.viewOptions[1].selection === 'Ja') {
      return [this.optionValueNames[2][lc]]
    }

    if (this.viewOptions[0].selection === 'Ja') { // Left
      return [this.optionValueNames[0][lc]]
    }

    return [this.optionValueNames[1][lc]] // Right
  }

  private setValuesFromProdboardData(option: ProdboardCabinetOption): void {
    this.viewOptions[0].selection = 'Nej'
    this.viewOptions[1].selection = 'Nej'
    if (option.value.code !== 'Off') {
      this.viewOptions[0].selection = this.selectionOptions[option.value.options.frames.fr_left] || 'Nej'
      this.viewOptions[1].selection = this.selectionOptions[option.value.options.frames.fr_right] || 'Nej'
      this.active = this.viewOptions[0].selection === 'Ja' || this.viewOptions[1].selection === 'Ja'
    }
  }

  /**
   * Called upon creation and after update
   */
  private setPrice(): void {
    this.resetPrices()
    if (this.viewOptions[0].selection === 'Ja') {
      this.price += this.product.cuBoCoPr.price
      this.labor += this.product.cuBoCoPr.labor
      const item = this.addReceiptItem()
      item.itemText = 'Snickarglädje, vänster hörn'
      // When we have multiple prices we need to set them individually.
      item.price = this.product.cuBoCoPr.price
      item.labor = this.product.cuBoCoPr.labor
      item.priceProperty = 'cuBoCoPr'
      this.shouldHavePrice = true
    }
    if (this.viewOptions[1].selection === 'Ja') {
      this.price += this.product.cuBoCoPr.price
      this.labor += this.product.cuBoCoPr.labor
      const item = this.addReceiptItem()
      item.itemText = 'Snickarglädje, höger hörn'
      item.price = this.product.cuBoCoPr.price
      item.labor = this.product.cuBoCoPr.labor
      item.priceProperty = 'cuBoCoPr'
      this.shouldHavePrice = true
    }
    this.addPriceFromComments()
  }
}

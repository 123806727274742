import {CabinetOption, ViewOptionType} from './cabinet-option'
import {IProduct} from '../common/interface/product-types'
import {ProdboardCabinetOption} from '../services/prodboard-types'

export class Plinth extends CabinetOption {
  /**
   * Must be statically set _before_ constructor?
   */
  public readonly optionSelectName = 'Plinth'

  constructor(option: ProdboardCabinetOption, product: IProduct, cabinetId: number) {
    super(option, product, cabinetId)
    this.setNameAndOptions()

    this.viewOptions = [
      {
        type: ViewOptionType.text,
        title: 'Sockelhöjd',
        name: 'socelHeight',
        values: [],
        selection: '0',
        disabled: false
      }
    ]

    this.description = 'Height of the plinth which the cabinets stands on'
    this.title = 'optPlinth'
    // We never set values on create, b/c this can only get data from project or update
  }

  public update(data: any): void {
    super.update(data)
    this.active = false
    this.priority = 100
    this.viewOptions[0].selection = ''
    if (data.socelHeight || data.socelHeight === 0) {
      this.viewOptions[0].selection = data.socelHeight
      this.active = true
      this.priority = 99 // High prio if set
    }
    this.setPrice()
  }

  protected getCustomCustomerListing(lc: string): string[] {
    return [`${this.optionValueNames[0][lc]}${this.viewOptions[0].selection} mm`]
  }

  private setPrice(): void {
    this.resetPrices()
    this.addPriceFromComments()
  }
}

import {Component, Inject, Optional} from '@angular/core'
import {MAT_DIALOG_DATA} from '@angular/material/dialog'

export interface IWaitDialogData {
  /**
   * The top heading
   */
  title: string

  /**
   * Somthing to display in the dialog, if any
   */
  message: string
}

@Component({
  selector: 'kdl-wait-dialog',
  templateUrl: './wait-dialog.component.html',
  styleUrls: ['./wait-dialog.component.scss']
})
export class WaitDialogComponent {
  public title = 'Vänta...'
  public message: string = null
  public type = ''

  constructor(
    // Optional until fixed
    @Inject(MAT_DIALOG_DATA) @Optional() public data: IWaitDialogData
  ) {
  }
}

import {Injectable} from '@angular/core'
import {CabinetOption} from '../model/cabinet-option'
import {HttpClient} from '@angular/common/http'
import {BehaviorSubject, Observable, forkJoin, of} from 'rxjs'
import {environment} from '../../environments/environment'
import {tap, switchMap, map} from 'rxjs/operators'
import {overrideDefaults} from './override.defaults'

export interface OptionValueNameItem {
  /**
   * Most of the time, the key and sv overlap, but not
   * always.
   */
  key: string

  /**
   * The text to display for customer for this option
   */
  sv: string

  /**
   * The text to display for the factory (snickeri)
   */
  en: string
}

export interface OptionValues {
  /**
   * Database id, may or may not exist depending on
   * if this is stored in backend or not.
   */
  id?: string

  /**
   * The name of the Class
   */
  name: string

  /**
   *
   */
  options: OptionValueNameItem[]
}

@Injectable({
  providedIn: 'root'
})
export class OverrideService {


  public optionValues$: Observable<OptionValues[]>

  private pOptionValues$: BehaviorSubject<OptionValues[]> = new BehaviorSubject<OptionValues[]>([])

  private optionValues: OptionValues[] = overrideDefaults

  constructor(
    private httpClient: HttpClient) {
    this.pOptionValues$.next(this.optionValues)
    this.optionValues$ = this.pOptionValues$.asObservable()
  }

  /**
   * Make sure the value on the server matches the value
   * we have in code since the saved rules.
   *
   * @param server - The item stored on the server
   * @param stored - The item we have statically in code
   */
  public static setOverride(server: OptionValues, stored: OptionValues): OptionValues {
    // If the two options are exactly the same just return the stored value
    if (server === stored) {
      return stored
    }
    // Create a new shiny object that has all stored defaults
    const result: OptionValues = Object.assign({}, JSON.parse(JSON.stringify(stored)))

    // Set all values from server that still exist
    result.options.forEach((ovni: OptionValueNameItem) => {
      const serverValue = server.options.find((o: OptionValueNameItem) => o.key === ovni.key) || {}
      Object.assign(ovni, serverValue)
    })
    return result
  }

  public bootstrap(): Observable<any> {
    const url = `${environment.productUrl}/options`
    return this.httpClient.get<OptionValues[]>(url).pipe(
      tap((options: OptionValues[]) => {
        /**
         * We have the defaults, we iterate over those if we find the same
         * option among the server values we use the server value. However,
         * we also add missing keys from the hard coded value to the server
         * value in case we have changed the default.
         */
        const values = this.pOptionValues$.value.map((staticOption: OptionValues) => {
          const optionValue = options.find((serverOption: OptionValues) => serverOption.name === staticOption.name) || staticOption
          return OverrideService.setOverride(optionValue, staticOption)
        })
        this.pOptionValues$.next(values)
        this.optionValues = values
      })
    )
  }

  public setOption(option: CabinetOption): void {
    this.pOptionValues$.value.forEach((opt: OptionValues) => {
      if (opt.name === option.optionSelectName) {
        option.optionValueNames = opt.options
      }
    })
  }

  public getOneOption(modelName: string): OptionValues {
    return this.optionValues.find((option) => option.name === modelName)
  }

  /**
   * Get the paint process in english since this is a special case
   *
   * @param selection - The actual selection from paint process
   * @param lc - English (en) or Swedish (sv)
   */
  public getPaintProcess(selection: string, lc: 'en' | 'sv'): string {
    return this.getOneOption('PaintProcess')
      .options.filter((o: OptionValueNameItem) => o.key === selection) // Remove all that does not match
      .map((o: OptionValueNameItem) => o[lc])[0]
  }

  public saveOverride(option: OptionValues): Observable<OptionValues> {
    const id = option.id ? `/${option.id}` : ''
    const url = `${environment.productUrl}/options${id}`
    return this.httpClient.put<OptionValues>(url, option).pipe(
      switchMap((updated: OptionValues) => forkJoin([of(updated), this.bootstrap()])),
      map((res: [OptionValues, any]) => res[0])
    )
  }
}

<div class="main">
  <div *ngIf="projectService.project$ | async; let project" class="stuff price">
    <div *ngIf="project.computed.price">
      <div class="heading" kdlI18n="priceDataHeader"></div>
      <div class="info-item">
        <div class="info-title" kdlI18n label="price">Pris:</div>
        <div class="info-value">{{project.computed.price.price | number: '1.0-0':  'fr'}} kr</div>
      </div>
      <div class="info-item">
        <div class="info-title" kdlI18n label="factory">Snickeri:</div>
        <div class="info-value">{{project.computed.price.labor | number: '1.0-0':  'fr'}} €</div>
      </div>
      <div class="info-item">
        <div class="info-title" kdlI18n label="material">Material:</div>
        <div class="info-value">{{project.computed.price.material | number: '1.0-0':  'fr'}} kr</div>
      </div>
    </div>
    <div *ngIf="customerPrice" class="info-item">
      <div class="info-title" kdlI18n label="customerPrice">Pris till kund:</div>
      <div class="info-value">{{customerPrice | number: '1.0-0':  'fr'}} kr</div>
    </div>
  </div>
  <div class="receipt">
    <mat-accordion>
      <mat-expansion-panel>
        <mat-expansion-panel-header>
          <mat-panel-title>
            <span kdlI18n label="factory"></span>
          </mat-panel-title>
          <mat-panel-description *ngIf="snickerierTotal">
            {{snickerierTotal | number : '1.0-2' : 'fr'}} kr
          </mat-panel-description>
        </mat-expansion-panel-header>
        <kdl-kitchen-receipt (total)="setTotal($event, 'snickerierTotal')"></kdl-kitchen-receipt>
      </mat-expansion-panel>
      <mat-expansion-panel>
        <mat-expansion-panel-header>
          <mat-panel-title>
            <span kdlI18n label="accessories"></span>
          </mat-panel-title>
          <mat-panel-description>
            {{applianceTotal | number: '1.0-2': 'fr'}} kr
          </mat-panel-description>
        </mat-expansion-panel-header>
        <kdl-appliance-receipt (total)="setTotal($event, 'applianceTotal')"></kdl-appliance-receipt>
      </mat-expansion-panel>
    </mat-accordion>
  </div>
</div>

import {Injectable, Inject} from '@angular/core'
import {I18N} from '../../application/i18n.provider'
import {Observable, ReplaySubject, forkJoin} from 'rxjs'
import {I18n} from '../../application/lang/i18n'
import {map} from 'rxjs/operators'

@Injectable({
  providedIn: 'any'
})
export class I18nService {

  /**
   * Internal representation of translations
   *
   * @private
   */
  private labels: Map<string, string> = new Map<string, string>()

  private labels$: ReplaySubject<Map<string, string>> = new ReplaySubject<Map<string, string>>(1)

  constructor(
    @Inject(I18N) public i18nCore: Observable<I18n>[]
  ) {
    forkJoin(this.i18nCore).subscribe({
      next: (translations: I18n[]) => {
        translations.forEach((translation: I18n) => {
          Object.keys(translation).forEach((k: string) => {
            this.labels.set(k, translation[k].text)
          })
        })
        this.labels$.next(this.labels)
      }
    })
  }

  public getLabel(label: string): Observable<string> {
    return this.labels$.pipe(
      map(() => {
        const l = this.labels.get(label)
        return l || label
      })
    )
  }

  public translate(label: string): string {
    return this.labels.get(label)
  }
}

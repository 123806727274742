<button
  [disabled]="!path"
  [routerLink]="[customerPath + path]"
  class="compact customer" mat-mini-fab>
  <mat-icon class="material-icons-outlined" fontIcon="printer"></mat-icon>
</button>

<button
  [disabled]="!path"
  [routerLink]="[factoryPath + path]"
  class="compact factory"
  mat-mini-fab>
  <mat-icon class="material-icons-outlined">printer</mat-icon>
</button>

import {Injectable} from '@angular/core'
import {hasch} from '../common/interface/helpers'
import {BehaviorSubject} from 'rxjs'
import {ProdboardFile, ProdboardItemHolder, ProdboardItem} from './prodboard-types'

export interface IProdboardComment {
  // The cabinet index for back reference
  index: number

  // The cabinet UID should be used for back reference
  // but does not exist in old projects.
  uid?: string

  // The ID is calculated as a number based on the real comment
  id: string

  // If this has been deleted (we still have to remember it)
  deleted: boolean

  // The comment as such.
  comment: string
}

export interface PBFileCompare {

  /**
   * The prodboard id
   */
  id: number

  /**
   * The url
   */
  url: string

  /**
   * The version as parsed from the URL
   */
  version: number

  /**
   * The customer name
   */
  customer: string

  /**
   * Prodboard provides a number property,
   * unclear what it really means
   */
  num: string
}

@Injectable({
  providedIn: 'root'
})
export class ProdboardService {

  /**
   * A tuple of two files, The first should be the NEW file and the
   * second the currently loaded file.
   */
  public static prodboardFiles$: BehaviorSubject<[ProdboardFile, ProdboardFile]> =
    new BehaviorSubject<[ProdboardFile, ProdboardFile]>([null, null])

  public static setProdboardComment(item: ProdboardItem, prodboardComment: IProdboardComment): IProdboardComment {
    const id = hasch(item.comments)
    let deleted = id === '0'
    const comment = item.comments
    if (prodboardComment) {
      deleted = prodboardComment.deleted
      if (hasch(prodboardComment.comment) !== id) {
        deleted = false
      }
    }
    return {
      index: item.index,
      uid: item.uid,
      id,
      comment,
      deleted
    }
  }

  public static convertProdboardFileToDisplay(prodboardFile: ProdboardFile, display: PBFileCompare): void {
    let customer = ''
    if (prodboardFile.customer) {
      customer = prodboardFile.customer.name
    }
    let version = -1
    if (prodboardFile.url) {
      version = +prodboardFile.url.split('-')[2]
    }
    Object.assign(display, {
      id: prodboardFile.prodboardId,
      url: prodboardFile.url,
      num: prodboardFile.prodboardNumber,
      customer,
      version
    })
  }

  /**
   * Let us fulfixa the prodboard file already here,
   * all files should come from here. Prodboard files have:
   * a) a property named number, that sucks since number is a reserved word.
   * b) an "id" that conflicts with our id.
   *
   * Further we want to sort the cabinets based on index, for some reason I do not
   * remember.
   *
   * Then let us remove some shit properties that we do not need.
   * We replace them with the text 'REMOVED' in case that we forget
   * that in the future.
   *
   * @param file - The newly imported file
   * @param currentFile - The current file if any
   */
  public static fixProdboardFile(file: any, currentFile: ProdboardFile = null): void {
    // Really new files needs this. but we can only do it once
    if (file.rawFile) {
      file.prodboardId = file.id
      file.prodboardNumber = file.number
      delete file.id
      delete file.number
      delete file.rawFile
    }

    file.customer = file.customer || {}
    file.plan.items.sort((a: ProdboardItemHolder, b: ProdboardItemHolder) => a.item.index - b.item.index)

    file.plan.room = 'REMOVED'

    file.plan.items = ProdboardService.filterItems(file.plan.items)

    file.plan.items.forEach((item: ProdboardItemHolder) => {

      // Put the UID, position down on the item itself
      item.item.uid = item.uid
      item.item.position = item.position
      item.item.room = item.room

      let currentComment
      if (currentFile) {
        currentComment = ProdboardService.getExistingComment(item, currentFile)
      } else {
        // This is called when we load the file first time. If a totally new
        // file these are undefined. They will be immediately replaced.
        currentComment = item.item.prodboardComment
      }

      item.item.prodboardComment = ProdboardService.setProdboardComment(item.item, currentComment)

      // Bunch of items that exist that we do not need. Feel free to
      // elaborate on this list.
      delete item.item.materials
      delete item.item.panels
      delete item.item.a_shelf
      delete item.item.inline
      delete item.item.orientation
      delete item.item.end_panel
      delete item.item.knife_block
      delete item.item.light
      delete item.item.dr3
      delete item.item.dr6
      delete item.item.cab_type
      delete item.item.d_rule
      delete item.item.h_rule
      delete item.item.w_type15
      delete item.item.w_type23
    })
    // If we have a current file we must copy the old id and version
    if (currentFile) {
      // Not sure why we need this ++ but we do... /Daniel
      file.version = currentFile.version++
      file.id = currentFile.id
    }
  }

  /**
   * This removes all items that have a type known as bad ('worktop' etc.)
   *
   * Real cabinets have 'module' or 'tp1' or 'tp2' or 'tp6'
   *
   * @param items - A list of shite items from prodboard
   */
  private static filterItems(items: ProdboardItemHolder[]): ProdboardItemHolder[] {
    const forbiddenTypes = ['worktop', 'auxiliary', 'wall']
    return items.filter((item: ProdboardItemHolder) =>
      forbiddenTypes.indexOf(item.item.type) === -1 &&
      Array.isArray(item.item.items) &&
      item.item.items.length > 0 && // For some reason find of [] returns a truish value??
      !item.item.items.find(i => i.modificator === 'cabinet')
    )
  }

  /**
   *
   * @param current - is the prodboard file we have loaded
   * @param file - is the new prodboard file
   * @private
   */
  private static getExistingComment(current: ProdboardItemHolder, file: ProdboardFile): IProdboardComment | null {
    // Current index is the index from the file we have now
    const currentIndex = current.item.index

    // Look in the NEW file if we can find a prodboard comment.
    // We find the same by UID, but if there are no UID in the NEW file then
    // we resort to index.
    const currentItem: ProdboardItemHolder = file.plan.items.find((newCabinet: ProdboardItemHolder) => {
      if (!newCabinet.uid) {
        return newCabinet.item.index === currentIndex
      }
      return newCabinet.uid === current.item.uid
    })

    if (currentItem) {
      // Regardless of the current prodboard comment has an UID or not, it should
      // have an uid from the NEW file
      currentItem.item.prodboardComment.uid = currentItem.uid
      return currentItem.item.prodboardComment
    }
    return null
  }
}

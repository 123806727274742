import {formatNumber} from '@angular/common'
import {ICounterTopBakkant} from '../../services/project.service'
import {Comments, Comment, CommentHomeEntity} from '../../comments/model/comment'
import {TEMP_SOURCE_ID} from '../../images/model/project-image'

type MakeMaterial = 'stone' | 'wood' | 'stainless'

type MakeOrBuy = 'make' | 'buy'

export const materialTranslation = {
  stone: 'Stenbänkskiva',
  wood: 'Träbänkskiva',
  stainless: 'Rostfri bänkskiva'
}

/**
 * The most necessary properties fetched from a product
 */
export type TCounterTopProduct = {
  pr: {
    price: number
    material: number
    labor: number
  }

  /**
   * The description from the product databse
   */
  description: string
}

/**
 * Simple abstraction to save some typing
 */
export interface IProductSelect {
  /**
   * CT is the product code for the counter top, e.g. CT_10
   */
  ct: string

  /**
   * CTP is "plint" (bakkant) product.
   */
  ctp: string

}

/**
 * Used to start a new counter top based on another
 * selection
 */

/**
 * The name, id and index of the cabinet the countertop sits on
 */
export interface ICounterTopCabinet {

  /**
   * The cabinets unique id
   */
  uid: string

  /**
   * The cabinets index
   */
  index: number
}

export interface ICounterTop {

  /**
   * A unique id, not uuid since we do not have that.
   */
  id?: string

  type: MakeOrBuy

  material: MakeMaterial

  woodType: IProductSelect

  /**
   * Customer friendly short name
   */
  name: string

  /**
   * Price is price per sqm to customer
   */
  price: number

  /**
   * Additional, possibly rounded price to show to customer.
   * Set manually or just the calculated price
   */
  customerDisplayPrice: number

  /**
   * The price / sqm for factory
   */
  labor: number

  /**
   * Discount in SEK
   */
  discount: number

  /**
   * Total length in mm
   */
  length: number

  /**
   * Depth in mm.
   */
  depth: number

  thickness: number

  /**
   * Product is mandatory if make
   */
  product?: TCounterTopProduct

  /**
   * Bakkant is possible for make counter tops.
   */
  bakkant?: ICounterTopBakkant

  /**
   * Tell if the bakkant should be displayed or not
   */
  hasBakkant: boolean

  /**
   * A list of cabinet ids and name the countertop sits on
   */
  cabinets: ICounterTopCabinet[]
}

export class CounterTop implements ICounterTop, Comments {

  /**
   * ID set only when we actually have one.
   */
  public id: string

  public type: MakeOrBuy = 'make'

  public material: MakeMaterial

  public woodType: IProductSelect = {ct: 'CT_10', ctp: 'CT_10_p'}

  /**
   * The name is either set by the product like "Bänkskiva Massiva Ek"
   * or is set as a description when it is made.
   */
  public name = ''

  /**
   * Price is price per sqm
   */
  public price = 0

  /**
   * The price for the factory in sqm
   */
  public labor = 0

  public discount = 0

  public length = 0

  public depth = 0

  public thickness = 0

  public product: TCounterTopProduct = {pr: {price: 0, material: 0, labor: 0}, description: ''}

  public bakkant: ICounterTopBakkant = {
    length: 0,
    height: 0,
    thickness: 0,
    price: 0,
    labor: 0
  }

  public hasBakkant = false

  public cabinets: ICounterTopCabinet[] = []

  public comments: Comment[] = []

  public commentHome: CommentHomeEntity = {id: TEMP_SOURCE_ID, type: 'COUNTER_TOP'}

  private pCustomerDisplayPrice: number | null = null

  constructor(iCounterTop: ICounterTop = {} as any) {
    this.id = self.crypto.randomUUID()
    Object.assign(this, iCounterTop)
    this.bakkant = Object.assign(this.bakkant, iCounterTop.bakkant || this.bakkant)
    this.commentHome.id = this.id
  }

  get area(): number {
    return this.length * this.depth / 1000 / 1000
  }

  get bakkantArea(): number {
    return this.bakkant.height * this.bakkant.length / 1000 / 1000
  }

  /**
   * Either the type of counter top as  in Massiv Ek or
   * the name of the type as in 'Rostfri', 'Sten'
   */
  get customerDisplayName(): string {
    if (this.type === 'make') {
      return this.name
    }
    if (this.type === 'buy') {
      return materialTranslation[this.material]
    }
  }

  get dimensionsString(): string {
    if (this.type === 'make') {
      return `${this.length} mm x ${this.depth} mm x ${this.thickness} mm`
    }
    return ''
  }

  get bakkantString(): string {
    if (this.hasBakkant) {
      return ` (med bakkant) `
    }
    return ''
  }

  get counterTopPresentation(): string {
    let price = formatNumber(this.price, 'fr', '1.0-0')
    if (this.type === 'make') {
      price = formatNumber(this.customerDisplayPrice, 'fr', '1.0-0')
      return (`${this.customerDisplayName} ${this.bakkantString} = ${price} kr`)
        .replace(/ {2}/g, ' ')
    }

    const presString = `${this.customerDisplayName}, ${this.name} = ${price} kr`
    if (this.discount > 0) {
      price = formatNumber(this.price - this.discount, 'fr', '1.0-0')
      return presString + ' - ' + this.discount + ' kr rabatt = ' + price + ' kr'
    }
    return presString
  }

  /**
   * Used for made counter tops only, returns the
   * price of the counter top * with total area
   */
  get materialCost(): number {
    return this.area * this.price + this.bakkantArea * this.bakkant.price
  }

  /**
   * Only used in Factory PDF
   */
  get laborPrice(): number {
    return this.labor * (this.area + (this.hasBakkant ? this.bakkantArea : 0))
  }

  /**
   * This should always return the exact price
   * that the customer will pay.
   *
   * If make this is either the calcualted price or the set
   * customer price if make.
   *
   * Or if buy the price _after_ discount.
   */
  get customerDisplayPrice(): number {
    // Customer display price is only set for "make"
    // If it has a number (even 0) it will be used.
    if (this.pCustomerDisplayPrice !== null) {
      return this.pCustomerDisplayPrice
    }

    if (this.type === 'make') {
      return this.materialCost
    }
    // Else it is the buy, and we return the price _after_ discount
    return this.price - this.discount
  }

  set customerDisplayPrice(displayPrice: number | null) {
    this.pCustomerDisplayPrice = displayPrice
  }


  public join(counterTop: CounterTop): void {
    if (this.equal(counterTop) && this.id !== counterTop.id) {
      if (this.type === 'make') {
        // Only the length must/can be added
        this.length += counterTop.length
        this.bakkant.length += counterTop.bakkant.length
      } else {
        this.price += counterTop.price
        this.discount += counterTop.discount
      }
      this.cabinets = this.cabinets.concat(counterTop.cabinets)
    }
  }

  /**
   * Two counter tops can be equal if it is the same
   * but just split over several cabinets. Also bakkant
   * is important
   *
   * @param counterTop
   * @private
   */
  public equal(counterTop: CounterTop): boolean {
    return this.serial() === counterTop.serial()
  }

  /**
   * Serialize the counter top so that we can see if they are join-able
   */
  public serial(): string {
    return `${this.name}${this.hasBakkant}${this.type}${this.woodType.ct}${this.depth}${this.thickness}` +
      `${this.bakkant.height}${this.bakkant.thickness}${this.pCustomerDisplayPrice === null}}`
  }
}

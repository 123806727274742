/**
 * Creates the Prodboard Cabinets based on the
 * provided prodboard export file and set additional
 * data on the Cabinet based on what has been saved
 * in the project.
 */
import {forkJoin, Observable} from 'rxjs'
import {ProdboardCabinet} from './cabinet/prodboard-cabinet'
import {ProductCategory, ProductStaticService} from '../services/product-static.service'
import {ProdboardCabinetFactory} from './prodboard-cabinet-factory'
import {Injectable} from '@angular/core'
import {map} from 'rxjs/operators'
import {OverrideService} from '../services/override.service'
import {ProdboardFile, ProdboardItem, ProdboardItemHolder, ProdboardSelectionItem} from '../services/prodboard-types'
import {IProject} from '../services/project-types'

@Injectable({
  providedIn: 'root'
})
export class ProdboardFactory {

  constructor(
    private overrideService: OverrideService,
    private productService: ProductStaticService,
    private cabinetFactory: ProdboardCabinetFactory,
  ) {
  }

  private static getCodeFromProdboardItem(item: ProdboardItem): string {
    const i = item.items.find((itm: ProdboardSelectionItem) => itm.code === 'cabinet')
    if (!i) {
      return '' // Counts as falsy and will be removed
    }
    return i.modificator
  }

  /**
   * This is only called by the Project Service. Only the project service knows
   * when we have a new fancy prodboard file to process.
   *
   * @param file - A prodboard file
   * @param project
   */
  public createCabinets(file: ProdboardFile, project: IProject): Observable<ProdboardCabinet[]> {

    // Distill a list of unique product codes
    const codes = this.getAllProductCodes(file)

    // Create observables calls for every code,
    const code$ = codes.map((code: string) => this.productService.getProductByProdBoardId(code))

    // Fork Join 'em, the pipe is activated when all are done.
    // Finally, we will return a list of cabinets, full of fancy
    // options and such.
    return forkJoin(code$)
      .pipe(
        map((res: ProductCategory[]) => {
            // Create a map that we can use for easy retrieval
            const products = new Map(res.map(i => [i.pc, i]))
            return file.plan.items
              .map((cab: ProdboardItemHolder) => {
                const code = ProdboardFactory.getCodeFromProdboardItem(cab.item)
                // If we have received stuff from Prodboard that we cannot cover for. This should
                // be recorded in the service that have already called the problem service.
                if (!products.get(code)) {
                  return
                }
                // This is immediate, no calls to  internet or so, all data is available.
                return this.cabinetFactory.createCabinet(this.overrideService, products.get(code), cab.item, project)
              })
              .filter(Boolean) // Remove the nulls/undefined that is the ones from the return undefined above
          }
        )
      )
  }

  private getAllProductCodes(f: ProdboardFile): string[] {
    const prodboardCodes = f.plan.items.map((item: ProdboardItemHolder) =>
      ProdboardFactory.getCodeFromProdboardItem(item.item))
    return [...new Set(prodboardCodes)].filter(i => i)
  }
}

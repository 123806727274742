export const environment = {
  production: false,
  debug: true,
  // Cognito config,
  clientId: '78ao2k70ca9eapc89285951na8',
  userPoolId: 'eu-west-1_LrUY36OWg',
  // productUrl: 'https://product-api-daniel.kulladal.me/api',
  productUrl: '/api',
  prodboardApiUrl: 'https://api-prodboard-test.kulladal.me/prodboard',
  showFactory: true,
}

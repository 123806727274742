import {CabinetOption, ViewOptionType} from './cabinet-option'
import {IProduct} from '../common/interface/product-types'
import {ProdboardCabinetOption} from '../services/prodboard-types'


export class HandleDrawer extends CabinetOption {
  /**
   * Must be statically set _before_ constructor
   */
  public readonly optionSelectName = 'HandleDrawer'

  constructor(option: ProdboardCabinetOption, product: IProduct, cabinetId: number) {
    super(option, product, cabinetId)
    this.setNameAndOptions()
    this.priority = 100

    this.viewOptions = [
      {
        type: ViewOptionType.select,
        title: 'Typ av handtag/knoppar',
        name: 'handleDrawer',
        values: ['Handtag_fornicklad_massing', '1843 silver', 'Handtag_massing', '1843 gold', 'Skalhandtag_massing',
          'ldhandtag_silver'],
        selection: '',
        disabled: false
      }
    ]
    this.active = true

    this.description = 'MISSING'
    this.title = 'optHandleDrawer'

    const sOptions = HandleDrawer.sanitize(option)
    this.setValuesFromProdboardData(sOptions)
    this.addPriceFromComments()
  }

  private static sanitize(option: ProdboardCabinetOption): ProdboardCabinetOption {
    option.value = option.value || {} as any
    option.value.handle = option.value.handle || {} as any
    option.value.handle.dr_model = option.value.handle.dr_model || {} as any
    option.value.handle.dr_model.dr_choice = option.value.handle.dr_model.dr_choice || {code: ''} as any

    return option
  }

  public update(options: any) {
    super.update(options)
    this.resetPrices()
    this.addPriceFromComments()
  }

  protected getCustomCustomerListing(lc: string): string[] {
    return []
  }

  private setValuesFromProdboardData(option: ProdboardCabinetOption) {
    this.viewOptions[0].selection = option.value.handle.dr_model.dr_choice.code
  }
}

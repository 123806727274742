<h4 matDialogTitle>Redigera val</h4>
<div matDialogContent>
  <kdl-cabinet-option [cabinet]="data.cabinet" [lc]="data.lc" [option]="data.option"></kdl-cabinet-option>
</div>
<mat-dialog-actions>
  <button (click)="dialogRef.close()" color="accent" mat-stroked-button>
    <mat-icon class="material-icons-outlined" fontIcon="close"></mat-icon>
    <span kdlI18n label="closeBtn"></span>
  </button>
</mat-dialog-actions>

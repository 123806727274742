import {Component, OnInit} from '@angular/core'
import {ProjectService} from '../../services/project.service'
import {Router} from '@angular/router'
import {IProject} from '../../services/project-types'

@Component({
  selector: 'kdl-shortcuts',
  templateUrl: './shortcuts.component.html',
  styleUrls: ['./shortcuts.component.scss']
})
export class ShortcutsComponent implements OnInit {

  public editProjectLink: string[] = ['dashboard', 'project']

  public type = 'a'

  constructor(
    private projectService: ProjectService,
    private router: Router
  ) {
  }

  ngOnInit(): void {
    this.projectService.currentProject$.subscribe({
      next: (project: IProject) => {
        if (project && project.id) {
          this.editProjectLink = ['edit-project', 'project', project.id, 'warnings']
        } else {
          this.editProjectLink = ['dashboard', 'project']
        }
      }
    })
  }

  /**
   * We must reset current project otherwise it will immediately load the
   * current project
   */
  public openProject(): void {
    this.projectService.currentProject$.next(null)
    this.projectService.currentFile$.next(null)
    this.router.navigate(['dashboard', 'project', 'prodboard-upload']).then()
  }
}

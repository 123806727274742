import {BrowserModule} from '@angular/platform-browser'
import {APP_INITIALIZER, ErrorHandler, LOCALE_ID, NgModule} from '@angular/core'
import {registerLocaleData} from '@angular/common'
import {HTTP_INTERCEPTORS, HttpClientModule} from '@angular/common/http'
import localeFr from '@angular/common/locales/fr'
import localeSv from '@angular/common/locales/sv'
import localeFrExtra from '@angular/common/locales/extra/fr'
import localeSvExtra from '@angular/common/locales/extra/sv'

import {AppRoutingModule} from './app-routing.module'
import {AppComponent} from './app.component'
import {WINDOW_PROVIDERS} from './application/window.provider'
import {LOCAL_STORAGE_PROVIDERS} from './application/localstorage.provider'

import {GlobalErrorHandler} from './application/error-handler'
import {BrowserAnimationsModule} from '@angular/platform-browser/animations'
import {AuthService} from './services/auth.service'
import {AuthInterceptor} from './application/auth-interceptor'
import {ResponseInterceptor} from './application/response-interceptor'
import {ReactiveFormsModule} from '@angular/forms'
import {KdlCommonModule} from './common/common.module'
import {BootstrapService} from './services/bootstrap.service'
import {I18N, i18nCoreFactory} from './application/i18n.provider'
import {MatDialogModule} from '@angular/material/dialog'
import {MAT_FORM_FIELD_DEFAULT_OPTIONS} from '@angular/material/form-field'
import {MatAutocompleteModule} from '@angular/material/autocomplete'
import {I18nService} from './i18n/service/i18n.service'

registerLocaleData(localeFr, 'fr', localeFrExtra)
registerLocaleData(localeSv, 'sv', localeSvExtra)

const bootstrapFactory = (i18nService: I18nService, bs: BootstrapService, as: AuthService) => () => as.checkLogin()

@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    AppRoutingModule,
    // Importing this here to be able to inject it in Dialog
    // See if it can be removed
    ReactiveFormsModule,
    // ---
    KdlCommonModule,
    // Must import this here for the ProjectHelperService and Comment
    // Service that opens the dialog
    MatDialogModule,
    MatAutocompleteModule
  ],
  providers: [
    AuthService,
    {
      // This is nice magic to make sure we load any existing tokens.
      // We return an observable, the "angulars" will subscribe to this
      // and when it completes, it will continue.
      // Injecting the I18nService to bootstrap translations.
      provide: APP_INITIALIZER,
      useFactory: bootstrapFactory, //(bs: BootstrapService, as: AuthService) => () => as.checkLogin(),
      deps: [I18nService, BootstrapService, AuthService],
      multi: true
    },
    WINDOW_PROVIDERS,
    LOCAL_STORAGE_PROVIDERS,
    {
      provide: I18N,
      useFactory: () => i18nCoreFactory('core')
    },
    {provide: LOCALE_ID, useValue: 'sv-SE'},
    {provide: ErrorHandler, useClass: GlobalErrorHandler},
    {provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true},
    {provide: HTTP_INTERCEPTORS, useClass: ResponseInterceptor, multi: true},
    {provide: MAT_FORM_FIELD_DEFAULT_OPTIONS, useValue: {appearance: 'outline', floatLabel: 'always'}}
    // Does not have panelClass etc. {provide: MAT_TOOLTIP_DEFAULT_OPTIONS}
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
}

import {Injectable} from '@angular/core'
import {CanActivate, CanMatch, Router, UrlTree} from '@angular/router'
import {Observable} from 'rxjs'
import {AuthService, IAuthInfo} from '../services/auth.service'
import {EAuthState} from '../common/interface/auth'
import {first, map} from 'rxjs/operators'

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate, CanMatch {
  constructor(
    private authService: AuthService,
    private router: Router
  ) {
  }

  canActivate(): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return this.test()
  }

  canMatch(): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return this.test()
  }

  private test(): Observable<boolean> {
    return this.authService.authState$.pipe(
      first(),
      map((authInfo: IAuthInfo) => {
        if (authInfo.state === EAuthState.authenticated && authInfo.user) {
          // Allow admins to go anywhere
          if (authInfo.user.roles.indexOf('admin') !== -1) {
            return true
          }
        }
        this.router.navigate(['/login/logout']).then()
        return false
      })
    )
  }
}

import {Component, Input} from '@angular/core'
import {Warning} from '../../model/warning'
import {WarningService} from '../../service/warning.service'

@Component({
  selector: 'kdl-warning-item',
  templateUrl: './warning-item.component.html',
  styleUrls: ['./warning-item.component.scss']
})
export class WarningItemComponent {
  @Input() warning: Warning = {details: []} as any

  constructor(
    public warningService: WarningService
  ) {
  }
}

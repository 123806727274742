<mat-accordion multi>
  <mat-expansion-panel
    *ngFor="let warning of warningsList; trackBy: tracker">
    <mat-expansion-panel-header class="header">
      <mat-panel-title class="title">
        {{warning.name}}
      </mat-panel-title>
      <mat-panel-description>
        <mat-icon
          *ngIf="warning.hint"
          matTooltipClass="mat-tooltip-extra"
          [matTooltip]="warning.hint"
          class="material-icons-outlined" color="accent" fontIcon="help_outline"></mat-icon>
      </mat-panel-description>
    </mat-expansion-panel-header>
    <div *ngIf="warning.details.length > 0">
      <kdl-warning-item [warning]="warning"></kdl-warning-item>
    </div>
  </mat-expansion-panel>
</mat-accordion>

import {CabinetOption, ViewOptionType} from './cabinet-option'
import {IProduct} from '../common/interface/product-types'
import {ProdboardCabinetOption} from '../services/prodboard-types'

export class DrawerFront extends CabinetOption {

  /**
   * Must be statically set _before_ constructor
   */
  public readonly optionSelectName = 'DrawerFront'

  constructor(option: ProdboardCabinetOption, product: IProduct, cabinetId: number) {
    super(option, product, cabinetId)
    this.setNameAndOptions()

    this.priority = 50
    this.viewOptions = [
      {
        type: ViewOptionType.select,
        title: 'Lådfronter',
        name: 'drawerFront',
        values: ['Standard', 'Släta lådfronter'],
        selection: '',
        disabled: false
      }
    ]
    this.active = true
    this.description = 'Sometimes the drawer fronts are "plain", other times they are "regular"'
    this.title = 'optDrawerFront'

    const sOption = DrawerFront.sanitize(option)
    this.setValuesFromProdboardData(sOption)
    this.addPriceFromComments()

  }

  private static sanitize(option: ProdboardCabinetOption): ProdboardCabinetOption {
    option.value = option.value || {} as any
    option.value.options = option.value.options || {} as any
    option.value.options.door = option.value.options.door || {type: ''}

    return option
  }

  public update(data: any) {
    super.update(data)
    this.resetPrices()
    this.setFromProperties(data)
    this.addPriceFromComments()
  }

  protected getCustomCustomerListing(lc: string): string[] {
    if (this.viewOptions[0].selection === 'Släta lådfronter') {
      return [this.optionValueNames[1][lc]]
    }
    return []
  }

  private setValuesFromProdboardData(option: ProdboardCabinetOption): void {
    this.viewOptions[0].selection = 'Standard'
    if (option.value.name === 'Släta lådfronter') {
      this.viewOptions[0].selection = 'Släta lådfronter'
    }
  }
}

import {CabinetOption} from '../cabinet-option'
import {HandleDoor} from '../handle-door'
import {NO_DOOR_SELECTED} from '../door'

/**
 * Simple helper to make testing feasible.
 */
export class StoppersAndCatchers {
  public static select(doorType: CabinetOption, door: CabinetOption, hinges: CabinetOption, handles: HandleDoor): string | undefined {
    if (doorType && hinges && (!door || door.viewOptions[0].selection !== NO_DOOR_SELECTED)) {
      let type: 'a' | 'b' | 'c'
      const doorSelection = doorType.viewOptions[0].selection
      const hingeSelection = hinges.viewOptions[0].selection

      // 1. Klassiskt lyftgångärn
      if (hingeSelection === '1. Klassiskt lyftgångjärn') {
        if (hinges.active) {
          type = 'a'
        }
        // 1. Överfalsad profilerad lucka.
        if (handles && handles.isVred()) {
          type = 'b'
          if (doorSelection === '1. Överfalsad profilerad lucka') {
            type = 'c'
          }
        }
      } else {
        // For Hinge types 2 and 3 the logic is the same
        type = 'b' // Default for Must be Modern with visible top
        // 1. Överfalsad profilerad lucka.
        if (doorSelection === '1. Överfalsad profilerad lucka') {
          type = 'c'
        }
      }
      return type
    }
  }
}

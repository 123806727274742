import {Component, OnDestroy, OnInit} from '@angular/core'
import {ReplaySubject, Subscription} from 'rxjs'
import {ProdboardCabinet} from '../../../model/cabinet/prodboard-cabinet'
import {filter, switchMap} from 'rxjs/operators'
import {CabinetOption} from '../../../model/cabinet-option'
import {ProjectHelperService} from '../../../services/project-helper.service'
import {Comment, IComment} from '../../../comments/model/comment'
import {CounterTop} from '../../../counter-top/model/counter-top'
import {IProject} from '../../../services/project-types'

export interface OptionItem {
  text: string
  labor: number
  comment?: boolean
}

export interface Item {
  index: number

  labor: number

  text: string

  rLabor: number

  cLabor: number

  comments: OptionItem[]

  options: OptionItem[]
}

@Component({
  selector: 'kdl-factory-receipt',
  templateUrl: './factory-receipt.component.html',
  styleUrls: ['./factory-receipt.component.scss']
})
export class FactoryReceiptComponent implements OnInit, OnDestroy {

  public items: Item[] = []

  public rLabor = 0

  public items$: ReplaySubject<Item[]> = new ReplaySubject<Item[]>(1)

  private list = []

  private sub$ = new Subscription()

  constructor(
    private projectHelperService: ProjectHelperService,
  ) {
  }

  public ngOnInit(): void {
    this.sub$ = this.projectHelperService.cabinets$.pipe(
      filter(Boolean),
      switchMap((cabinets: ProdboardCabinet[]) => {
        this.list.length = 0
        this.rLabor = 0
        cabinets.forEach((cabinet: ProdboardCabinet) => {
          const item: Item = {
            labor: cabinet.labor,
            rLabor: 0,
            text: cabinet.cat,
            index: cabinet.index,
            cLabor: cabinet.labor,
            comments: [],
            options: []
          }
          this.list.push(item)

          cabinet.comments
            .filter((comment: Comment) => comment.labor > 0)
            .forEach((comment: Comment) => {
              item.comments.push({
                text: comment.translation,
                labor: comment.labor,
                comment: true
              })
              item.labor -= comment.labor
              this.rLabor = this.rLabor + comment.labor
            })

          cabinet.options.forEach((option: CabinetOption) => {
            if (option.active && option.labor > 0) {
              const optionItem = {
                text: option.getCustomerListing('factory').join(',') || option.title,
                labor: option.labor
              }
              item.options.push(optionItem)
              option.comments
                .filter((comment: IComment) => comment.labor > 0)
                .forEach((comment: IComment) => {
                  optionItem.labor -= comment.labor
                  item.options.push({
                    text: comment.translation,
                    labor: comment.labor,
                    comment: true
                  })
                })
              item.labor -= option.labor
              this.rLabor = this.rLabor + option.labor
              // Remove all items that have cost 0
              item.options = item.options.filter((i: OptionItem) => i.labor)
            }
          })
          this.rLabor = this.rLabor + item.labor
        })
        return this.projectHelperService.project$
      }),
      filter(Boolean),
    ).subscribe({
      next: (project: IProject) => {
        const labor = project.counterTops
          .filter((counterTop: CounterTop) => counterTop.type === 'make')
          .map((counterTop: CounterTop) => counterTop.laborPrice)
          .reduce((acc: number, val: number) => acc + val, 0)
        if (labor > 0) {
          const item: Item = {
            labor,
            rLabor: 0,
            text: 'Counter tops',
            index: -1,
            cLabor: 123,
            comments: [],
            options: []
          }
          this.rLabor = this.rLabor + labor
          this.list.push(item)
        }
        this.items$.next(this.list)
      }
    })
  }

  public ngOnDestroy(): void {
    this.sub$.unsubscribe()
  }
}

<div *ngIf="current" class="holder">
  <div class="item">
    <div class="title" kdlI18n label="customer"></div>
    <div class="text">{{current.customer}}</div>
  </div>
  <div class="item">
    <div class="title" kdlI18n label="idLabel"></div>
    <div class="text">{{current.id}}</div>
  </div>
  <div class="item">
    <div class="title" kdlI18n label="numberLabel"></div>
    <div class="text">{{current.num}}</div>
  </div>
  <div class="item">
    <div class="title" kdlI18n label="versionLabel"></div>
    <div class="text">{{current.version}}</div>
  </div>
  <div class="item">
    <div class="title">
      <div class="info-title"><a [href]="current.url" kdlI18n label="openInProdboard" target="_blank"></a></div>
    </div>
  </div>
</div>

import {IProduct} from '../common/interface/product-types'
import {ViewOptionType, CabinetOption} from './cabinet-option'
import {ProdboardCabinetOption} from '../services/prodboard-types'

export const VALUE_NONE = 'Ingen'

export class PaintSide extends CabinetOption {
  /**
   * Must be statically set _before_ constructor?
   */
  public readonly optionSelectName = 'PaintSide'

  private optionMap = {} as any

  constructor(option: ProdboardCabinetOption, product: IProduct, cabinetId: number) {
    super(option, product, cabinetId)
    this.setNameAndOptions()

    this.optionMap.Right = 'Höger'
    this.optionMap.Left = 'Vänster'
    this.optionMap['Left and Right'] = 'Båda'
    this.optionMap.None = 'Ingen'

    this.priority = 10
    this.viewOptions = [
      {
        type: ViewOptionType.select,
        title: 'Målad sida',
        name: 'paintedSide',
        values: [
          'Vänster',
          'Höger',
          'Båda',
          'Ingen'
        ],
        selection: 'Ingen',
        disabled: false
      },
    ]
    this.description = 'A technical thing, about painting certain cabinet sides that would otherwise be visible'
    this.title = 'optPaintSide'

    option.value = option.value || {} as any
    this.viewOptions[0].selection = this.optionMap[option.value.name]
    this.active = this.viewOptions[0].selection !== VALUE_NONE
  }

  public update(data: any): void {
    super.update(data)
    this.setFromProperties(data)
    this.active = this.viewOptions[0].selection !== VALUE_NONE
  }


  /**
   * Should not be presented to customers
   */
  protected getCustomCustomerListing(lc: string): string[] {
    if (lc === 'sv') {
      return []
    }
    return this.getCustomerListingByKey(lc, this.viewOptions[0].selection)
  }
}

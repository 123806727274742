import {Injectable} from '@angular/core'
import {ProductService} from './product.service'
import {forkJoin, Observable, of, ReplaySubject} from 'rxjs'
import {Category, IProduct} from '../common/interface/product-types'
import {catchError, first, map, switchMap, tap} from 'rxjs/operators'
import {ProblemService} from './problem.service'

/**
 * Let us invent a super ProductCategory with the wanted category
 * data inserted. Overlapping properties are hopefully the same.
 */
export interface ProductCategory extends IProduct, Category {
  loaded: boolean
}

@Injectable({
  providedIn: 'root'
})
export class ProductStaticService {

  /**
   * We emit on this when we have done our shit.
   * We will also always take the latest value when (if) fetching a product
   */
  private ready$ = new ReplaySubject<boolean>(1)

  /**
   * Start by getting hold of all Products and Categories
   *
   * Note how we set an empty object initially to avoid writing tests
   * and can drink more wine.
   */
  private products: ProductCategory[] = [{pc: '1'} as any]

  constructor(
    private productService: ProductService,
    private problemService: ProblemService
  ) {
    this.loadProducts().subscribe()
  }

  /**
   * This should not be called until we have all products loaded.
   * That is why I check on the "loaded" subject before continuing
   *
   * @param id
   */
  public getProductByProdBoardId(id: string): Observable<ProductCategory> {
    const product = this.products.find(p => p.pc === id)
    if (product && product.loaded) {
      return of(product)
    }

    return this.ready$
      .pipe(
        first(), // We must complete after taking one
        switchMap(() => this.productService.getProdboardProduct(id)),
        // The product should always exist, but it could "disappear" in the future...
        // Add the "product" to the stored "shallow" product, as well as "loaded" and return it.
        // We have now updated it in the database.
        map((p: IProduct) => {
          const prod = this.products.find(p2 => p2.pc === id)
          return Object.assign(prod, p, {loaded: true})
        }),
        catchError(() => {
          // console.error(err)
          this.problemService.problems$.next({description: `Vi hittade inte ${id} i produktdatabasen`, handled: false})
          return of({pc: 'missing'} as ProductCategory)
        })
      )
  }

  /**
   * This loads the product short list so that we have
   * category data put on it. To avoid having to nag about that
   * data forever and ever.
   */
  private loadProducts(): Observable<[IProduct[], Category[]]> {
    //
    // TODO: CHANGE THIS MISERY! REPLACE WITH PRODUCT LIST ITEM!
    //
    const products$ = this.productService.products$.pipe(first())
    const category$ = this.productService.categories$.pipe(first())
    return forkJoin([products$, category$])
      .pipe(tap((resArray: [IProduct[], Category[]]) => {
        const categories = resArray[1]
        this.products = resArray[0].map((product: IProduct) => {
          const category = categories.find((cat: Category) => product.cat === cat.cat)
          const p = JSON.parse(JSON.stringify(product))
          p.loaded = false
          p.deSwLaSw = category.deSwLaSw
          p.tiLaSw = category.tiLaSw
          p.deEnLaSw = category.deSwLaSw
          return p
        })
        this.ready$.next(true)
      }))
  }
}

<div *ngIf="post && pre" class="holder">
  <div class="items">
    <div>
      <div class="title" kdlI18n label="existing"></div>
      <kdl-pb-display [current]="post" [other]="pre"></kdl-pb-display>
    </div>
    <div>
      <div class="title" kdlI18n label="newFile"></div>
      <kdl-pb-display [current]="pre" [other]="post"></kdl-pb-display>
    </div>
  </div>
  <div class="buttons">
    <button (click)="selected.emit(true)" color="primary" kdlI18n label="select" mat-raised-button></button>
    <button (click)="selected.emit(false)" color="accent" kdlI18n label="undo" mat-stroked-button></button>
  </div>
</div>


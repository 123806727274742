import {Injectable} from '@angular/core'
import {BehaviorSubject, Subject, timer} from 'rxjs'
import {ProjectService} from './project.service'
import {debounceTime, filter, first, switchMap, takeUntil, tap} from 'rxjs/operators'
import {AUTO_SAVE_TIMEOUT} from '../common/interface/helpers'
import {IProject, IChange} from './project-types'

@Injectable({
  providedIn: 'root'
})
export class AutoSaveService {

  public saving$ = new BehaviorSubject<boolean>(false)

  public timer$ = new BehaviorSubject<number>(0)

  public changed$ = new BehaviorSubject<boolean>(false)

  public pChanged = false

  public pTimer = -1

  private cancelTimer: Subject<any> = new Subject()

  constructor(
    private projectService: ProjectService
  ) {
    this.projectService.changes$.pipe(
      tap((changes: IChange) => {
        this.cancelTimer.next(null) // Cancel potential ongoing saves.
        this.pTimer = 0 // Make sure timer is reset
        this.timer$.next(this.pTimer) // Send the timer value to listeners

        this.pChanged = changes.changed // Remember if we have changes or not.
        this.changed$.next(this.pChanged) // Update so that listeners know

        // If there were changes then start the timer
        if (changes) {
          this.countDown() // Start countdown
        }
      }),
      filter(Boolean),
      debounceTime(AUTO_SAVE_TIMEOUT),
    )
      .subscribe({
        next: () => {
          this.cancelTimer.next(null)
          if (this.pChanged) {
            this.saveProject()
          }
        }
      })
  }

  /**
   * For manual save by click of button
   */
  public saveProject(): void {
    this.projectService.currentProject$
      .pipe(
        first(),
        tap(() => this.saving$.next(true)),
        filter(Boolean),
        filter((project: IProject) => !!project.id),
        switchMap(() => this.projectService.saveProject())
      )
      .subscribe({
        next: () => {
          this.saving$.next(false)
        }
      })
  }

  /**
   * This is just for setting the clock.
   */
  private countDown(): void {
    timer(0, 1000).pipe(
      takeUntil(this.cancelTimer),
      tap(() => {
        this.pTimer++
        this.timer$.next(this.pTimer)
      })
    ).subscribe()
  }
}

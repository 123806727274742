import {Injectable} from '@angular/core'
import {HttpClient} from '@angular/common/http'
import {Observable, ReplaySubject} from 'rxjs'
import {environment} from '../../environments/environment'
import {map} from 'rxjs/operators'
import {IProject} from './project-types'

export interface KulladalUser {
  /**
   * UUID v4 unique Id
   */
  id?: string

  /**
   * Type is always U
   */
  type: 'U'

  /**
   * name
   */
  name: string

  /**
   * email
   */
  email: string

  /**
   * Roles is an array of simple string, like admin, factory, customer
   */
  roles: string[]

  /**
   * Temp password is there until the user has logged in and
   * successfully fetched his/hers own data.
   */
  tempPassword?: string
}

@Injectable({
  providedIn: 'root'
})
export class UserService {

  public users$: Observable<KulladalUser[]>

  public currentUser$ = new ReplaySubject<KulladalUser>(1)

  private pUsers$ = new ReplaySubject<KulladalUser[]>(1)

  constructor(private httpClient: HttpClient) {
    this.users$ = this.pUsers$.asObservable()
  }

  public getUsers(): Observable<KulladalUser[]> {
    const url = `${environment.productUrl}/users`
    return this.httpClient.get<KulladalUser[]>(url).pipe(
      map((users: KulladalUser[]) => {
        this.pUsers$.next(users)
        return users
      })
    )
  }

  public createUser(user: KulladalUser): Observable<KulladalUser> {
    const url = `${environment.productUrl}/users`
    return this.httpClient.put<KulladalUser>(url, user)
  }

  public saveUser(user: KulladalUser): Observable<KulladalUser> {
    const url = `${environment.productUrl}/users/${user.id}`
    return this.httpClient.put<KulladalUser>(url, user)
  }

  public deleteUser(id: string): Observable<void> {
    const url = `${environment.productUrl}/users/${id}`
    return this.httpClient.delete<void>(url)
  }

  public getUserSelf(): Observable<KulladalUser> {
    const url = `${environment.productUrl}/users/self`
    return this.httpClient.get<KulladalUser>(url)
  }

  public getUser(id: string): Observable<KulladalUser> {
    const url = `${environment.productUrl}/users/${id}`
    return this.httpClient.get<KulladalUser>(url)
  }

  public getUserSelfProjects(): Observable<IProject[]> {
    const url = `${environment.productUrl}/projects/self`
    return this.httpClient.get<IProject[]>(url)
  }
}

import {Component, OnDestroy, OnInit} from '@angular/core'
import {
  ADMIN_HOME_PATH,
  PRODUCT_HOME_PATH,
  SETTINGS_HOME_PATH,
  USER_HOME_PATH,
  CUSTOMER_HOME_PATH
} from '../interface/routing'
import {filter, tap, map} from 'rxjs/operators'
import {Subscription} from 'rxjs'
import {AuthService, IAuthInfo} from '../../services/auth.service'
import {EAuthState} from '../interface/auth'

@Component({
  selector: 'kdl-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit, OnDestroy {

  public productPath = '/' + PRODUCT_HOME_PATH
  public userPath = '/' + USER_HOME_PATH
  public adminHomePath = '/' + ADMIN_HOME_PATH
  public settingsHomePath = '/' + SETTINGS_HOME_PATH
  public customerHomePath = '/' + CUSTOMER_HOME_PATH

  // Most users have a name, all users have an email
  // Use name when we can but fall back on email
  public userName = ''

  public userEmail = ''

  public isAdmin = false

  public items: any[] = [
    {
      title: 'Avsluta',
      link: '/login/logout'
    }
  ]

  private productMenuItem: any = {title: 'Produkter', link: this.productPath}

  private baseMenu: any[] = [
    {
      title: 'Avsluta',
      link: '/login/logout'
    }
  ]

  private sub$ = new Subscription()

  /**
   * The constructor
   *
   * @param authService - So that we can set state of menu.
   */
  constructor(
    private authService: AuthService,
  ) {
  }

  public ngOnInit(): void {
    this.items =
      [
        {
          title: 'Hem',
          link: '/' + ADMIN_HOME_PATH
        },
        {
          title: 'Kunder',
          link: this.customerHomePath
        },
        this.productMenuItem,
        {
          title: 'Användare',
          link: this.userPath
        },
        {
          title: 'Inställningar',
          link: this.settingsHomePath
        }
      ]

    this.items = this.items.concat(this.baseMenu)

    this.sub$ = this.authService.authState$
      .pipe(
        tap(() => this.userName = this.userEmail = ''),
        filter((state: IAuthInfo) => state.state === EAuthState.authenticated && !!state.user),
        map((state: IAuthInfo) => {
          this.userName = state.user.name
          this.userEmail = state.user.email
          // If not admin then show base menu only
          if (state.user.roles.indexOf('admin') === -1) {
            this.items = this.baseMenu
          } else {
            this.isAdmin = true
          }
        })
      )
      .subscribe()
  }

  public ngOnDestroy(): void {
    this.sub$.unsubscribe()
  }
}

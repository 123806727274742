import {CabinetOption, ViewOptionType} from '../cabinet-option'
import {IProduct} from '../../common/interface/product-types'
import {FactoryData, IFDFrame} from '../factory/factory-data'
import {FrameWidthHelper} from './frame-width-helper'
import {Skirting} from '../skirting'
import {ProdboardCabinetOption} from '../../services/prodboard-types'
import {ProdboardCabinet} from '../cabinet/prodboard-cabinet'

export const FRAME_BOTTOM_POS = 3
export const FRAME_LEFT_POS = 1
export const FRAME_RIGHT_POS = 2
export const FRAME_TOP_POS = 0

export class FrameWidth extends CabinetOption {
  /**
   * Must be statically set _before_ constructor
   */
  public readonly optionSelectName = 'FrameWidth'

  private frameWidthHelper: FrameWidthHelper

  /**
   * Set differently if scribings on cabinet
   */
  private measureLeft = ' mm'
  private measureRight = ' mm'

  constructor(option: ProdboardCabinetOption, product: IProduct, private cabinet: ProdboardCabinet, frameWidthHelper: FrameWidthHelper) {
    super(option, product, cabinet.index)
    this.setNameAndOptions()
    this.frameWidthHelper = frameWidthHelper
    this.priority = 5
    this.viewOptions = [
      {
        type: ViewOptionType.text,
        title: 'Top (mm)',
        name: 'top',
        values: [],
        selection: '',
        disabled: false
      },
      {
        type: ViewOptionType.text,
        title: 'Left (mm)',
        name: 'left',
        values: [],
        selection: '',
        disabled: false
      },
      {
        type: ViewOptionType.text,
        title: 'Right (mm)',
        name: 'right',
        values: [],
        selection: '',
        disabled: false
      },
      {
        type: ViewOptionType.text,
        title: 'Bottom (mm)',
        name: 'bottom',
        values: [],
        selection: '',
        disabled: false
      }
    ]
    this.active = true

    this.description = 'The facade frame - around the doors - can have different widths'
    this.title = 'optFrameWidth'

    FrameWidth.sanitize(option)
    this.setValuesFromProdboardData(this.option)
    this.addPriceFromComments()
  }

  get bottom(): number {
    return +this.viewOptions[FRAME_BOTTOM_POS].selection
  }

  get top(): number {
    return +this.viewOptions[FRAME_TOP_POS].selection
  }

  get right(): number {
    return +this.viewOptions[FRAME_RIGHT_POS].selection
  }

  get left(): number {
    return +this.viewOptions[FRAME_LEFT_POS].selection
  }

  private static sanitize(option: ProdboardCabinetOption): ProdboardCabinetOption {
    option.value = option.value || {bottom: 0, left: 0, right: 0, top: 0} as any
    return option
  }

  public addScribings(scribings: string): void {
    if (scribings === 'Vänster' || scribings.indexOf('Både') !== -1) {
      this.measureLeft = ' + 40* mm'
    }
    if (scribings === 'Höger' || scribings.indexOf('Både') !== -1) {
      this.measureRight = ' + 40* mm'
    }
  }

  public getFactoryData(): FactoryData {
    const base = super.getFactoryData()
    const frame: IFDFrame = {
      bottom: this.bottom,
      left: this.left,
      right: this.right,
      top: this.top,
    }
    Object.keys(frame).forEach((key: string) => {
      frame[key] = isNaN(frame[key]) ? 0 : frame[key]
    })

    return Object.assign(base, {frame})
  }

  public update(data: any): void {
    super.update(data)
    this.setFromProperties(data)
    this.resetPrices()
    super.addPriceFromComments()
  }

  /**
   * When we know the skirting type we set the top and bottom
   * frame based on the skirting type.
   */
  public updateBasedOnSkirting(skirting: Skirting): void {
    const defaults = this.getDefaultRecess(this.product, skirting, this)
    const bf = defaults.bf >= 0 ? defaults.bf : this.bottom
    const tf = defaults.tf >= 0 ? defaults.tf : this.top
    const data = {bottom: bf, top: tf}
    this.update(data)
    this.valueChanges$.next(true)
  }

  protected getCustomCustomerListing(lc: string): string[] {

    const uniqueCabinets: string = this.frameWidthHelper.getString(this.viewOptions, this.product, lc, this.cabinet)
    if (uniqueCabinets !== '') {
      return [uniqueCabinets]
    }

    // The factory does not want this in the listings
    if (lc === 'en' || (+this.viewOptions[1].selection + +this.viewOptions[2].selection) === 0) {
      return []
    }

    return [
      this.optionValueNames[0][lc] + this.viewOptions[FRAME_LEFT_POS].selection + this.measureLeft,
      this.optionValueNames[1][lc] + this.viewOptions[FRAME_RIGHT_POS].selection + this.measureRight
    ]
  }

  private setValuesFromProdboardData(option: ProdboardCabinetOption): void {
    this.viewOptions[FRAME_BOTTOM_POS].selection = option.value.bottom
    this.viewOptions[FRAME_LEFT_POS].selection = option.value.left
    this.viewOptions[FRAME_RIGHT_POS].selection = option.value.right
    this.viewOptions[FRAME_TOP_POS].selection = option.value.top
  }
}

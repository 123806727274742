import {Injectable} from '@angular/core'
import {HttpClient} from '@angular/common/http'
import {environment} from '../../../environments/environment'
import {BehaviorSubject, forkJoin, Observable, of, ReplaySubject} from 'rxjs'
import {map, switchMap, tap} from 'rxjs/operators'
import {Customer, CustomerProject, ICustomer, ICustomerProject} from '../customer-types'
import {ProjectService} from '../../services/project.service'
import {WarningService} from '../../warnings/service/warning.service'
import {StateService} from './state.service'
import {IProjectBase} from '../../services/project-types'

/**
 * This is a shorthand interface for selecting projects
 * without bothering a about the whole project
 */
export interface CustomerProjectSelect {
  /**
   * The derived name from the "kitchen project"
   */
  name: string

  /**
   * The database id of the "kitchen project" for this customer.
   */
  id: string

  /**
   * If the project has an existing customer project, let us use that
   * one instead of creating a new.
   */
  customerProjectId?: string
}

@Injectable({
  providedIn: 'root'
})
export class CustomerService {

  /**
   * Any one can listen and get a list
   */
  public customers$: Observable<Customer[]>

  /**
   * Keep an updated customer here.
   */
  public currentCustomer$: Observable<Customer>

  public currentCustomerProject$: ReplaySubject<CustomerProject> = new ReplaySubject(1)

  /**
   * Keep internal track of customers and currentCustomer.
   */
  private pCustomers$ = new BehaviorSubject<Customer[]>([])

  private pCurrentCustomer$: ReplaySubject<Customer> = new ReplaySubject<Customer>(1)

  constructor(
    private httpClient: HttpClient,
    private projectService: ProjectService,
    private warningService: WarningService,
    private stateService: StateService
  ) {
    this.customers$ = this.pCustomers$.asObservable()

    this.currentCustomer$ = this.pCurrentCustomer$.asObservable()

    /**
     * Get all server "interfaces" and convert them to classes.
     */
    const url = `${environment.productUrl}/customers`
    this.httpClient.get<ICustomer[]>(url).subscribe({
      next: (customers: ICustomer[]) => {
        const customerList = customers.map((c: ICustomer) => new Customer(c))
        customerList.sort((a: Customer, b: Customer) => b.timeStamp - a.timeStamp)
        this.pCustomers$.next(customerList)
      }
    })
  }

  /**
   * Just get a customer by ID, make sure it is the latest
   * return the Class not the interface
   */
  public get(id: string): Observable<Customer> {
    const url = `${environment.productUrl}/customers/${id}/$LATEST`
    return this.httpClient.get<ICustomer>(url).pipe(
      map((customer: ICustomer) => {
        const c = new Customer(customer || new Customer())
        this.pCurrentCustomer$.next(c)
        return c
      })
    )
  }

  /**
   * We have a customer but wants to add a project to it. We also
   * connect an existing (or new) customerProject
   */
  public setProjectOnCustomer(customerId: string, project: IProjectBase): Observable<any> {
    let customer: Customer
    return this.get(customerId).pipe(
      switchMap((createdCustomer: Customer) => {
        customer = createdCustomer
        if (project.customerProjectId) {
          return this.getProject(project.customerProjectId, project.id)
        }
        return of(new CustomerProject())
      }),
      switchMap((customerProject: CustomerProject) => {
        customerProject.name = project.customer.name
        customerProject.customerId = customer.id
        customerProject.projectId = project.id
        customerProject.customerName = customer.name
        return this.saveProject(customerProject.getSaveData())
      }),
      switchMap((customerProject: CustomerProject) => {
        // We now have a brand-new customer project, but the project
        // is not aware of that?
        const cp = new CustomerProject(customerProject)
        customer.projects.push({id: customerProject.id, state: cp.nextState()})
        return forkJoin([of(cp), this.save(customer.getSaveData())])
      }),
      switchMap((res: [CustomerProject, Customer]) =>
        // Finally add the customer project to the project.
        this.projectService.setCustomerProject(project.id, res[0])
      ))
  }

  public getProject(id: string, projectId: string): Observable<CustomerProject> {
    const url = `${environment.productUrl}/customer-projects/${id}/$LATEST`
    return this.httpClient.get<ICustomerProject>(url).pipe(
      map((customerProject: ICustomerProject) => {
        // If the project is missing on the server, just make a new one.
        const cp = new CustomerProject(customerProject || new CustomerProject())
        cp.projectId = projectId
        this.currentCustomerProject$.next(this.processConditions(cp))
        return cp
      })
    )
  }

  public processConditions(customerProject: CustomerProject): CustomerProject {
    const conditions = customerProject.getWarnItems()
    const states = customerProject.getStateItems()
    this.warningService.processConditions(conditions)
    this.stateService.processConditions(states)
    customerProject.updateState()
    return customerProject
  }

  /**
   * Create and delete are server operations
   */
  public save(customer: ICustomer): Observable<Customer> {
    let url = `${environment.productUrl}/customers`
    if (customer.id) {
      url += `/${customer.id}`
    }

    return this.httpClient.put<ICustomer>(url, customer).pipe(
      map((updatedCustomer: ICustomer) => {
        let result = new Customer(updatedCustomer)

        if (customer.id) {
          result = this.pCustomers$.value.find((c: Customer) => c.id === customer.id)
          Object.assign(result, updatedCustomer)
        } else {
          this.pCustomers$.value.push(result)
        }

        // Sort the list so that the newest is on top
        this.pCustomers$.value
          .sort((a: Customer, b: Customer) => b.timeStamp - a.timeStamp)
        this.pCustomers$.next(this.pCustomers$.value)
        this.pCurrentCustomer$.next(result)
        return result
      })
    )
  }

  public saveProject(customerProject: ICustomerProject): Observable<CustomerProject> {
    let url = `${environment.productUrl}/customer-projects`
    if (customerProject.id) {
      url += `/${customerProject.id}`
    }

    return this.httpClient.put<ICustomerProject>(url, customerProject).pipe(
      map((updatedProject: ICustomerProject) => {
        const result = new CustomerProject(updatedProject)
        //customerProject.version = result.version
        //customerProject.id = result.id
        this.projectService.setCustomerProject(customerProject.projectId, result).subscribe()
        this.currentCustomerProject$.next(result)
        return result
      })
    )
  }

  public delete(customer: Customer): Observable<any> {
    const url = `${environment.productUrl}/customers/${customer.id}`
    return this.httpClient.delete(url).pipe(
      map(() => {
        this.pCustomers$.next(this.pCustomers$.value.filter((c: Customer) => c.id !== customer.id))
      })
    )
  }

  /**
   * Delete a customer project when there is a project
   */
  public deleteProject(customerProject: CustomerProject): Observable<void> {
    return this.deleteProjectById(customerProject.id).pipe(
      tap(() => {
        this.projectService.removeCustomerProject(customerProject.projectId)
      })
    )
  }

  /**
   * Just delete a customer project, if it is not connected to a project
   */
  public deleteProjectById(id: string): Observable<void> {
    const url = `${environment.productUrl}/customer-projects/${id}`
    return this.httpClient.delete<void>(url)
  }
}


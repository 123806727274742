import {Component, HostListener, OnDestroy, OnInit} from '@angular/core'
import {AutoSaveComponent} from '../auto-save/auto-save.component'
import {AutoSaveService} from '../../services/auto-save.service'
import {ProjectService} from '../../services/project.service'
import {filter, tap} from 'rxjs/operators'
import {Router} from '@angular/router'
import {ProdboardFile} from '../../services/prodboard-types'
import {IProject} from '../../services/project-types'
import {MatDialog} from '@angular/material/dialog'

@Component({
  selector: 'kdl-floating-menu',
  templateUrl: './floating-menu.component.html',
  styleUrls: ['./floating-menu.component.scss']
})
export class FloatingMenuComponent extends AutoSaveComponent implements OnInit, OnDestroy {
  /**
   * Three variables for moving the window
   */
  public top = 20

  public left = 150

  public moving = false

  /**
   * The name to show.
   */
  public projectName = ''

  /**
   * Unless we have project we hide ourselves.
   */
  public showFloater = false

  public prodboardUrl: string

  public projectId: string

  constructor(
    autoSave: AutoSaveService,
    router: Router,
    dialog: MatDialog,
    private projectService: ProjectService
  ) {
    super(autoSave, router, dialog)
  }

  @HostListener('window:mousemove', ['$event'])
  onMouseMove(event: MouseEvent) {
    if (this.moving) {
      this.left = event.clientX
      this.top = event.clientY
    }
  }

  @HostListener('mousedown', [])
  moveIn(): boolean {
    this.moving = true
    return false
  }

  @HostListener('window:mouseup', [])
  moveOut(): boolean {
    this.moving = false
    return true
  }

  public ngOnInit(): void {
    super.ngOnInit()
    this.projectService.currentProject$.pipe(
      tap(() => this.showFloater = false),
      filter((Boolean))
    ).subscribe({
      next: (project: IProject) => {
        this.showFloater = true
        this.projectName = project.customer.name
        this.projectId = project.id
      }
    })

    this.projectService.currentFile$.pipe(
      filter(Boolean)
    ).subscribe({
      next: (file: ProdboardFile) => {
        this.prodboardUrl = file.url
      }
    })
  }

  public ngOnDestroy(): void {
    super.ngOnDestroy()
  }

  public saveProject(): void {
    this.projectService.saveProject().subscribe()
  }
}

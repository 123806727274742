import {Component, EventEmitter, OnDestroy, OnInit, Output} from '@angular/core'
import {ReplaySubject, Subscription} from 'rxjs'
import {filter} from 'rxjs/operators'
import {Appliance} from '../../../appliances/model/appliance'
import {IProject} from '../../../services/project-types'
import {Comment} from '../../../comments/model/comment'
import {ProjectHelperService} from '../../../services/project-helper.service'

interface ItemComment {
  text: string

  price: number
}

interface Item {
  text: string

  price: number

  discountPercent: number

  discountAmount: number

  customerPrice: number

  comments: ItemComment[]
}

@Component({
  selector: 'kdl-appliance-receipt',
  templateUrl: './appliance-receipt.component.html',
  styleUrls: ['./appliance-receipt.component.scss']
})
export class ApplianceReceiptComponent implements OnInit, OnDestroy {

  @Output() total: EventEmitter<number> = new EventEmitter<number>()

  public items$: ReplaySubject<Item[]> = new ReplaySubject<Item[]>(1)

  public rTotal = 0

  public rDiscount = 0

  public rCustomer = 0

  private sub$ = new Subscription()

  constructor(
    private projectHelperService: ProjectHelperService
  ) {
  }

  public ngOnInit(): void {
    this.sub$ = this.projectHelperService.project$.pipe(
      filter(Boolean)
    ).subscribe({
      next: (project: IProject) => {
        this.rCustomer = 0
        this.rDiscount = 0
        this.rTotal = 0
        this.items$.next(project.appliances.map((appliance: Appliance) => {
          const quantity = appliance.quantity || 1
          const text = quantity > 1 ? `${quantity} st ${appliance.name} à ${appliance.price} kr` : appliance.name
          const total = appliance.price * quantity
          const discountAmount = total * (appliance.discount / 100)
          const customerPrice = total - discountAmount
          this.rDiscount += discountAmount
          this.rTotal += total
          this.rCustomer += customerPrice
          const comments = appliance.comments
            .map((comment: Comment) => {
              this.rCustomer += comment.price
              return {text: comment.comment, price: comment.price}
            })
          return {
            text,
            price: total,
            discountPercent: appliance.discount,
            discountAmount,
            customerPrice,
            comments
          }
        }))
        this.total.emit(this.rCustomer)
      }
    })
  }

  public ngOnDestroy(): void {
    this.sub$.unsubscribe()
  }
}

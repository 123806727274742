<div class="header cabinet">
  <div>Item</div>
  <div class="prices">
    <div class="cabinet-labor price">Price</div>
  </div>
</div>
<div *ngFor="let item of items$ | async">
  <div class="cabinet">
    <div class="cabinet-text"><span *ngIf="item.index > 0">{{item.index}} - </span>{{item.text}}</div>
    <div class="prices">
      <div class="cabinet-labor price">{{item.labor  | number:'1.2-2': 'fr'}} €</div>
    </div>
  </div>
  <div *ngFor="let comment of item.comments" class="options">
    <div class="option">
      <div class="option-text comment">{{comment.text}}</div>
      <div class="prices">
        <div class="option-labor price">{{comment.labor | number:'1.2-2': 'fr'}} €</div>
      </div>
    </div>
  </div>
  <div *ngFor="let option of item.options" class="options">
    <div class="option">
      <div class="option-text">{{option.text}}</div>
      <div class="prices">
        <div class="option-labor price">{{option.labor | number:'1.2-2': 'fr'}} €</div>
      </div>
    </div>
  </div>
</div>
<div class="cabinet footer">
  <div>Total</div>
  <div class="prices">
    <div class="cabinet-labor price">{{rLabor | number:'1.2-2': 'fr'}} €</div>
  </div>
</div>


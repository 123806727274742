<ng-template #saveButton>
  <span *ngIf="changed && !saving"><span kdlI18n label="saveIn"></span>: {{autoSaveTimeout - timer}} s</span>
  <span *ngIf="!changed && !saving" kdlI18n label="save"></span>
  <span *ngIf="saving" kdlI18n label="saving"></span>
</ng-template>

<div
  *ngIf="showFloater && projectName"
  [ngStyle]="{'moving' : moving}"
  [style.left.px]="left"
  [style.top.px]="top"
  class="floater project">
  <div class="float-content">
    <div class="floater-title">{{projectName}}</div>
    <kdl-print></kdl-print>
    <button
      (click)="saveProject()"
      [disabled]="!changed || saving"
      color="primary"
      mat-raised-button>
      <mat-icon class="material-icons-outlined" fontIcon="save"></mat-icon>
      <ng-container *ngTemplateOutlet="saveButton"></ng-container>
    </button>
    <button
      (click)="showFloater = false"
      color="warn"
      mat-icon-button>
      <mat-icon class="material-icons-outlined" fontIcon="close"></mat-icon>
    </button>
  </div>
  <div class="links">
    <a *ngIf="prodboardUrl" [href]="prodboardUrl" kdlI18n label="openInProdboard" target="_blank"></a>
    <div>|</div>
    <a *ngIf="prodboardUrl" [routerLink]="['edit-project', 'project', projectId, 'prodboard']"
       kdlI18n label="selectNewProdboard"></a>
  </div>
  <kdl-cad></kdl-cad>
</div>


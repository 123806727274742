import {CabinetOption, ViewOptionType} from './cabinet-option'
import {IProduct} from '../common/interface/product-types'
import {ProdboardCabinetOption} from '../services/prodboard-types'

export class Lighting extends CabinetOption {
  /**
   * Must be statically set _before_ constructor?
   */
  public readonly optionSelectName = 'Lighting'

  /**
   * Since we want to drink wine and not write test we create this
   *
   * Heads Up! If you change these the WarningService may break!
   */
  private values = [
    {name: 'Ingen belysning', factor: 0}, // Using factor 0 will set the price to zero.
    {name: 'Spotlights undertill', factor: 1},
    {name: 'LED-strips undertill', factor: 1.7},
    {name: 'Spotlights undertill, och LED-strips inuti skåpet', factor: 3.5},
    {name: 'LED-strips undertill och inuti skåpet', factor: 4.2},
    {name: 'LED-strips inuti skåpet', factor: 2.5},
  ]

  constructor(option: ProdboardCabinetOption, product: IProduct, cabinetId: number) {
    super(option, product, cabinetId)
    this.setNameAndOptions()
    this.priority = 100 // Top if active
    this.viewOptions = [
      {
        type: ViewOptionType.select,
        title: 'Belysningstyp',
        name: 'lightingType',
        values: this.values.map((v) => v.name),
        selection: 'Ingen belysning',
        disabled: false
      }
    ]
    this.description = 'Spotlights or LED-strips can be added to the cabinets'
    this.title = 'optLightning'

    const sOption = Lighting.sanitize(option, product)
    this.setValuesFromProdboardData(sOption)
    this.setPrice()
  }

  private static sanitize(option: ProdboardCabinetOption, product: IProduct): ProdboardCabinetOption {
    option.value = option.value || {} as any
    option.value.options = option.value.options || {} as any
    option.value.options.light = option.value.options.light || {} as any
    option.value.options.light.choice = option.value.options.light.choice || {name: 'Ingen belysning'} as any

    // We make sure to fix the price so that it does not break if we do not
    // Get a value
    const priceModel = {
      liPr: {
        price: 0,
        labor: 0,
        material: 0
      },
    }
    Object.assign(product, priceModel)
    return option
  }

  public update(data: any): void {
    super.update(data)
    this.setFromProperties(data)
    this.active = this.viewOptions[0].selection !== 'Ingen belysning'
    this.setPrice()
  }

  protected getCustomCustomerListing(lc: string): string[] {
    return this.getCustomerListingByKey(lc, this.viewOptions[0].selection)
  }

  private setValuesFromProdboardData(option: ProdboardCabinetOption): void {
    if (option.value.name === 'Ja') {
      this.priority = 10
      this.viewOptions[0].selection = option.value.options.light.choice.name
    }
    this.active = this.viewOptions[0].selection !== 'Ingen belysning'
  }

  private setPrice(): void {
    this.resetPrices()
    const factorial = this.values.find((v: any) => v.name === this.viewOptions[0].selection)
    this.price = this.product.liPr.price * factorial.factor
    this.labor = this.product.liPr.labor * factorial.factor
    this.material = this.product.liPr.material * factorial.factor

    if (factorial.factor > 0) {
      const item = this.addReceiptItem()
      item.priceProperty = 'liPr'
      item.itemText = this.viewOptions[0].selection
      this.shouldHavePrice = true
    }
    this.addPriceFromComments()
  }
}

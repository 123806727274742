<div *ngIf="warning">
  <div *ngIf="warning.type === 'cabinet'">
    <div
      (click)="warningService.editCabinet(detail)"
      [ngClass]="{ 'detail-heading': !detail.index, 'click': detail.index && detail.option }"
      *ngFor="let detail of warning.details"
      class="detail"
    >{{detail.text}}</div>
  </div>
  <div *ngIf="warning.type === 'project'">
    <kdl-edit-project [show]="[warning.details[0].hideShow.show]"></kdl-edit-project>
  </div>
</div>

import {Injectable} from '@angular/core'
import {ProdboardCabinet} from '../model/cabinet/prodboard-cabinet'
import {CabinetOption} from '../model/cabinet-option'
import {ReceiptItem} from '../model/receipt/receipt-item'
import {Observable, ReplaySubject} from 'rxjs'
import {ProjectHelperService} from './project-helper.service'
import {ProblemService} from './problem.service'
import {filter, map, switchMap, tap} from 'rxjs/operators'
import {ProjectService} from './project.service'
import {IProject} from './project-types'

@Injectable({
  providedIn: 'root'
})
export class ReceiptService {

  public items$: Observable<ReceiptItem[]>

  private pItems$: ReplaySubject<ReceiptItem[]> = new ReplaySubject<ReceiptItem[]>()

  private cabinetItems: ReceiptItem[] = []

  private projectItems: ReceiptItem[] = []

  private priceProperties: string[] = ['price', 'material', 'labor']

  constructor(
    private projectHelperService: ProjectHelperService,
    private projectService: ProjectService,
    private problemService: ProblemService
  ) {
    this.items$ = this.pItems$.asObservable()

    this.projectHelperService.cabinets$.pipe(
      switchMap((cabinets: ProdboardCabinet[]) => {
        this.cabinetItems.length = 0
        cabinets.forEach((cabinet: ProdboardCabinet) => {
          // Get the Cabinet receipt
          // cabinet.update() // Triggers price calculus
          cabinet.receipt.forEach((receipt: ReceiptItem) => this.cabinetItems.push(receipt))

          cabinet.options
            .filter(o => o.active)
            .forEach((option: CabinetOption) => {
              option.receipt.forEach((receipt: ReceiptItem) => this.cabinetItems.push(receipt))
            })
        })
        return this.projectService.currentProject$
      }),
      tap(() => this.projectItems.length = 0),
      filter(Boolean),
      map((project: IProject) => {
        project.receipt.forEach((receiptItem: ReceiptItem) => this.projectItems.push(receiptItem))
      })
    ).subscribe({
      next: () => {
        // Verify and filter all the items
        const items = this.cabinetItems.concat(this.projectItems)
          .map((receiptItem: ReceiptItem) => this.verifyItem(receiptItem))
          .filter((r: any) => r)
        this.pItems$.next(items)
      }
    })
  }

  private verifyItem(receiptItem: ReceiptItem): ReceiptItem | undefined {
    const problem = this.priceProperties.find((property: string) => {
      if (isNaN(receiptItem[property])) {
        this.problemService.problems$.next({
          description: `Pris '${receiptItem.itemText} har ogiltigt värde (${property})` +
            JSON.stringify(receiptItem, null, 2),
          handled: false
        })
        return true
      }
    })
    if (!problem) {
      return receiptItem
    }
  }
}

import {CabinetOption, ViewOptionType} from './cabinet-option'
import {IProduct} from '../common/interface/product-types'
import {ProdboardCabinetOption, ProdboardSelectionItem} from '../services/prodboard-types'

export class HandleDoor extends CabinetOption {
  /**
   * Must be statically set _before_ constructor
   */
  public readonly optionSelectName = 'HandleDoor'

  constructor(item: ProdboardSelectionItem, product: IProduct, cabinetId: number) {
    const option: ProdboardCabinetOption = {} as any

    super(option, product, cabinetId)
    this.setNameAndOptions()
    this.priority = 100

    this.viewOptions = [
      {
        type: ViewOptionType.select,
        title: 'Vred',
        name: 'handleDoor',
        values: ['Ja', 'Nej'],
        selection: 'Nej',
        disabled: false
      }
    ]
    this.active = true
    this.description = 'Special kind of knob style, which looks like ' +
      'this: https://www.gyllenhaks.se/index.php?main_page=product_info&products_id=1548'
    this.title = 'optHandleDoor'

    this.viewOptions[0].selection = (item.modificator + '').toLowerCase().indexOf('vred') !== -1 ? 'Ja' : 'Nej'
    this.addPriceFromComments()
  }

  public isVred(): boolean {
    return this.viewOptions[0].selection === 'Ja'
  }

  public update(data: any) {
    super.update(data)
    this.setFromProperties(data)
    this.active = data.handleDoor === 'Ja'
    this.resetPrices()
    this.addPriceFromComments()
  }

  protected getCustomCustomerListing(lc: string): string[] {
    return []
  }
}

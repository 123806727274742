import {Injectable} from '@angular/core'
import {HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http'
import {AuthService, IAuthInfo} from '../services/auth.service'

/**
 * Inject to set auth header on all requests.
 */
@Injectable()
export class AuthInterceptor implements HttpInterceptor {

  private accessToken: string | null = null

  constructor(
    private authService: AuthService
  ) {
    this.authService.authState$.subscribe({
      next: (state: IAuthInfo) => this.accessToken = state.accessToken
    })
  }

  /**
   * Intercept all requests and add auth token. We should however
   * do this only on white listed requests so that we do not
   * send our tokens when not needed.
   */
  intercept(req: HttpRequest<any>, next: HttpHandler) {
    if (req.url.indexOf('-kulladal-images.s3') !== -1) {
      return next.handle(req)
    }

    if (!this.accessToken) {
      return next.handle(req)
    }

    const authReq = req.clone({
      headers: req.headers.set('Authorization', `Bearer ${this.accessToken}`)
    })

    return next.handle(authReq)
  }
}

import {formatNumber} from '@angular/common'
import {applianceTypes, ApplianceType} from '../service/appliance.service'
import {ISupplier} from '../appliance-form/appliance-form.component'
import {Comments, CommentHomeEntity, Comment} from '../../comments/model/comment'
import {TEMP_SOURCE_ID} from '../../images/model/project-image'

export interface IAppliance {
  /**
   * Unique Id for server storage purposes
   */
  id?: string

  /**
   * Version set on server side
   */
  version: number

  /**
   * The type is from the backends and is always 'A'
   */
  type: 'A'

  /**
   * Appliance type is one of:
   */
  applianceType: string

  applianceTypeName: string

  name: string
  nameEn: string

  /**
   * Price is customer price
   */
  price: number

  /**
   * Material is used for "in-pris" to stay consistent
   * with all other prices.
   */
  material: number

  /**
   * Discount in percent as in 10 (%)
   */
  discount: number

  /**
   * The number of these, only added when used.
   */
  quantity: number

  /**
   * Customer facing description
   */
  description?: string

  /**
   * Any URL that will showcase this on the internet
   */
  url?: string

  /**
   * An ID of an image previously uploaded
   */
  image?: string

  /**
   * The supplier of the goods.
   */
  supplier: ISupplier
}

export class Appliance implements IAppliance, Comments {

  public id = ''
  public version = 0
  public type: 'A'
  public applianceType = 'stove'
  public applianceTypeName = ''
  public name = ''
  public nameEn = ''
  public price = 0
  public material = 0
  public discount = 0
  public quantity = 0
  public description?: string = ''
  public url?: string = ''
  public image?: string = ''
  public supplier: ISupplier = {name: 'Okänd', email: 'mail@exampler.com'}

  /**
   * Required to support the comments interface
   */
  public commentHome: CommentHomeEntity = {type: 'APPLIANCE', id: TEMP_SOURCE_ID}

  public comments: Comment[] = []

  constructor(appliance: IAppliance = {} as any) {
    Object.assign(this, appliance)
    this.applianceTypeName = applianceTypes.find((applianceType: ApplianceType) => applianceType.name === this.applianceType).displayName
    this.commentHome.id = this.id
  }

  get handlePresentation(): string {
    let price = formatNumber(this.price, 'fr', '1.0-0')
    const totalPrice = formatNumber(this.price * this.quantity, 'fr', '1.0-0')
    let presString = `${this.applianceTypeName}: ${this.name}: ${this.quantity} st à ${price} kr = ${totalPrice} kr`
    if (this.discount > 0) {
      price = formatNumber(this.price * this.quantity - (this.price * this.quantity * this.discount / 100), 'fr', '1.0-0')
      presString = `${presString} - ${this.discount}% rabatt = ${price} kr`
    }
    return presString
  }

  get totalPrice(): number {
    let price: number = this.price * this.quantity
    if (this.discount > 0) {
      price = price - (price * this.discount / 100)
    }
    this.comments.forEach((comment: Comment) => {
      price += comment.price
    })
    return price
  }
}

import {Component, Input} from '@angular/core'
import {PBFileCompare} from '../../../services/prodboard.service'

@Component({
  selector: 'kdl-pb-display',
  templateUrl: './pb-display.component.html',
  styleUrls: ['./pb-display.component.scss']
})
export class PbDisplayComponent {
  @Input() current: PBFileCompare
  @Input() other: PBFileCompare
}

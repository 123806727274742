import {CabinetOption, ViewOption, ViewOptionType} from './cabinet-option'
import {IProduct} from '../common/interface/product-types'
import {OptionValueNameItem} from '../services/override.service'
import {ProdboardCabinetOption} from '../services/prodboard-types'

export const SELECTION_NONE = 'Ingen'
export const OPTION_COMBO = 'Combo spice/jar'
export const OPTION_JAR = 'Jar rack'
export const OPTION_SPICE = 'Spice rack'
export const OPTION_NO = 'Nix'

export class SpiceRack extends CabinetOption {
  public readonly optionSelectName = 'SpiceRack'

  private translation = {}

  private keys = ['door', 'door_l', 'door_r']

  constructor(option: ProdboardCabinetOption, product: IProduct, cabinetId: number) {
    super(option, product, cabinetId)
    this.setNameAndOptions()

    // Detour to avoid linter objections
    this.translation[OPTION_COMBO] = {en: 'Combo spice/jar', sv: 'Combo'}
    this.translation[OPTION_JAR] = {en: 'Jar rack', sv: 'Burkhylla'}
    this.translation[OPTION_SPICE] = {en: 'Spice rack', sv: 'Kryddhylla'}
    this.translation[OPTION_NO] = {en: 'None', sv: 'Ingen'}

    this.priority = 20
    this.viewOptions = [
      {
        type: ViewOptionType.select,
        title: 'Typ av hylla, vänster lucka',
        name: 'doorOne',
        values: ['Ingen', 'Kryddhylla', 'Burkhylla', 'Combo'],
        selection: 'Ingen',
        disabled: false
      },
      {
        type: ViewOptionType.select,
        title: 'Typ av hylla, höger lucka',
        name: 'doorTwo',
        values: ['Ingen', 'Kryddhylla', 'Burkhylla', 'Combo'],
        selection: 'Ingen',
        disabled: false
      }
    ]

    this.description = 'A kind of shelf that is put on the inside of the door'
    this.title = 'optSpiceRack'

    this.sanitize(option)
    this.setValuesFromProdboardData(option)
    this.active = this.viewOptions.reduce((acc: boolean, vo: ViewOption) => vo.selection !== SELECTION_NONE || acc, false)
    this.setPrice()
  }


  public update(data: any) {
    super.update(data)
    this.setFromProperties(data)
    this.active = !!this.viewOptions.find((viewOption: ViewOption) => 'Ingen'.indexOf(viewOption.selection) === -1)
    this.setPrice()
  }

  protected getCustomCustomerListing(lc: string): string[] {
    const answer = []
    this.viewOptions.forEach((viewOption: ViewOption) => {
      if (viewOption.selection !== 'Ingen') {
        answer.push(this.optionValueNames.find((valueNameItem: OptionValueNameItem) => valueNameItem.key === viewOption.selection)[lc])
      }
    })
    if (answer[0] === answer[1]) {
      return [answer[0] + ': 2']
    }
    return answer
  }

  private sanitize(option: ProdboardCabinetOption): void {
    option.value = option.value || {name: 'Nej'} as any
    option.value.options = option.value.options || {} as any
    this.keys.forEach((door: string) => {
      option.value.options[`sh_${door}`] = option.value.options[`sh_${door}`] || {} as any
      option.value.options[`sh_${door}`].type = option.value.options[`sh_${door}`].type || {
        code: 'Katt',
        name: OPTION_NO
      } as any
    })
  }

  private setValuesFromProdboardData(option: ProdboardCabinetOption): void {
    const options: any[] = []
    if (option.value.name !== 'Nej') {
      Object.keys(option.value.options).forEach((key: string) => {
        if (option.value.options[key].type.name !== 'Nix') {
          options.push({name: key, value: option.value.options[key].type.name})
        }
      })

      if (options.length === 1) {
        this.viewOptions.pop() // Remove door 2
        this.viewOptions[0].title = 'Typ av hylla' // Set texts to one door
        this.viewOptions[0].selection = this.translation[options[0].value].sv
      } else {
        this.viewOptions[0].selection = this.translation[options.find((opt: any) => opt.name === 'sh_door_l').value].sv
        this.viewOptions[1].selection = this.translation[options.find((opt: any) => opt.name === 'sh_door_r').value].sv
      }
    }
  }

  private setPrice(): void {
    this.resetPrices()
    const numRacks = this.viewOptions
      .filter((vo: ViewOption) => vo.selection !== SELECTION_NONE)
      .reduce((acc: number) => acc + 1, 0)
    this.price = this.product.shIdPr.price * numRacks
    this.labor = this.product.shIdPr.labor * numRacks

    if (numRacks > 0) {
      const item = this.addReceiptItem()
      item.itemText = `Pris för ${numRacks} Krydd-/Burkhylla`
      item.price = this.price
      item.labor = this.labor
      this.shouldHavePrice = true
    }
    this.addPriceFromComments()
  }
}

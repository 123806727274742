import {IProduct} from '../common/interface/product-types'
import {CabinetOption, ViewOption, ViewOptionType} from './cabinet-option'
import {ProdboardCabinetOption} from '../services/prodboard-types'

export class HiddenDrawer extends CabinetOption {

  /**
   * Must be statically set _before_ constructor
   */
  public readonly optionSelectName = 'HiddenDrawer'

  constructor(option: ProdboardCabinetOption, product: IProduct, cabinetId: number, optionsList: ProdboardCabinetOption[]) {
    super(option, product, cabinetId)
    this.setNameAndOptions()

    this.priority = 100
    this.viewOptions = [
      {
        type: ViewOptionType.select,
        title: 'Utdragslåda, hög',
        name: 'largeHiddenDrawers',
        values: ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9'],
        selection: '0',
        disabled: false,
      },
      {
        type: ViewOptionType.select,
        title: 'Utdragslåda, låg',
        name: 'smallHiddenDrawers',
        values: ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9'],
        selection: '0',
        disabled: false,
      },
      {
        type: ViewOptionType.select,
        title: 'Hyllplan',
        name: 'shelves',
        values: ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9'],
        selection: '0',
        disabled: false
      },
    ]
    this.product.hiDrPr.price = this.product.hiDrPr.price || 0
    this.product.hiDrPr.labor = this.product.hiDrPr.labor || 0
    this.product.exShPr.price = this.product.exShPr.price || 0
    this.product.exShPr.labor = this.product.exShPr.labor || 0
    this.active = true

    this.description = 'Info about what\'s inside the cabinet: qty of shelves, drawers'
    this.title = 'optHiddenDrawer'

    const sOption = HiddenDrawer.sanitize(optionsList)
    this.setValuesFromProdboardData(sOption)
    this.setPrice()
  }

  private static sanitize(optionsList: ProdboardCabinetOption[]): { key: string; amount: number }[] {
    const optionCount = [
      {
        key: 'Utdragslåda, hög',
        amount: 0
      },
      {
        key: 'Utdragslåda, låg',
        amount: 0
      },
      {
        key: 'Hyllplan',
        amount: 0
      }
    ]

    optionsList.forEach((option: ProdboardCabinetOption) => {
      option.value = option.value || {name: ''} as any
      optionCount.forEach((count) => {
        if (count.key === option.value.name) {
          count.amount++
        }
      })
    })
    return optionCount
  }

  public update(data: any) {
    super.update(data)
    const props = ['largeHiddenDrawers', 'smallHiddenDrawers', 'shelves']
    props.forEach((key: string, index: number) => {
      this.viewOptions[index].selection = data[key] || this.viewOptions[index].selection
    })
    this.active = +this.viewOptions[0].selection !== 0 || +this.viewOptions[1].selection !== 0 || +this.viewOptions[2].selection !== 0
    this.setPrice()
  }

  public numberHiddenShelves(): number {
    return +this.viewOptions[0].selection + +this.viewOptions[1].selection
  }

  public hasHiddenDrawers(): boolean {
    let hasHidden = false
    if (this.viewOptions[0].selection !== '0' || this.viewOptions[1].selection !== '0') {
      hasHidden = true
    }
    return hasHidden
  }

  protected getCustomCustomerListing(lc: string): string[] {
    const answer = []
    this.viewOptions.forEach((vo: ViewOption, index: number) => {
      if (+this.viewOptions[index].selection > 0) {
        answer.push(this.optionValueNames[index][lc] + vo.selection)
      }
    })
    return answer
  }

  private setValuesFromProdboardData(option: { key: string; amount: number }[]): void {
    this.viewOptions.forEach((vo: ViewOption, index: number) => {
      this.viewOptions[index].selection =
        option.find((opt: { amount: number; key: string }) => opt.key === vo.title).amount + ''
    })
    this.active = +this.viewOptions[0].selection !== 0 || +this.viewOptions[1].selection !== 0 || +this.viewOptions[2].selection !== 0
  }

  private checkShouldHavePrice(): number {
    let amount = 0
    this.viewOptions.forEach((viewOption: ViewOption) => {
      amount += +viewOption.selection
    })
    return amount
  }

  private setPrice(): void {
    this.resetPrices()
    this.price =
      this.product.hiDrPr.price * (+this.viewOptions[0].selection) +
      this.product.hiDrPr.price * (+this.viewOptions[1].selection) +
      this.product.exShPr.price * (+this.viewOptions[2].selection)

    this.labor =
      this.product.hiDrPr.labor * (+this.viewOptions[0].selection) +
      this.product.hiDrPr.labor * (+this.viewOptions[1].selection) +
      this.product.exShPr.labor * (+this.viewOptions[2].selection)
    const item = this.addReceiptItem()
    item.itemText = 'Hyllor/Utdragslådor'
    item.price = this.price
    item.labor = this.labor

    this.shouldHavePrice = this.checkShouldHavePrice() > 0

    this.addPriceFromComments()
  }
}

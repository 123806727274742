import {IProduct} from '../common/interface/product-types'
import {CabinetOption, ViewOptionType} from './cabinet-option'
import {ProdboardCabinetOption} from '../services/prodboard-types'
import {CLASSIC_HINGES, MODERN_HINGES, MODERN_HINGES_TAP} from './model-types'

export class Hinges extends CabinetOption {

  /**
   * Must be statically set _before_ constructor?
   */
  public readonly optionSelectName = 'Hinges'

  private readonly prices = [
    {
      name: CLASSIC_HINGES,
      price: 250,
    },
    {
      name: MODERN_HINGES,
      price: 100,
    },
    {
      name: MODERN_HINGES_TAP,
      price: 0,
    },
  ]

  constructor(option: ProdboardCabinetOption, product: IProduct, cabinetId: number) {
    super(option, product, cabinetId)
    this.setNameAndOptions()

    this.priority = 10
    this.viewOptions = [
      {
        type: ViewOptionType.select,
        title: 'Gångjärn',
        name: 'hinges',
        values: [CLASSIC_HINGES, MODERN_HINGES, MODERN_HINGES_TAP],
        selection: '',
        disabled: true
      }
    ]
    this.description = 'Decides what kind of hinge style the cabinet has - there are 3 different styles'
    this.title = 'optHinges'
    const sOption = Hinges.sanitize(option)
    this.setValuesFromProdboardData(sOption)
    this.setPrice()
  }

  private static sanitize(option: ProdboardCabinetOption): ProdboardCabinetOption {
    option.value = option.value || {} as any
    option.value.name = option.value.name || ''
    option.value.options = option.value.options || {} as any
    option.value.options.hinges = option.value.options.hinges || {style: ''} as any

    return option
  }

  public update(data: any): void {
    super.update(data)
    this.setFromProperties(data)
    this.setPrice()
  }

  protected getCustomCustomerListing(lc: string): string[] {
    return this.getCustomerListingByKey(lc, this.viewOptions[0].selection)
  }

  private setValuesFromProdboardData(option: ProdboardCabinetOption): void {
    this.viewOptions[0].selection = option.value.name
    this.viewOptions[0].disabled = option.name.toLowerCase() !== 'gångjärn'
    this.active = option.name.toLowerCase() === 'gångjärn'
  }

  private setPrice(): void {
    const numDoors = this.product.nuDo || 0
    this.resetPrices()
    const findPrice = this.prices.find((price: any) => price.name === this.viewOptions[0].selection) || ({price: 0} as any)
    this.price = findPrice.price * numDoors
    if (findPrice.price > 0) {
      const item = this.addReceiptItem()
      item.priceProperty = 'local'
      item.itemText = this.viewOptions[0].selection
      this.shouldHavePrice = true
    }
    this.addPriceFromComments()
  }
}

import {Component, OnInit, Output, EventEmitter} from '@angular/core'
import {ProdboardService, PBFileCompare} from '../../services/prodboard.service'
import {filter, first} from 'rxjs/operators'
import {ProdboardFile} from '../../services/prodboard-types'

@Component({
  selector: 'kdl-prodboard-compare',
  templateUrl: './prodboard-compare.component.html',
  styleUrls: ['./prodboard-compare.component.scss']
})
export class ProdboardCompareComponent implements OnInit {

  @Output() selected: EventEmitter<boolean> = new EventEmitter<boolean>()

  public pre: PBFileCompare = {} as any
  public post: PBFileCompare = {} as any

  constructor(public prodboardService: ProdboardService) {
  }

  ngOnInit(): void {
    ProdboardService.prodboardFiles$
      .pipe(
        filter((res: [ProdboardFile, ProdboardFile]) => res[0] !== null && res[1] !== null),
        first()
      )
      .subscribe({
        next: (res: [ProdboardFile, ProdboardFile]) => {
          this.pre = {} as any
          this.post = {} as any
          const items = [this.pre, this.post]
          res.forEach((prodboardFile: ProdboardFile, index: number) => {
            ProdboardService.convertProdboardFileToDisplay(prodboardFile, items[index])
          })
        }
      })
  }
}

import {Injectable} from '@angular/core'
import {
  ICustomerStateCondition,
  CustomerStateLabel,
  CustomerStateName,
  CustomerProject,
  ICustomerState
} from '../customer-types'
import {ProjectHelperService} from '../../services/project-helper.service'
import {ProdboardCabinet} from '../../model/cabinet/prodboard-cabinet'
import {CabinetOption} from '../../model/cabinet-option'
import {STANDARD_SKIRTING} from '../../common/interface/product-types'

export const generateStateMap = (): Map<CustomerStateLabel, CustomerStateName> => {
  const stateMap = new Map<CustomerStateLabel, CustomerStateName>()
  const customerProject = new CustomerProject()
  // Send out all keys as active also.
  customerProject.states.forEach((state: ICustomerState) => {
    stateMap.set(state.state.state, Object.assign(state.state, {active: true}))
  })
  return stateMap
}
@Injectable({
  providedIn: 'root'
})
export class StateService {

  private cabinets: ProdboardCabinet[] = []

  constructor(private projectHelperService: ProjectHelperService) {
    projectHelperService.cabinets$.subscribe({
      next: (cabinets: ProdboardCabinet[]) => {
        this.cabinets = cabinets
      }
    })
  }

  public processConditions(conditions: ICustomerStateCondition[]): void {
    conditions.forEach((condition: ICustomerStateCondition) => {
      if (condition.stateId === 'SKIRTING') {
        this.setSkirtingState(condition)
      }
      if (condition.stateId === 'HANDLES') {
        this.setHandleState(condition)
      }
      if (condition.stateId === 'DOOR_ATTACHMENTS') {
        this.setDoorAttachments(condition)
      }
    })
  }

  private setSkirtingState(condition: ICustomerStateCondition): void {
    const skirting: CabinetOption[] = []
    this.cabinets.forEach((cabinet: ProdboardCabinet) => {
      const optionWithSkirt: CabinetOption = cabinet.options.find((option: CabinetOption) => option.optionSelectName === 'Skirting')
      if (optionWithSkirt) {
        skirting.push(optionWithSkirt)
      }
    })
    condition.disabled = !skirting.find((op: CabinetOption) => STANDARD_SKIRTING.indexOf(op.viewOptions[0].selection) !== -1)
    if (condition.disabled) {
      condition.completed = !skirting.find((op: CabinetOption) => STANDARD_SKIRTING.indexOf(op.viewOptions[0].selection) !== -1)
    }
  }

  private setHandleState(condition: ICustomerStateCondition): void {
    this.cabinets.forEach((cabinet: ProdboardCabinet) => {
      const optionWithHandle: CabinetOption = cabinet.options.find((option: CabinetOption) => option.optionSelectName === 'HandleDoor')
      if (optionWithHandle) {
        if (optionWithHandle.viewOptions[0].selection === 'Ja') {
          condition.completed = true
        }
      }
    })
  }

  private setDoorAttachments(condition: ICustomerStateCondition): void {
    const hanging: string[] = []
    this.cabinets.forEach((cabinet: ProdboardCabinet) => {
      const optionWithHanging: CabinetOption = cabinet.options.find((option: CabinetOption) => option.optionSelectName === 'DoorAttachment')
      if (optionWithHanging) {
        hanging.push(optionWithHanging.viewOptions[0].selection)
      }
    })
    condition.completed = hanging.indexOf('TBD') === -1
  }
}

import {CabinetOption, ViewOptionType} from './cabinet-option'
import {IProduct} from '../common/interface/product-types'
import {ProdboardCabinetOption} from '../services/prodboard-types'

export const CENTER_POST_OPTION_SELECT_NAME = 'CenterPost'

export class CenterPost extends CabinetOption {

  /**
   * Must be statically set _before_ constructor
   */
  public readonly optionSelectName = CENTER_POST_OPTION_SELECT_NAME

  constructor(option: ProdboardCabinetOption, product: IProduct, cabinetId: number) {
    super(option, product, cabinetId)
    this.setNameAndOptions()
    /**
     * Set this "statically" first so that they always have a default
     * value. The after you have analyzed the values, set it properly
     */
    this.viewOptions = [
      {
        type: ViewOptionType.select,
        title: 'Typ av mittstolpe',
        name: 'centerPostType',
        values: ['Fast mittstolpe mellan luckorna', 'Mittstolpen är fixerad vid en av luckorna', 'Ingen mittstolpe mellan luckorna'],
        selection: '',
        disabled: false
      }
    ]

    this.description = 'Describes if the mid-post is attached to the door or fixed in the carcass'
    this.title = 'optCenterPost'
    this.active = true // This is always active b/c
    this.priority = 7
    const sOption = CenterPost.sanitize(option)
    this.setValuesFromProdboardData(sOption)
    this.addPriceFromComments()
  }

  private static sanitize(option: ProdboardCabinetOption): ProdboardCabinetOption {
    option.value = option.value || {} as any
    return option
  }

  public update(data: any) {
    super.update(data)
    this.resetPrices()
    this.setFromProperties(data)
    this.addPriceFromComments()
  }

  protected getCustomCustomerListing(lc: string): string[] {
    return this.getCustomerListingByKey(lc, this.viewOptions[0].selection)
  }

  private setValuesFromProdboardData(option: ProdboardCabinetOption) {
    this.viewOptions[0].selection = option.value.name
  }
}

import {ErrorHandler, Injectable} from '@angular/core'
import {ProblemService} from '../services/problem.service'
import {HttpErrorResponse} from '@angular/common/http'
import {MatDialog} from '@angular/material/dialog'
import {ConflictDialogComponent} from '../dialogs/conflict-dialog/conflict-dialog.component'

@Injectable()
export class GlobalErrorHandler implements ErrorHandler {

  constructor(
    private problemService: ProblemService,
    private dialog: MatDialog
  ) {
  }

  handleError(error: Error) {
    if (error instanceof HttpErrorResponse && error.status === 409) {
      this.dialog.open(ConflictDialogComponent, {
        maxWidth: '450px'
      })
      throw error
    }
    this.problemService.problems$.next({
      description: 'Ohanterat fel',
      handled: false,
      error
    })
    // IMPORTANT: Rethrow the error otherwise it gets swallowed
    throw error
  }
}

<div class="holder">
  <div class="line header">
    <div class="text">Text</div>
    <div class="filler"></div>
    <div class="number">Pris</div>
    <div class="number">Fab.</div>
    <div class="number">Mat.</div>
  </div>
  <div *ngFor="let item of items$ | async">
    <div class="line">
      <div [ngClass]="item.source" class="text">{{item.text}}</div>
      <div class="filler"></div>
      <div
        [matTooltip]="item.rPrice | number : '1.0-0': 'fr'"
        [ngClass]="item.source" class="number"
        matTooltipClass="mat-tooltip-extra">{{item.price | number : '1.0-0': 'fr'}}</div>
      <div
        [matTooltip]="item.rLabor | number : '1.0-0': 'fr'"
        [ngClass]="item.source"
        class="number"
        matTooltipClass="mat-tooltip-extra"
      >{{item.labor | number : '1.0-0': 'fr'}}</div>
      <div
        [matTooltip]="item.rMaterial | number : '1.0-0': 'fr'"
        [ngClass]="item.source"
        class="number"
        matTooltipClass="mat-tooltip-extra"
      >{{item.material | number : '1.0-0': 'fr'}}</div>
    </div>
    <div *ngIf="item.cabinetLast" class="line cabinet-sum">
      <div>s:a</div>
      <div class="filler"></div>
      <div class="number">{{item.cPrice | number: '1.0-0': 'fr'}}</div>
      <div class="number">{{item.cLabor | number: '1.0-0': 'fr'}}</div>
      <div class="number">{{item.cMaterial | number: '1.0-0': 'fr'}}</div>
    </div>
  </div>

  <div class="line footer">
    <div class="text">Total:</div>
    <div class="filler"></div>
    <div class="number">{{rPrice | number: '1.0-0': 'fr'}}</div>
    <div class="number">{{rLabor | number: '1.0-0': 'fr'}}</div>
    <div class="number">{{rMaterial | number: '1.0-0': 'fr'}}</div>
  </div>
</div>

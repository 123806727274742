import {CabinetOption, ViewOptionType} from './cabinet-option'
import {IProduct} from '../common/interface/product-types'
import {ProdboardCabinetOption} from '../services/prodboard-types'

export class CuttingBoard extends CabinetOption {

  /**
   * Must be statically set _before_ constructor
   */
  public readonly optionSelectName = 'CuttingBoard'

  public selectionOptions = {
    // eslint-disable-next-line @typescript-eslint/naming-convention
    1: 'Ja',
    // eslint-disable-next-line @typescript-eslint/naming-convention
    0: 'Nej'
  }

  constructor(option: ProdboardCabinetOption, product: IProduct, cabinetId: number) {
    super(option, product, cabinetId)
    this.setNameAndOptions()
    this.priority = 10

    this.viewOptions = [
      {
        type: ViewOptionType.binary,
        title: 'Utdragbar skärbräda',
        name: 'cuttingBoard',
        values: ['Ja', 'Nej'],
        selection: 'Nej',
        disabled: false
      }
    ]
    this.description = 'A cabinet can have a built-in cutting board'
    this.title = 'optCuttingBoard'
    const sOption = CuttingBoard.sanitize(option)
    this.setValuesFromProdboardData(sOption)
    this.setPrice()
  }

  private static sanitize(option: ProdboardCabinetOption): ProdboardCabinetOption {
    option.quantity = option.quantity || 0
    option.value = option.value || {} as any
    option.value.options = option.value.options || {} as any
    option.value.options.ch_b = option.value.options.ch_b || {on: false}
    return {quantity: option.value.options.ch_b.on ? 1 : 0} as any
  }

  public update(data: any): void {
    super.update(data)
    this.setFromProperties(data)
    this.active = this.viewOptions[0].selection === 'Ja'
    this.setPrice()
  }

  private setValuesFromProdboardData(option: ProdboardCabinetOption): void {
    this.active = option.quantity > 0
    this.viewOptions[0].selection = this.selectionOptions[option.quantity]
  }

  private setPrice(): void {
    const count = this.viewOptions[0].selection === 'Ja' ? 1 : 0

    this.resetPrices()
    this.price = this.product.cuBoPr.price * count
    this.labor = this.product.cuBoPr.labor * count

    // We should not add an item if there are no cutting boards
    if (count > 0) {
      const item = this.addReceiptItem()
      item.itemText = `Pris för ${count} skärbräda`
      item.priceProperty = 'cuBoPr'
      this.shouldHavePrice = true
    }
    this.addPriceFromComments()
  }
}

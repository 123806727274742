import {Injectable} from '@angular/core'
import {HttpClient} from '@angular/common/http'
import {ReplaySubject, Subject, timer, NEVER} from 'rxjs'
import {switchMap, takeUntil, catchError} from 'rxjs/operators'


interface PackageJson {
  version: string
}

@Injectable({
  providedIn: 'root'
})
export class VersionService {

  public version$: ReplaySubject<string> = new ReplaySubject<string>(1)

  /**
   * The only reason for this is to be able to cancel in tests
   */
  public cancel$ = new Subject<any>()

  constructor(
    private http: HttpClient
  ) {
    timer(0, 60 * 15 * 1000) // We check every 15 minutes
      .pipe(
        takeUntil(this.cancel$),
        switchMap(() => this.http.get<PackageJson>('/assets/package.json?' + `ts=${Date.now()}`)),
        catchError(() => NEVER)
      ).subscribe({
      next: (res: PackageJson) => {
        this.version$.next(res.version)
      }
    })
  }
}

import {Component, Input, OnDestroy, OnInit} from '@angular/core'
import {FormControl, FormGroup} from '@angular/forms'
import {debounceTime, filter, first, switchMap} from 'rxjs/operators'
import {ProjectHelperService} from '../../../services/project-helper.service'
import {forkJoin, Observable, of, Subscription} from 'rxjs'
import {IProject, ProjectFormData, TPaintProcessValues} from '../../../services/project-types'
import {ProdboardCabinet} from '../../../model/cabinet/prodboard-cabinet'
import {CustomerProject} from '../../../customer/customer-types'
import {CustomerService} from '../../../customer/service/customer.service'
import {calculateDiscount} from '../../../application/helpers'
import {formatNumber} from '@angular/common'

export const SPECIAL_NCS = 'Special: NCS'

@Component({
  selector: 'kdl-edit-project',
  templateUrl: './edit-project.component.html',
  styleUrls: ['./edit-project.component.scss']
})
export class EditProjectComponent implements OnInit, OnDestroy {

  /**
   * This is to display individual form controls in the warning dialog
   */
  @Input() public hide: string[] = []

  @Input() public show: string[] = []

  public colorFilteredOptions: Observable<string[]>
  public carpentryFilteredOptions: Observable<string[]>
  public cadFilteredOptions: Observable<string[]>
  public painterFilteredOptions: Observable<string[]>

  public formControlToHide =
    {
      projectName: false,
      discount: false,
      customerPrice: false,
      customerVersion: false,
      projectId: false,
      value: false,
      color: false,
      customColor: false,
      paintProcess: false,
      lc: false,
      socelHeight: false,
      hideFactoryInvoice: false,
      carpentry: false,
      customCarpentry: false,
      cad: false,
      customCad: false,
      painter: false,
      customPainter: false
    }

  /**
   * The form for setting project specific data
   */
  public form: FormGroup = new FormGroup({
    customerName: new FormControl<string | null>(null),
    discountPercent: new FormControl<number | null>(null),
    customerPrice: new FormControl<number | null>(null),
    customerVersion: new FormControl(),
    color: new FormControl<string | null>(null),
    customColor: new FormControl<string | null>(null),
    paintProcess: new FormControl<TPaintProcessValues | null>(null),
    lc: new FormControl<string>('', {nonNullable: true}),
    socelHeight: new FormControl<number | null>(null),
    hideFactoryInvoice: new FormControl<boolean>(false),
    carpentry: new FormControl<string | null>(null),
    customCarpentry: new FormControl<string | null>(null),
    cad: new FormControl<string>(null),
    customCad: new FormControl<string>(null),
    painter: new FormControl<string>(null),
    customPainter: new FormControl<string>(null),
  })


  public colorss = new Map<string, string>(
    [
      ['Nr 1', 'Nr 1'],
      ['Nr 12', 'Nr 12'],
      ['Nr 19', 'Nr 19'],
      ['Nr 25', 'Nr 25'],
      ['Nr 31', 'Nr 31'],
      ['Nr 34', 'Nr 34'],
      ['Nr 38', 'Nr 38'],
      ['Nr 48', 'Nr 48'],
      ['Nr 58', 'Nr 58'],
      ['Nr 67', 'Nr 67'],
      ['Nr 69', 'Nr 69'],
      ['Nr 86', 'Nr 86'],
      ['Nr 87', 'Nr 87'],
      ['Nr 97', 'Nr 97'],
    ]
  )
  /**
   * A list of colors to be selected in the color form control
   */
  public colors: { val: string; text: string }[] = [
    {val: 'Nr 1', text: 'Nr 1'},
    {val: 'Nr 12', text: 'Nr 12'},
    {val: 'Nr 19', text: 'Nr 19'},
    {val: 'Nr 25', text: 'Nr 25'},
    {val: 'Nr 31', text: 'Nr 31'},
    {val: 'Nr 34', text: 'Nr 34'},
    {val: 'Nr 38', text: 'Nr 38'},
    {val: 'Nr 48', text: 'Nr 48'},
    {val: 'Nr 58', text: 'Nr 58'},
    {val: 'Nr 67', text: 'Nr 67'},
    {val: 'Nr 69', text: 'Nr 69'},
    {val: 'Nr 86', text: 'Nr 86'},
    {val: 'Nr 87', text: 'Nr 87'},
    {val: 'Nr 97', text: 'Nr 97'},
    {val: 'Nr 100', text: 'Nr 100'},
    {val: 'Nr 101', text: 'Nr 101'},
    {val: 'Nr 107', text: 'Nr 107'},
    {val: 'Nr 111', text: 'Nr 111'},
    {val: 'Nr 116', text: 'Nr 116'},
    {val: 'Nr 148', text: 'Nr 148'},
    {val: 'Nr 151', text: 'Nr 151'},
    {val: 'XX', text: 'XX'},
    {val: 'Vit Titan-Zink (NCS 0402-Y07R)', text: 'Vit Titan-Zink (NCS 0402-Y07R)'}
  ]

  /**
   * A list of carpenters to be selected in the carpentry form control
   */
  public carpenters: { val: string; text: string }[] = [
    {val: 'Raimondas', text: 'Raimondas'},
    {val: 'Justas', text: 'Justas'},
    {val: 'Odas', text: 'Odas'},
    {val: 'Retasta', text: 'Retasta'},
    {val: 'Agacco', text: 'Agacco'}
  ]

  /**
   * A list of "CAD:ers" to be selected in the cad form control
   */
  public caders: { val: string; text: string }[] = [
    {val: 'Alex', text: 'Alex'},
    {val: 'Sema', text: 'Sema'},
  ]

  /**
   * A list of painter to be selected in the painter form control
   */
  public painters: { val: string; text: string }[] = [
    {val: 'Linoljemålarna', text: 'Linoljemålarna'},
    {val: 'Patrik', text: 'Patrik'},
    {val: 'Linda', text: 'Linda'},
    {val: 'Pontus', text: 'Pontus'},
    {val: 'Kritan', text: 'Kritan'},
  ]

  /**
   * The actual price for carpentry
   */
  public actualPrice = 0

  /**
   * Additional data to display with pricing
   */
  public realDiscountValue = ''

  /**
   * The discount calculation string
   */
  public discountString = ''

  public hideColor = false

  private sub$ = new Subscription()

  private cabinets$ = new Subscription()

  private project: IProject

  private hiddenColors: TPaintProcessValues[] = [4, 5]

  constructor(
    public projectHelperService: ProjectHelperService,
    private customerService: CustomerService
  ) {
  }

  public ngOnInit(): void {


    // for (const x of this.colorss.entries()) {
    //   console.log(x)
    // }
    // this.colorFilteredOptions = this.form.controls.color.valueChanges.pipe(
    //   startWith(''),
    //   map(value => this.filter(value)),
    // )
    //
    // this.carpentryFilteredOptions = this.form.controls.carpentry.valueChanges.pipe(
    //   startWith(''),
    //   map(value => this.filter(value || ''))
    // )

    this.projectHelperService.project$.pipe(
      filter(Boolean),
      first(),
      switchMap((project: IProject) => {
        this.project = project
        this.form.patchValue(project.form, {emitEvent: false})
        this.actualPrice = project.computed.price.price
        this.form.controls.customerName.setValue(project.customer.name, {emitEvent: false})
        this.hideColor = this.hiddenColors.indexOf(project.form.paintProcess) !== -1
        this.updateCalculations()
        return this.form.valueChanges
      }),
      switchMap((val: ProjectFormData) => {
        this.project.customer.name = val.customerName
        this.hideColor = this.hiddenColors.indexOf(val.paintProcess) !== -1
        this.updateCalculations()
        return this.projectHelperService.setProjectForm(val)
      })
    ).subscribe()

    this.cabinets$ = this.projectHelperService.cabinets$.subscribe({
      next: (cabinets: ProdboardCabinet[]) => {
        if (!this.form.controls.paintProcess.value && cabinets.length > 0) {
          this.form.controls.paintProcess.setValue(cabinets[0].paintProcess)
        }
      }
    })

    this.form.controls.color.valueChanges
      .pipe(
        debounceTime(1500),
        switchMap((val: string) =>
          forkJoin([of(val), this.customerService.currentCustomerProject$.pipe(first())])
        ))
      .subscribe({
        next: (res: [val: string, cp: CustomerProject]) => {
          const colorVals: string[] = this.colors.map((color: { val: string; text: string }) => color.val)
          const customColor: boolean = colorVals.indexOf(res[0]) === -1
          res[1].setCustomColor(customColor)
          this.customerService.saveProject(res[1]).subscribe()
        }
      })
    this.showHideFormControls()
  }

  public clearCustomColor(color: string): void {
    if (color !== SPECIAL_NCS) {
      this.form.controls.customColor.reset()
    }
  }

  // public clearCustomCarpentry(color: string): void {
  //   if (color !== 'Custom carpentry') {
  //     this.form.controls.customCarpentry.reset()
  //   }
  // }
  //
  // public clearCustomCad(cad: string): void {
  //   if (cad !== 'Custom CAD') {
  //     this.form.controls.customCad.reset()
  //   }
  // }
  //
  // public clearCustomPainter(painter: string): void {
  //   if (painter !== 'Custom painter') {
  //     this.form.controls.customPainter.reset()
  //   }
  // }

  public ngOnDestroy(): void {
    this.sub$.unsubscribe()
    this.cabinets$.unsubscribe()
  }

  // private filter(value: string): string[] {
  //   const filterValue = value.toLowerCase()
  //   const result = this.colors.filter(option => option.text.toLowerCase().includes(filterValue))
  //   return result.map((c: { val: string; text: string }) => c.text)
  // }

  private showHideFormControls(): void {
    this.hide.forEach((hide: string) => {
      this.formControlToHide[hide] = true
    })
    if (this.show.length > 0) {
      Object.keys(this.formControlToHide).forEach((key: string) => {
        this.formControlToHide[key] = true
      })

      this.show.forEach((show: string) => {
        this.formControlToHide[show] = false
      })
    }
  }

  /**
   * Update all calculated values
   */
  private updateCalculations(): void {
    const price = this.form.controls.customerPrice.value
    const discountPct = this.form.controls.discountPercent.value
    this.discountString = calculateDiscount(price, discountPct)
    // we keep this here in case we want to show the actual price again
    const realDiscountPct = formatNumber(100 * (1 - price / this.actualPrice), 'fr', '1.0-2')
    this.realDiscountValue =
      `${formatNumber(-1 * (this.actualPrice - price), 'fr', '1.0-0')} kr (${realDiscountPct}%)`
  }
}

import {Injectable} from '@angular/core'
import {AuthService, IAuthInfo} from './auth.service'
import {filter, switchMap} from 'rxjs/operators'
import {EAuthState} from '../common/interface/auth'
import {ProductService} from './product.service'
import {OverrideService} from './override.service'
import {ProjectService} from './project.service'

@Injectable({
  providedIn: 'root'
})
export class BootstrapService {

  constructor(
    private authService: AuthService,
    private productService: ProductService,
    private overrideService: OverrideService,
    private projectService: ProjectService
  ) {
    this.authService.authState$.pipe(
      filter((authState: IAuthInfo) => authState.state === EAuthState.authenticated),
      // Here we must bootstrap the product service...
      switchMap(() => this.productService.getProductsAndCategories()),
      // And the options
      switchMap(() => this.overrideService.bootstrap())
    ).subscribe({
      next: () => {
        // And the projects
        this.projectService.updateProjectList()
      }
    })
  }
}

import {Injectable} from '@angular/core'
import {HttpClient} from '@angular/common/http'
import {BehaviorSubject, forkJoin, Observable, of, ReplaySubject} from 'rxjs'
import {environment} from '../../environments/environment'
import {catchError, filter, first, map, mergeMap, switchMap, tap} from 'rxjs/operators'
import {ProdboardFactory} from '../model/prodboard-factory'
import {ProdboardCabinet} from '../model/cabinet/prodboard-cabinet'
import {ProblemService} from './problem.service'
import {ProdboardService} from './prodboard.service'
import {Appliance} from '../appliances/model/appliance'
import {ProdboardFile} from './prodboard-types'
import {IChange, IProject, IProjectBase} from './project-types'
import {CustomerProject} from '../customer/customer-types'
import {IProjectImage} from '../images/model/project-image'

export interface ICounterTopBakkant {
  length: number

  height: number

  thickness: number

  /**
   * Price per sqm
   */
  price: number

  /**
   * Price per sqm for factory
   */
  labor: number
}

/**
 * This service is responsible for two things and two things only
 * 1. It can save, get, delete projects and files.
 * 2. It can receive prodboard files and convert them to cabinets.
 *
 * Everything else, that has to bother about the logic of these items
 * has, shall and must be handled elsewhere.
 *
 */
@Injectable({
  providedIn: 'root'
})
export class ProjectService {
  /**
   * Set projects here, if we have a loaded project, this is where we publish it.
   * We must always have projects. Not any idiot should publish here. We should really
   * change this so that the currentProject is exported as .asObservable(), however I do
   * not feel like putting in the energy for that now.
   */
  public currentProject$: BehaviorSubject<IProject> = new BehaviorSubject<IProject>(null)

  /**
   * Let innocent bystanders get all projects from here.
   */
  public projects$: BehaviorSubject<IProjectBase[]> = new BehaviorSubject<IProjectBase[]>([])

  /**
   * We publish the current cabinets here, note that it is
   * a replay subject, so it will not emit until we actually have
   * a file to work with
   */
  public cabinets$: ReplaySubject<ProdboardCabinet[]> = new ReplaySubject<ProdboardCabinet[]>(1)

  /**
   * We keep certain metadata about files ... stupid?
   */
  public currentFile$: ReplaySubject<ProdboardFile> = new ReplaySubject<ProdboardFile>(1)

  /**
   * Set up common change detection, both for listeners and those that create changes.
   */
  public changes$: BehaviorSubject<IChange> = new BehaviorSubject<IChange>({changed: false, fileChanged: false})

  /**
   * Yet another semaphore to prevent double save ...
   */
  private isSavingProject = false
  private isSavingFile = false

  /**
   * Use this semaphore to prevent file saving if the
   * prodboard file has not changed. Prodboard file
   * is only changed when a new file has been loaded from file.
   */
  private fileIsPristine = true

  /**
   * If we have received a file from the database it has fancy id numbers and
   * sh*t. If we load a new file from "disk" we need to impose that file metadata
   * otherwise we cannot save it.
   */
  private fileMetaData: ProdboardFile = {} as any

  /**
   * We need to know if we have a project loaded. If not we need to seed
   * one if someone loads a Prodboard file.
   */
  private project: IProject = null

  /**
   * Simple semaphore to avoid loading projects when no real change has
   * happened.
   */
  private projectsLoaded = false

  constructor(
    private httpClient: HttpClient,
    private prodboardFactory: ProdboardFactory,
    private problemService: ProblemService
  ) {

    // Every time we get a new File we have to update the project
    // a new file comes from either the file uploader or from a project
    // that is loaded from the internet. We intercept "prodboard jsons"
    // here and make them into proper "Cabinets".
    this.currentFile$
      .pipe(
        filter<ProdboardFile | null>(Boolean), // Null is reset så we do not process that
        switchMap((file: ProdboardFile) => this.prodboardFactory.createCabinets(file, this.project || {form: {}} as any)),
        map((cabinets: ProdboardCabinet[]) => {
          this.cabinets$.next(cabinets)
          ProjectService.projectBaseToProject(this.project, this.fileMetaData)
        })
      )
      .subscribe()

    // Just an indication if we have a project to populate with
    // prodboard data.
    this.currentProject$.subscribe({
      next: (project: IProject) => {
        this.project = project
      }
    })

    this.changes$.subscribe({
      next: (changes: IChange) => {
        if (changes.fileChanged === true) {
          this.fileIsPristine = false
        }
      }
    })
  }

  public static newProject(): IProject {
    return {
      appliances: [],
      cabinets: {},
      comments: [],
      prodboardComment: null,
      computed: {price: {}} as any,
      customer: {} as any,
      fileId: '',
      form: {} as any,
      id: '',
      images: [],
      counterTops: [],
      timeStamp: 0,
      version: 0,
      receipt: []
    }
  }

  /**
   * Exporting this so that I can test it reasonably easy,
   * This is just until we have all projects with a new import.
   */
  public static setProjectDataFromProdboardFile(project: IProject, file: ProdboardFile): void {
    project.customer.url = file.url
    // THIS IS CHEATING! We assume hera that the file is a real json
    // that have properties that are not really allowed :-(
    // 'number  is a reserved word,
    let prop = 'number'
    project.customer.prodboardNumber = file[prop] as string

    // If the file comes from "disk" it has the "id" prop, but if it comes
    // from the server it has, or should have, the "prodboardId" property.
    // It is hate, pure hate, this morning I woke up and chose violence!
    prop = 'prodboardId'
    if (!file[prop]) {
      prop = 'id'
    }
    project.customer.prodboardId = file[prop]
  }

  /**
   * Take what we get from server and create a "Project" and add the file
   * metadata to it. From here we should have a real project.
   *
   * @param projectBase - What we have stored in the database, not an object.
   * @param file - A prodboard file, either from server or file.
   */
  private static projectBaseToProject(projectBase: IProjectBase, file: ProdboardFile): IProject {
    const result: IProject = Object.assign(ProjectService.newProject(), projectBase) as any
    if (result.appliances && Array.isArray(result.appliances)) {
      result.appliances.forEach((appliance: Appliance) => {
        const discount = appliance.discount + ''
        appliance.discount = Number(discount.replace(/\D/, ''))
      })
    }
    ProjectService.setProjectDataFromProdboardFile(result, file)
    return result
  }

  /**
   * This is called when we load a new file using the file uploader,
   * DO NOT call this when loading a file from the servers
   */
  public setProdboardFile(file: ProdboardFile): void {
    // We listen to currentFile$ in the constructor so that every time
    // we get a new file can create cabinets for it.
    this.fileMetaData.type = 'F'
    this.currentFile$.next(Object.assign(this.fileMetaData, file))
    if (!this.project) {
      const project = ProjectService.newProject()
      project.customer.name = file.customer.name
      this.currentProject$.next(project)
    }

    /**
     * Allow to save the new file.
     */
    this.fileIsPristine = false

    /**
     * Make sure to reset the file selector
     */
    ProdboardService.prodboardFiles$.next([null, null])
    this.changes$.next({changed: true, fileChanged: true}) // Something has changed, let us notify
  }

  /**
   * Fetch a project from the server. This means that we start over on files
   * and project. A project that is fetched must have both version and id.
   *
   * It first fetches the project, then the file attached to that project.
   * When we have the project we emit that. But we then have to wait for the
   * file to load and create the Cabinets.
   */
  public getProject(id: string, version: string): Observable<any> {
    return this.changes$.pipe(
      first(),
      switchMap((changes: IChange) => {
        //
        // This is for a future "warn if not saved shite"
        if (!changes.changed) {
          return this.getProjectFromServer(id, version)
        }
        return of({})
      })
    )
  }

  public getLatestItem<T>(id: string, type: string): Observable<T> {
    const url = `${environment.productUrl}/${type}/${id}/$LATEST`
    return this.httpClient.get<T>(url)
  }

  public getProjectVersions(id: string): Observable<IProject[]> {
    const url = `${environment.productUrl}/projects/${id}`
    return this.httpClient.get<IProject[]>(url)
  }

  /**
   * 1. Create one Project and one File on the server.
   * 2. Submit the requests in parallell
   * 3. Get both results back (forkJoin)
   * 4. In parallell on the server, update the file with the project and vice versa.
   * 5. Finally, submit the new file and the new project on subscriptions
   * 6. Then we return the newly created project. We could return just about
   *    anything
   *
   * Note that it all depends on that we already have a file.
   *
   * @param projectBase - The project is basically empty but has a name (hopefully)
   */
  public createProject(projectBase: IProjectBase): Observable<IProjectBase> {

    // Note that this will not complete until you have selected at least one prodboard file.
    // So we need to emit an empty file on creation, or fix the UI so that you cannot
    // save w/o first selecting a file.
    return this.currentFile$.pipe(
      first(), // Take only the last emitted file
      switchMap((res: ProdboardFile) => this.createProjectOnServer(projectBase, res))
    )
  }

  public duplicateProject(projectBase: IProjectBase): Observable<IProjectBase> {
    let project: IProjectBase
    const projectUrl = `${environment.productUrl}/projects/${projectBase.id}/$LATEST`
    return this.httpClient.get<IProjectBase>(projectUrl).pipe(
      switchMap((p: IProjectBase) => {
        project = p
        const fileId = project.fileId
        delete project.id
        delete project.version
        delete project.fileId
        project.customer.name = projectBase.customer.name + ' (kopia)'
        const fileUrl = `${environment.productUrl}/files/${fileId}/$LATEST`
        return this.httpClient.get<ProdboardFile>(fileUrl)
      }),
      switchMap((file: ProdboardFile) => {
        delete file.version
        delete file.projectId
        delete file.id
        // Must mark this as pristine, or it won't be saved.
        this.fileIsPristine = false
        return this.createProjectOnServer(project, file)
      })
    )
  }

  public deleteProject(project: IProjectBase): Observable<any> {
    const projectUrl = `${environment.productUrl}/projects/${project.id}`
    const fileUrl = `${environment.productUrl}/files/${project.fileId}`
    return forkJoin([this.httpClient.delete<any>(projectUrl), this.httpClient.delete<any>(fileUrl)])
      .pipe(
        tap(() => {
          this.reset()
          this.getProjects()
        })
      )
  }

  /**
   * Saving project, we also save the file. Possibly we
   * should only save the file if it has changed?
   */
  public saveProject(): Observable<IProject> {
    const p$ = this.currentProject$
      .pipe(
        first(),
        filter(Boolean),
        tap((p: IProject) => {
          if (!p.customer || !p.customer.name) {
            p.customer = p.customer || {} as any
            p.customer.name = 'Namnet har försvunnit!'
          }
          // Do not save long image urls for viewing.
          p.images.forEach((im: IProjectImage) => delete im.viewUrl)
        }),
        switchMap((p: IProject) => this.updateProject(p)),
        map((p: IProject) => {
          this.changes$.next({changed: false, fileChanged: false})
          this.currentProject$.next(p)
          this.getProjects() // Update the project list
          return p
        })
      )

    const f$ = this.currentFile$
      .pipe(
        first(), // Can only do "first" here since we publish to our own subject
        filter<ProdboardFile | null>(Boolean),
        mergeMap((f: ProdboardFile) => this.updateFile(f)),
        map((f: ProdboardFile) => {
          this.fileMetaData = f
          this.currentFile$.next(f)
          return f
        })
      )
    return forkJoin([p$, f$]).pipe(
      map((res: [IProject, ProdboardFile]) => res[0]
      ))
  }

  /**
   * Blindly just send a "project" to the backend. In theory, we get the 'updated'
   * project back. We namely need the new version to be able to update the routes.
   */
  public updateProject(project: IProject): Observable<IProject> {
    if (this.isSavingProject) {
      return of(project)
    }
    this.isSavingProject = true
    const url = `${environment.productUrl}/projects/${project.id}`
    return this.httpClient.put<IProject>(url, project).pipe(
      tap(() => this.isSavingProject = false)
    )
  }

  public updateFile(file: ProdboardFile): Observable<ProdboardFile> {
    if (this.isSavingFile || this.fileIsPristine) {
      return of(file)
    }
    this.isSavingFile = true
    const url = `${environment.productUrl}/files/${file.id}`
    return this.httpClient.put<ProdboardFile>(url, file).pipe(
      tap(() => {
        this.fileIsPristine = true
        this.isSavingFile = false
      })
    )
  }

  /**
   * If this is called we must update the connected kitchen project
   * we do not know what has changed so call this only when it has changed.
   */
  public setCustomerProject(projectId: string, customerProject: CustomerProject | undefined): Observable<any> {
    return of(this.project).pipe(
      switchMap((project: IProject) => {
        project.customerProjectId = customerProject.id
        project.customerId = customerProject.customerId
        project.customerName = customerProject.customerName
        project.customerProjectState = customerProject.currentState()
        return this.updateProject(project)
      }),
      tap((project: IProject) => {
        // Load this project if this is the same as the one we are working with
        if (this.currentProject$.value && this.currentProject$.value.id === projectId) {
          this.currentProject$.next(project)
        }
        // update the project list in case someone wants to list them
        this.getProjects()
      })
    )
  }

  /**
   * If this is called we must update the connected kitchen project
   * we do not know what has changed so call this only when it has changed.
   */
  public removeCustomerProject(projectId: string): void {
    this.getLatestItem<IProject>(projectId, 'projects').pipe(
      switchMap((project: IProject) => {
        delete project.customerProjectId
        delete project.customerId
        delete project.customerProjectState
        delete project.customerName
        return this.updateProject(project)
      }),
      tap((project: IProject) => {
        // Load this project if
        if (this.currentProject$.value && this.currentProject$.value.id === projectId) {
          this.currentProject$.next(project)
        }
      })
    ).subscribe()
  }

  public reset(): void {
    this.currentProject$.next(null)
    this.cabinets$.next([])
    this.currentFile$.next(null)
    this.fileMetaData = {} as any
    this.changes$.next({changed: false, fileChanged: false})
  }

  /**
   * This is for fixing bad BE data. To be removed
   * when we have a clean BE or implements this in BE
   *
   * @param projects
   */
  public filterProjectNames(projects: IProjectBase[]): IProjectBase[] {
    projects
      .filter((project: IProject) => !!project)
      .forEach((project: IProjectBase) => {
        project.customer = project.customer || {name: ''} as any
        if (typeof project.customer === 'string') {
          project.customer = {name: project.customer} as any
        }
      })
    return projects
  }

  public updateProjectList(): void {
    if (this.projectsLoaded) {
      return
    }
    this.getProjects()
  }

  public getOneProject(id: string): Observable<IProject> {
    const url = `${environment.productUrl}/projects/${id}/$LATEST`
    return this.httpClient.get<IProject>(url)
  }

  /**
   * Simple get that fetches all projects
   */
  private getProjects(): void {
    const url = `${environment.productUrl}/projects`
    this.httpClient.get<IProjectBase[]>(url).subscribe({
      next: (projects: IProjectBase[]) => {
        projects.sort((a: IProjectBase, b: IProjectBase) => b.timeStamp - a.timeStamp)
        this.projectsLoaded = true
        this.projects$.next(this.filterProjectNames(projects))
      }
    })
  }

  private getProjectFromServer(id: string, version: string): Observable<any> {
    let projectBase: IProjectBase
    const url = `${environment.productUrl}/projects/${id}/${version}`
    this.reset()
    return this.currentProject$.pipe(
      // Note the trick here, I try to make sure that the project and file
      // are reset before loading anything.
      first(),
      switchMap(() => this.currentFile$),
      first(),
      // This is the real action before the trick above.
      switchMap(() => this.httpClient.get<IProjectBase>(url)),
      filter(Boolean),
      switchMap((p: IProjectBase) => {
        projectBase = p
        const fileUrl = `${environment.productUrl}/files/${p.fileId}/$LATEST`
        return this.httpClient.get<ProdboardFile>(fileUrl)
      }),
      map((file: ProdboardFile) => {
        ProdboardService.fixProdboardFile(file)
        this.fileMetaData = file
        this.project = ProjectService.projectBaseToProject(projectBase, file)
        this.currentFile$.next(file) // If no file, it should reset
        this.currentProject$.next(JSON.parse(JSON.stringify(this.project)))
      }),
      catchError(() => {
        this.problemService.problems$.next({
          description: 'Kunde inte öppna projektet', handled: false
        })
        return of({})
      })
    )
  }

  private createProjectOnServer(projectBase: IProjectBase, file: ProdboardFile): Observable<any> {
    const url = `${environment.productUrl}/`
    const proj$ = this.httpClient.put<IProjectBase>(url + 'projects', projectBase)

    // Note that this will not complete until you have selected at least one prodboard file.
    // So we need to emit an empty file on creation, or fix the UI so that you cannot
    // save w/o first selecting a file.
    const file$ = this.httpClient.put<ProdboardFile>(url + 'files', file)

    return forkJoin([proj$, file$]).pipe(
      switchMap((res: [IProject, ProdboardFile]) => {
        const newProject = res[0]
        const newFile = res[1]
        newProject.fileId = newFile.id
        newFile.projectId = newProject.id
        const updateProject$ = this.updateProject(newProject)
        const updateFile$ = this.updateFile(newFile)
        return forkJoin([updateProject$, updateFile$])
      }),
      map((updateRes: [IProject, ProdboardFile]) => {
        // this.setProdboardFile(updateRes[1])
        this.currentProject$.next(ProjectService.projectBaseToProject(updateRes[0], updateRes[1]))
        this.currentFile$.next(updateRes[1])
        this.getProjects()
        this.changes$.next({changed: false, fileChanged: false})
        return updateRes[0] // Map returns what you return in an Observable
      })
    )
  }
}

import {CabinetOption, ViewOptionType} from './cabinet-option'
import {IProduct} from '../common/interface/product-types'
import {OptionValueNameItem} from '../services/override.service'
import {ProdboardCabinetOption} from '../services/prodboard-types'

export class BrassPlate extends CabinetOption {

  public readonly optionSelectName = 'BrassPlate'

  private selectOptions = [
    {
      key: 'None',
      value: 'Vänster'
    },
    {
      key: 'Left',
      value: 'Vänster'
    },
    {
      key: 'Right',
      value: 'Höger'
    },
    {
      key: 'Both',
      value: 'Båda'
    }
  ]

  constructor(option: ProdboardCabinetOption, product: IProduct, cabinetId: number) {
    super(option, product, cabinetId)
    this.setNameAndOptions()

    this.priority = 20
    this.viewOptions = [
      {
        type: ViewOptionType.select,
        title: 'Mässingsskylt med Kulladalslogo',
        name: 'brassPlate',
        values: ['Vänster', 'Höger', 'Båda'],
        selection: 'Ingen',
        disabled: false
      }
    ]
    this.active = false
    this.description = ''
    this.title = 'optBrassPlate'
    BrassPlate.sanitize(option)
    this.setValuesFromProdboardData(option)
  }

  private static sanitize(option: ProdboardCabinetOption): void {
    option.value = option.value || {name: 'None'} as any
  }

  public update(data: any) {
    super.update(data)
    this.setFromProperties(data)
  }

  setValuesFromProdboardData(option: ProdboardCabinetOption) {
    this.viewOptions[0].selection = this.selectOptions.find((opt) => opt.key === option.value.name).value
    this.active = this.selectOptions.find((opt) => opt.key === option.value.name).key !== 'None'
  }

  protected getCustomCustomerListing(lc: string): string[] {
    const answer = []

    if (lc === 'en') {
      answer.push(this.optionValueNames.find((opt: OptionValueNameItem) => opt.key === this.viewOptions[0].selection).en)
    }
      return answer
  }
}

import {Observable, of} from 'rxjs'
import {TWarningID} from '../warnings/model/warning'
import {CustomerStateMap} from './customer-state-map'

export interface ICustomer {
  /**
   * Id from database
   */
  id: string

  /**
   * Version from database, please do not care.
   */
  version: number

  /**
   * Type is a databse thing and is always "CST"
   */
  type: 'CST'

  /**
   * Database latest save time.
   */
  timeStamp: number

  /**
   * Name is the name of the customer, like Adam Haglund NOT
   * the project name 167 - What ever
   */
  name: string

  address1: string
  address2: string
  postalCode: string
  postalTown: string
  country: string

  /**
   * This is a list of customer projects that belong
   * to this customer.
   */
  projects: CustomerProjectData[]

  /**
   * State is the _best_ state of all customer projects.
   * It is just the indicator ('A', 'B' etc.), nothing else
   */
  currentStateLabel: CustomerStateLabel
}

export interface ICustomerProject {
  /**
   * The database id (uuid) for the customer project
   */
  id: string

  /**
   * Database version, must be retained between saves
   */
  version: number

  /**
   * Type as stored in the database, always CuSTomerProject
   */
  type: 'CSTP'


  /**
   * Customer id, a customer project should always have
   * a customer.
   */
  customerId: string

  /**
   * Wrap name so that we can do listings, not
   * a fan of this, but it is ok.
   */
  customerName: string

  /**
   * A MANDATORY Project ID for a 'Kitchen Project'
   * to connect this to. This must always be created
   * based on a kitchen project
   */
  projectId: string

  /**
   * All states of this customer project
   */
  states: ICustomerState[]

  /**
   * If this is archived or not, does not
   * say anything about which archive
   */
  archived: boolean
}

/**
 * One day I will understand this, but for now I want
 * to be able to iterate these so I create the type
 * from the array
 */
export const stateLabels = ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I'] as const
export type CustomerStateLabel = typeof stateLabels[number]

export type StateId = 'SKIRTING' | 'HANDLES' | 'DOOR_ATTACHMENTS'

export interface CustomerStateName {

  /**
   * Check version, if the version has changed we
   * should update to latest in terms of color, icon and such
   */
  version: number

  /**
   * The state, basically an enum but a type...
   */
  state: CustomerStateLabel

  /**
   * Display name
   */
  name: string

  /**
   * These have an order, 0 is "new", 9-ish archived
   */
  position: number

  /**
   * Let us set a hex color for display purposes
   */
  color: string

  /**
   * Image an icon or a png?
   */
  image: string

  /**
   * Helper to use in various functions
   */
  active?: boolean
}

export interface ICustomerState {
  /**
   * The state decorators and "id"
   */
  state: CustomerStateName

  /**
   * A set of yes no conditions if any. Leave empty array if none.
   * All has to be checked true?
   */
  conditions: ICustomerStateCondition[]

  /**
   * Tells if this is completed
   */
  completed: boolean

  /**
   * Below follows optional parameters for the various
   * states.
   */

  /**
   * Step 1. We must select a prodboard project.
   * When that is done the step is completed.
   */
  projectId?: string

  /**
   * A text description of when the phase starts
   */
  descriptionStart: string

  /**
   * A test description of when the description ends
   */
  descriptionEnd: string
}

/**
 * Binary, true/false - Checkbox
 * Unary, yes or no, both are valid but one must be selected
 * List, must select from list of values 'a' | 'b' or 'c'
 */
export type ConditionType = 'BINARY' | 'UNARY' | 'LIST'

export interface ICustomerStateCondition {
  /**
   * We need to account for these so that we
   * can add new conditions.
   */
  id: string

  /**
   * The type of condition
   */
  type: ConditionType

  /**
   * What we display
   */
  label: string

  /**
   * A list of options, actually mandatory
   * if type > BINARY, will extend later?
   */
  options?: string[]

  /**
   * Selection, if > Binary, this is the selected value
   */
  selection?: string

  /**
   * Completed or not.
   */
  completed: boolean

  /**
   * Who did change this, if any
   */
  actor?: string

  /**
   * Time of change, save the UNIX epoch in ms.
   */
  timeStamp?: number

  /**
   * Optional, undefined or false if not optional,
   * set to true if not needed to pass the state
   */
  optional?: boolean

  /**
   * If this cannot be changed by user (e.g. due to
   * warning connection)
   */
  disabled?: boolean

  /**
   * If this is tied to a warning, it should
   * have this set.
   */
  warningId?: TWarningID

  /**
   * If the state is affected by some rule
   */
  stateId?: StateId

  /**
   * Not applicable, if not shown in this context, like if
   * the project is not Türkie (or what it is called nowadays)
   */
  notApplicable?: boolean

  /**
   * Set to true if deadline has passed
   */
  expired?: boolean

  /**
   * Unix Epoch in ms when this item expires
   */
  deadline?: number

  /**
   * A list of strings representing comments on this item.
   * Right now we only use this in the condition-form.component.ts.
   * There we don't need an array, so we reset the array and only
   * use the first value. This need to be revisited if we need an
   * actual array of comments
   */
  comments?: string[]
}

/**
 * Change this when you have made changes to the display properties,
 * e.g. changing a color or title.
 */
export const STATE_VERSION = 1

/**
 * This is used so that we can store enough data
 * on the customer
 */
export interface CustomerProjectData {
  /**
   * The ID of the CustomerProject
   */
  id: string

  /**
   * The short state of select project
   */
  state: CustomerStateLabel
}

export class Customer implements ICustomer {
  public id: string
  public type: 'CST'
  public version: number
  public timeStamp: number

  public name: string
  address1: string
  address2: string
  postalCode: string
  postalTown: string
  country: string
  public email: string
  public phone: string
  /**
   * These should be saved on server. A list of customer project ids
   */
  public projects: CustomerProjectData[] = []

  public currentStateLabel: CustomerStateLabel = 'A'

  public currentStateName: CustomerStateName

  public stateLabels = new Map<CustomerStateLabel, CustomerStateName>()

  constructor(customer: ICustomer = {} as any) {
    Object.assign(this, customer)
    this.setStates()
  }

  /**
   * Return only what you want to save on the server.
   * When creating it was the stateCodes and StateMap.
   */
  public getSaveData(): ICustomer {
    const result = Object.assign({}, this)
    delete result.stateLabels
    return result
  }

  /**
   * Updates relevant states based on a state, this is called
   * from the customer card component and to work we need projects
   *
   * @param id - The CustomerProject id that needs updating
   * @param state - The new state as in 'A', 'B' etc.
   */
  public updateProjectState(id: string, state: CustomerStateLabel): void {
    this.projects.filter((p: CustomerProjectData) => p.id === id)
      .forEach((p: CustomerProjectData) => {
        p.state = state
      })
    this.setStates()
  }

  /**
   * Return the delivery address as
   * Daniel Bergdahl
   * Trädgårdsgatan 2
   * 26868 Röstånga
   * Sverige
   */
  public getDeliveryAdress(): string {
    return [this.name, this.address1, this.address2, `${this.postalCode} ${this.postalTown}`, this.country]
      .filter(s => s)
      .join('\n').replace(/undefined|null| {2}/g, '')
  }

  /**
   * Return the data to be copied in the customer list as
   * Daniel Bergdahl
   * Trädgårdsgatan 2
   * 26868 Röstånga
   * Sverige
   * 0706-6666666
   * daniel@dabe.se
   */
  public getCustomerCopyData(): string {
    return [this.getDeliveryAdress(), this.phone, this.email]
      .filter(s => s)
      .join('\n').replace(/undefined|null| {2}/g, '')
  }

  /**
   * Return notification info like:
   * Avisering till:
   * 0730274188
   * rexsuecia@gmail.com
   *
   */
  public getNotification(): string {
    return ['Avisering till:', this.email, this.phone]
      .filter(s => s)
      .join('\n')
  }

  /**
   * Return true/false if the filter matches something
   */
  public filter(filter: string, states?: Map<CustomerStateLabel, CustomerStateName>): boolean {
    let allowed = true
    // Available states are all states that we have.
    const availableStates: CustomerStateLabel[] = this.projects.map((project: CustomerProjectData) => project.state)

    // Sort them A - Z
    availableStates.sort() // Should work since this is basically primitives

    // IF states are available, then make sure we only return
    // true IF our _best_ state is in the allowed list, our best
    // state is the last state in the sorted list
    if (states && availableStates.length > 0) {
      allowed = states.get(availableStates[availableStates.length - 1]).active
    }
    return this.name.toLowerCase().indexOf(filter.toLowerCase()) !== -1 && allowed
  }

  private setStates(): void {
    // Just get a list of states
    const customerProject = new CustomerProject()

    // For each state set it to active??
    customerProject.states.forEach((state: ICustomerState) => {
      this.stateLabels.set(state.state.state, Object.assign(state.state, {active: true}))
    })
    // Each project have a state?!
    const availableStates: CustomerStateLabel[] = this.projects.map((project: CustomerProjectData) => project.state)

    availableStates.sort() // Should work since this is basically primitives
    if (availableStates.length > 0) {
      this.currentStateName = this.stateLabels.get(availableStates[availableStates.length - 1])
    } else {
      this.currentStateName = this.stateLabels.get(this.currentStateLabel)
    }
  }

}

/**
 * A Customer Project can exist w/o project or Customer
 */
export class CustomerProject implements ICustomerProject {
  public id = ''
  public version = -1
  public type: 'CSTP'

  // Take name from project or from customer?
  public name = ''

  public projectId: string

  public states: ICustomerState[] = []

  public customerId: string

  public customerName: string

  public notes: string

  /**
   * If this has been archived then special rules apply.
   */
  public archived = false

  public nextStateLabel: CustomerStateLabel

  /**
   * The complete state.
   */
  private stateMap = new CustomerStateMap().stateMap

  constructor(customerProject: ICustomerProject = {} as any) {
    Object.assign(this, customerProject)
    this.states = customerProject.states || []

    stateLabels.forEach((v: CustomerStateLabel) => {
      // Add the state if it is a new state
      const exist = this.states.find((s: ICustomerState) => s.state.state === v)
      if (!exist) {
        this.states.push(this.generateState(v))
      }
    })

    // Sort them to make sure of order.
    this.states.sort((a: ICustomerState, b: ICustomerState) =>
      a.state.position - b.state.position)

    // Update all states if they are outdated.
    this.states.forEach((state: ICustomerState) => {
      const stateName = state.state.state.toLowerCase()
      state.state.position = this.stateMap[stateName].state.position
      state.state.color = this.stateMap[stateName].state.color
      state.state.version = this.stateMap[stateName].state.version
      state.state.image = this.stateMap[stateName].state.image
      state.state.name = this.stateMap[stateName].state.name

      state.descriptionStart = this.stateMap[stateName].descriptionStart
      state.descriptionEnd = this.stateMap[stateName].descriptionEnd

      state.conditions = state.conditions || []
      // Go through all conditions and update the existing ones
      // and add missing conditions.
      this.stateMap[stateName].conditions.forEach((condition: ICustomerStateCondition) => {
        const existing = state.conditions.find((s: ICustomerStateCondition) => s.id === condition.id)
        if (existing) {
          existing.label = condition.label
          existing.type = condition.type
          existing.options = condition.options
        } else {
          state.conditions.push(condition)
        }
      })
      state.conditions
        .sort((a: ICustomerStateCondition, b: ICustomerStateCondition) => a.id.localeCompare(b.id))
    })
    // Special case for 'A' that have a selector for project id
    // we use direct access instead of 'A', do not know why.
    this.states[0].projectId = this.projectId

    // this.updateState()
    this.nextState()
  }

  public setCustomColor(customColor: boolean): void {
    //
    let stateToChange
    this.states.forEach((state: ICustomerState) => {
      stateToChange = state.conditions.find((condition: ICustomerStateCondition) => condition.id === 'd-az') || stateToChange
    })
    stateToChange.notApplicable = !customColor
    stateToChange.completed = !customColor
  }

  public getStateByLabel(label: CustomerStateLabel): ICustomerState {
    return this.states.find((state: ICustomerState) => state.state.state === label)
  }

  /**
   * Return only what you want to save on the server.
   * When creating it was the stateCodes and StateMap.
   */
  public getSaveData(): ICustomerProject {
    const result = Object.assign({}, this)
    delete result.stateMap
    return result
  }

  /**
   * Return true/false if the filter matches something
   */
  public filter(filter: string, states?: Map<CustomerStateLabel, CustomerStateName>): boolean {
    let allowed = true
    if (states) {
      const state = this.nextState()
      allowed = states.get(state).active
    }
    return this.name.toLowerCase().indexOf(filter.toLowerCase()) !== -1 && allowed
  }

  /**
   * Review all states to see if they are complete or not.
   * Should only be called when one or more of the states
   * have changed.
   */
  public updateState(): void {
    //this.states.forEach((state: ICustomerState) => this.setState(state))
    const allDone = this.states
      .map((state: ICustomerState) => {
        this.setState(state)
        return state.completed
      })
      .slice(0, -2) // Only look at not archived
      .reduce((acc: boolean, next: boolean) => acc && next, true)
    // Archive state if all states are complete
    if (allDone) {
      // All completed means customer archive (H) which is second from last
      const archiveState: ICustomerState = this.states[this.states.length - 2]
      archiveState.completed = true
      this.archived = true
    }
  }

  /**
   * Returns the state object (name, color, icon etc.) for the
   * current state.
   */
  public currentState(): CustomerStateName {
    let best = this.nextState()
    /**
     * If we are already archived we cannot have a better state.
     */
    if (this.archived) {
      best = this.bestState()
    }
    return this.generateState(best).state
  }

  /**
   * Returns the highest completed state.
   */
  public bestState(): CustomerStateLabel {
    let bestState: CustomerStateLabel = 'A'
    this.states.forEach((state: ICustomerState) => {
      if (state.completed) {
        bestState = state.state.state
      }
    })
    return bestState
  }

  /**
   * Set "next" based on what we think is "best"
   */
  public nextState(): CustomerStateLabel {
    const bestState = this.bestState()

    if (this.archived) {
      return bestState
    }

    let index = 0
    let state: ICustomerState = this.states[0]
    this.states.forEach((s: ICustomerState, i: number) => {
      if (s.state.state === bestState) {
        state = s
        index = i + 1
      }
    })
    if (index >= this.states.length) {
      this.nextStateLabel = this.states[this.states.length - 1].state.state
      return this.states[this.states.length - 1].state.state
    }

    if (state.completed) {
      this.nextStateLabel = this.states[index].state.state
      return this.states[index].state.state
    }

    this.nextStateLabel = state.state.state
    return state.state.state
  }

  /**
   * Archives the project, always set it to "skissakrviet" (I)
   * that is the last state.
   */
  public archive(): Observable<ICustomerState> {
    const archiveState: ICustomerState = this.states[this.states.length - 1]
    archiveState.completed = true
    archiveState.conditions.forEach((condition: ICustomerStateCondition) => condition.completed = true)
    this.archived = true
    return of(archiveState)
  }

  /**
   * Restore this to the last best state.
   */
  public unArchive(): Observable<ICustomerState> {
    // We currently know that the last two states are archives
    // can be improved.
    this.states.slice(-2)
      .forEach((state: ICustomerState) => {
        state.completed = false
        state.conditions.forEach((c: ICustomerStateCondition) => c.completed = false)
      })
    this.archived = false
    return of(this.generateState(this.nextState()))
  }

  /**
   * Restore all conditions to factory default
   */
  public reset(): void {
    this.states = []
    stateLabels.forEach((v: CustomerStateLabel) => {
      this.states.push(this.generateState(v))
    })
  }

  /**
   * Set all items to completed in a given state
   */
  public completeState(stateLabel: CustomerStateLabel): void {
    const state = this.getStateByLabel(stateLabel)
    state.conditions.forEach((c: ICustomerStateCondition) => c.completed = true)
    state.completed = true
  }

  public getStateItems(): ICustomerStateCondition[] {
    let conditions: ICustomerStateCondition[] = []
    this.states.forEach((state: ICustomerState) => {
      conditions = conditions.concat(state.conditions.filter((c: ICustomerStateCondition) => c.stateId))
    })
    return conditions
  }

  public getWarnItems(): ICustomerStateCondition[] {
    let res: ICustomerStateCondition[] = []
    this.states.forEach((s: ICustomerState) => {
      res = res.concat(s.conditions.filter((c: ICustomerStateCondition) => c.warningId))
    })
    return res
  }

  /**
   * Sets the overall state based on checks and potential other stuff.
   * This is for one specific state only
   */
  private setState(state: ICustomerState): void {
    state.completed = state.conditions
      .map((condition: ICustomerStateCondition) => condition.completed || condition.optional)
      .reduce((acc: boolean, completed: boolean) => acc && completed, true)
  }

  /**
   * Return a complete state based on the state label
   */
  private generateState(state: CustomerStateLabel): ICustomerState {
    return this.stateMap[state.toLowerCase()]
  }
}

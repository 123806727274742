import {CabinetOption, ViewOptionType} from './cabinet-option'
import {IProduct} from '../common/interface/product-types'
import {ProdboardCabinetOption} from '../services/prodboard-types'
import {DOOR_ON_DOOR, SLIDES, TBD} from './model-types'

export class DoorAttachment extends CabinetOption {

  public readonly optionSelectName = 'DoorAttachment'

  constructor(option: ProdboardCabinetOption, product: IProduct, cabinetId: number) {
    super(option, product, cabinetId)
    this.setNameAndOptions()

    this.priority = 10
    this.viewOptions = [
      {
        type: ViewOptionType.select,
        title: 'Luckinfästning',
        name: 'doorAttachment',
        values: [DOOR_ON_DOOR, SLIDES, TBD],
        selection: '',
        disabled: false
      }
    ]

    this.active = true

    this.description = 'MISSING'
    this.title = 'optDoorAttachment'

    DoorAttachment.sanitize(option)
    this.setValuesFromProdboardData(option)
  }

  private static sanitize(option: ProdboardCabinetOption): void {
    option.value = option.value || {code: '', name: ''} as any
  }

  public update(data: any) {
    super.update(data)
    this.setFromProperties(data)
  }

  protected getCustomCustomerListing(lc: string): string[] {
    return this.getCustomerListingByKey(lc, this.viewOptions[0].selection)
  }


  private setValuesFromProdboardData(option: ProdboardCabinetOption): void {
    this.viewOptions[0].selection = option.value.name
  }
}

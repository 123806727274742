import {Component, OnInit} from '@angular/core'
import {ActivatedRoute, ParamMap} from '@angular/router'
import {ProjectService} from '../../services/project.service'
import {switchMap, first} from 'rxjs/operators'
import {IProject} from '../../services/project-types'

@Component({
  selector: 'kdl-project-loader',
  template: '',
})
export class ProjectLoaderComponent implements OnInit {

  constructor(
    private route: ActivatedRoute,
    private projectService: ProjectService,
  ) {
  }

  ngOnInit(): void {
    let id: string
    this.route.paramMap.pipe(
      switchMap((params: ParamMap) => {
        id = params.get('id')
        return this.projectService.currentProject$
      }),
      first() // This cancels the whole chain so additional path changes will go unnoticed.
    ).subscribe({
      next: (project: IProject) => {
        if (!project || (id && project.id !== id)) {
          this.projectService.getProject(id, '$LATEST').subscribe()
        }
      }
    })
  }
}

import {NgModule} from '@angular/core'
import {CommonModule} from '@angular/common'
import {ReactiveFormsModule} from '@angular/forms'

import {HeaderComponent} from './header/header.component'
import {FooterComponent} from './footer/footer.component'
import {LoadingIndicatorComponent} from './loading-indicator/loading-indicator.component'
import {RouterModule} from '@angular/router'
import {ProblemListComponent} from './problem-list/problem-list.component'
import {FileUploadComponent} from './file-upload/file-upload.component'
import {EditProjectComponent} from './project/edit/edit-project.component'
import {ProjectPriceSummaryComponent} from './project/price/project-price-summary.component'
import {ProjectProdboardSummaryComponent} from './project/prodboard/project-prodboard-summary.component'
import {PrintComponent} from './print/print.component'
import {KitchenReceiptComponent} from './receipt/kitchen-receipt/kitchen-receipt.component'
import {ApplianceReceiptComponent} from './receipt/appliance-receipt/appliance-receipt.component'
import {AutoSaveComponent} from './auto-save/auto-save.component'
import {ProjectLoaderComponent} from './project-loader/project-loader.component'
import {ProdboardCompareComponent} from './prodboard-compare/prodboard-compare.component'
import {PbDisplayComponent} from './prodboard-compare/display/pb-display.component'
import {FactoryReceiptComponent} from './receipt/factory-receipt/factory-receipt.component'
import {ShortcutsComponent} from './shortcuts/shortcuts.component'
import {FloatingMenuComponent} from './floating-menu/floating-menu.component'
import {WarningComponent} from '../warnings/components/warning.component'
import {WarningItemComponent} from '../warnings/components/warning-item/warning-item.component'
import {CadComponent} from './cad/cad.component'
import {MatDialogModule} from '@angular/material/dialog'
import {MatIconModule} from '@angular/material/icon'
import {I18nModule} from '../i18n/i18n.module'
import {MatInputModule} from '@angular/material/input'
import {MatSelectModule} from '@angular/material/select'
import {MatCheckboxModule} from '@angular/material/checkbox'
import {MatButtonModule} from '@angular/material/button'
import {MatExpansionModule} from '@angular/material/expansion'
import {MatTooltipModule} from '@angular/material/tooltip'
import {MatSnackBarModule} from '@angular/material/snack-bar'
import {MatMenuModule} from '@angular/material/menu'
import {MatToolbarModule} from '@angular/material/toolbar'
import {MatAutocompleteModule} from '@angular/material/autocomplete'

@NgModule({
  declarations: [
    HeaderComponent,
    FooterComponent,
    ProblemListComponent,
    LoadingIndicatorComponent,
    FileUploadComponent,
    EditProjectComponent,
    ProjectPriceSummaryComponent,
    ProjectProdboardSummaryComponent,
    PrintComponent,
    KitchenReceiptComponent,
    ApplianceReceiptComponent,
    AutoSaveComponent,
    ProjectLoaderComponent,
    ProdboardCompareComponent,
    PbDisplayComponent,
    FactoryReceiptComponent,
    ShortcutsComponent,
    FloatingMenuComponent,
    WarningComponent,
    WarningItemComponent,
    CadComponent
  ],
  imports: [
    CommonModule,
    RouterModule,
    ReactiveFormsModule,
    MatDialogModule,
    MatIconModule,
    I18nModule,
    MatInputModule,
    MatSelectModule,
    MatCheckboxModule,
    MatButtonModule,
    MatExpansionModule,
    MatTooltipModule,
    MatSnackBarModule,
    MatMenuModule,
    MatToolbarModule,
    MatAutocompleteModule
  ],
  exports: [
    HeaderComponent,
    FooterComponent,
    ProblemListComponent,
    LoadingIndicatorComponent,
    FileUploadComponent,
    EditProjectComponent,
    ProjectPriceSummaryComponent,
    ProjectProdboardSummaryComponent,
    KitchenReceiptComponent,
    ProjectLoaderComponent,
    ProdboardCompareComponent,
    PbDisplayComponent,
    FactoryReceiptComponent,
    FloatingMenuComponent,
    WarningComponent,
    CadComponent
  ]
})
export class KdlCommonModule {
}

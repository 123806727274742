import {Component, EventEmitter, Inject, Input, OnDestroy, OnInit, Output} from '@angular/core'
import {FILE_READER} from '../../application/fileReader.provider'
import {ProjectService} from '../../services/project.service'
import {ProblemService} from '../../services/problem.service'
import {Subscription} from 'rxjs'
import {ProdboardService} from '../../services/prodboard.service'
import {ProdboardFile} from '../../services/prodboard-types'

@Component({
  selector: 'kdl-file-upload',
  templateUrl: './file-upload.component.html',
  styleUrls: ['./file-upload.component.scss']
})
export class FileUploadComponent implements OnInit, OnDestroy {

  /**
   * Input used for styling only, see Dashboard
   */
  @Input() type = ''

  @Input() label = 'selectFile'

  /**
   * Signal 'true' for a file loaded, 'false' if no file was loaded.
   */
  @Output() fileLoaded: EventEmitter<boolean> = new EventEmitter<boolean>()

  /**
   * The current file so that we can replace content
   * in new file with the old file.
   */
  private currentFile: ProdboardFile | null = null

  private project$ = new Subscription()

  constructor(
    private projectService: ProjectService,
    @Inject(FILE_READER) private fileReader: FileReader,
    private problemService: ProblemService,
  ) {
    this.fileReader.onload = this.onload
    this.fileReader.onerror = this.onerror
  }

  public ngOnInit(): void {
    this.project$ = this.projectService.currentFile$.subscribe({
      next: (file: ProdboardFile) => this.currentFile = file
    })
  }

  public ngOnDestroy(): void {
    this.project$.unsubscribe()
  }

  public onFileSelected(event: EventTarget): any {
    const selectedFile = (event as HTMLInputElement).files[0]
    // If the user cancels we must handle that
    if (selectedFile) {
      this.fileReader.readAsText(selectedFile, 'utf-8')
    }
  }

  public onload = () => {
    const uploadedFile = JSON.parse(this.fileReader.result.toString())
    // Now we are done and send the list back to the host
    uploadedFile.rawFile = true
    ProdboardService.fixProdboardFile(uploadedFile, this.currentFile)
    ProdboardService.prodboardFiles$.next([uploadedFile, this.currentFile])
    this.fileLoaded.emit(true)
  }

  public onerror = (e: Event) => {
    this.problemService.problems$.next({
      description: 'Fel vid uppläsning av fil',
      timeStamp: e.timeStamp + '',
      handled: false
    })
    this.fileLoaded.emit(false)
  }
}
